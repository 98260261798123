import { Typography } from "@ui-kit";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as ErrorIcon } from "@assets/icons/popup_error_icon.svg";
import { BasicDialog } from "@components/Dialogs/BasicDialog";
import { dialogContents } from "@constants/popups";

interface IErrorDialog {
  isDialogOpen: boolean;
  closeDialog: () => void;
  onAgreeClick: () => void;
}

export const ErrorDialog: FC<IErrorDialog> = ({ isDialogOpen, closeDialog, onAgreeClick }) => {
  const { t } = useTranslation();

  return (
    <BasicDialog
      open={isDialogOpen}
      onClose={closeDialog}
      onAgree={onAgreeClick}
      title={t(dialogContents.somethingWentWrongUnableProcessReq.title)}
      description={
        <Typography variant="p3" textAlign="center" component="p">
          {t(dialogContents.somethingWentWrongUnableProcessReq.description)}
        </Typography>
      }
      icon={<ErrorIcon />}
      agreeButtonText={t("buttons.tryAgain")}
    />
  );
};
