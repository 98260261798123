import { ORDER_STATUSES } from "@constants/business/orderStatuses";
import { IAxiosResponseWithError, network } from "@utils/network/network";

export interface IRegularBNPLOffer {
  loanAmount: number;
  tenure: number;
  offerType: string;
  installments: number;
  interestRate: number;
  profit: number;
}

export interface IDividedBNPLOffer {
  loanAmount: number;
  tenure: number;
  offerType: string;
  amountPerMonth: number;
}

export interface ILinkOfferResponse {
  eligibilityStatus: boolean;
  principle: number;
  regularOffers: IRegularBNPLOffer[];
  bnplOffers: IDividedBNPLOffer[];
}

export type ILinkOfferError = ORDER_STATUSES;

type useRequestType = [linkOrder: () => Promise<IAxiosResponseWithError<ILinkOfferResponse, ILinkOfferError>>];

export const useLinkOrder = (checkoutToken: string): useRequestType => {
  const linkOrder = (): Promise<IAxiosResponseWithError<ILinkOfferResponse, ILinkOfferError>> => {
    return network.request<ILinkOfferResponse, ILinkOfferError>("order", "customer/checkout/link", {
      method: "POST",
      params: {
        checkoutToken,
      },
    });
  };

  return [linkOrder];
};
