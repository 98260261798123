import { useEffect } from "react";

import * as httpCodes from "@constants/httpStatuses";
import { DEFAULT_USER_INFO } from "@constants/sessionStorageDefaults";
import { USER_INFO_KEY } from "@constants/sessionStorageKeys";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { getCustomerInfoRequest } from "@utils/network/customerInfo/getCustomerInfoRequest";

// TODO: Possibly this request redundant, because this data setting to session storage on Login page.
export const useUserData = (): { userInfo: typeof DEFAULT_USER_INFO } => {
  const [userInfo, setUserInfoToStorage] = useSessionStorageState(USER_INFO_KEY, DEFAULT_USER_INFO);

  useEffect(() => {
    if (!userInfo.englishFirstName || !userInfo.arabicFirstName) {
      const getUserInfo = async () => {
        try {
          const { data: updatedUser, status } = await getCustomerInfoRequest();
          if (status === httpCodes.OK) {
            setUserInfoToStorage({
              ...userInfo,
              status: updatedUser.status,
              englishFirstName: updatedUser.englishFirstName,
              arabicFirstName: updatedUser.arabicFirstName,
            });
          } else {
            console.log("User info request status:", status);
          }
        } catch (e) {
          console.log("User info request error");
        }
      };

      getUserInfo();
    }
  }, []);

  return { userInfo };
};
