import { Typography } from "@ui-kit";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { IDialogContents } from "@appTypes/dialogs";

import { BasicDialog } from "./BasicDialog";

export interface IDialogProps {
  open: boolean;
  dialog: IDialogContents;
  onAgree?: () => void;
  onClose?: () => void;
  onCancel?: () => void;
}

export const Dialog: FC<IDialogProps> = (props) => {
  const { dialog, open, onAgree, onClose, onCancel } = props;
  const { title, description, agreeText, cancelText, icon, isNotError } = dialog;
  const { t } = useTranslation();

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      onAgree={onAgree}
      onCancel={onCancel}
      title={t(title)}
      isNotError={isNotError}
      description={
        typeof description === "string" ? (
          <Typography variant="p3" textAlign="center" component="p">
            {t(description)}
          </Typography>
        ) : (
          description
        )
      }
      icon={icon}
      agreeButtonText={agreeText ? t(agreeText) : undefined}
      cancelButtonText={cancelText ? t(cancelText) : undefined}
    />
  );
};
