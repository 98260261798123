import { Typography } from "@ui-kit";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { BasicDialog } from "@components/Dialogs/BasicDialog";
import { SetupPassword } from "@components/SetupPassword";
import * as httpCodes from "@constants/httpStatuses";
import { dialogContents } from "@constants/popups";
import { RESET_PASSWORD_KEY } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";
import { DEFAULT_RESET_PASSWORD_DATA } from "@pages/ForgotPassword/forgotPassword.constants";
import { useDialog } from "@utils/hooks/useDialog";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { hasObjectValues } from "@utils/services/Objects/Objects.service";

import { IUserDataForm, useSetUpNewPasswordRequest } from "./hooks/useSetUpNewPassword";

export const ResetUserPassword: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userData] = useSessionStorageState<{ form: IUserDataForm }>(RESET_PASSWORD_KEY, DEFAULT_RESET_PASSWORD_DATA);
  const [enteredPassword, setEnteredPassword] = useState("");
  const [setUpNewPasswordRequest] = useSetUpNewPasswordRequest();
  const [isDialogOpen, closeDialog, openDialog] = useDialog();
  const [dialogContent, setDialogContent] = useState(dialogContents.defaultDialogContent);

  const somethingWrongDialog = useCallback(() => {
    openDialog();
    setDialogContent(dialogContents.somethingWentWrong);
  }, []);

  const handleContinueClick = useCallback(
    async (passwordInputValue?: string) => {
      closeDialog();
      let password = "";
      if (!passwordInputValue) {
        password = enteredPassword;
      } else {
        await setEnteredPassword(passwordInputValue);
        password = passwordInputValue;
      }

      if (userData?.form && hasObjectValues(userData.form)) {
        try {
          const { status } = await setUpNewPasswordRequest({ ...userData.form }, password);

          switch (status) {
          case httpCodes.OK:
            history.push(siteMap.ForgotPasswordOTPPage.path);
            break;

          default:
            somethingWrongDialog();
            break;
          }
        } catch (e) {
          somethingWrongDialog();
          return;
        }
      } else {
        somethingWrongDialog();
      }
    },
    [enteredPassword],
  );

  return (
    <>
      <SetupPassword
        title={t("pageHeader.setupPassword")}
        continueText={t("buttons.continue")}
        goBackText={t("buttons.goBack")}
        onContinue={handleContinueClick}
        onGoBack={history.goBack}
      />
      <BasicDialog
        open={isDialogOpen}
        onClose={closeDialog}
        onAgree={handleContinueClick}
        title={t(dialogContent.title)}
        description={
          <Typography variant="p3" textAlign="center" component="p">
            {t(dialogContent.description)}
          </Typography>
        }
        icon={dialogContent.icon}
        cancelButtonText={t(dialogContent.cancelText)}
        agreeButtonText={t(dialogContent.agreeText)}
      />
    </>
  );
};
