import { IAxiosResponseWithError, network } from "@utils/network/network";

import { IContractInfo } from "./types";

export const getContractStatusRequest = async (orderId = ""): Promise<IAxiosResponseWithError<IContractInfo>> => {
  return await network.request<IContractInfo>("order", "customer/contract/status", {
    method: "GET",
    ...(orderId
      ? {
        params: {
          orderId,
        },
      }
      : {}),
  });
};
