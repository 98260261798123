import { IOTPInfoResponse } from "@appTypes/responses";
import { IAxiosResponseWithError, network } from "@utils/network/network";

type useGetOTPStatusReturnType = [
  getResetPasswordOTPCodeStatus: (
    id: string,
    phoneNumber: string,
  ) => Promise<IAxiosResponseWithError<IOTPInfoResponse>>,
];

export const useGetInfoResetPasswordOTP = (): useGetOTPStatusReturnType => {
  const getResetPasswordOTPCodeStatus = (
    id: string,
    phoneNumber: string,
  ): Promise<IAxiosResponseWithError<IOTPInfoResponse>> => {
    return network.request<IOTPInfoResponse>("keycloak", "password/reset/otp/info", {
      method: "GET",
      params: {
        phone: `+966${phoneNumber}`,
        id: id,
      },
    });
  };

  return [getResetPasswordOTPCodeStatus];
};
