import { Typography } from "@ui-kit";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { IDialogContents } from "@appTypes/dialogs";
import { IOTPInfoResponse } from "@appTypes/responses";
import { BasicDialog } from "@components/Dialogs/BasicDialog";
import { SetOTPComponent } from "@components/SetOTP";
import * as httpCodes from "@constants/httpStatuses";
import { dialogContents } from "@constants/popups";
import { DEFAULT_MERCHANT_DATA_KEY } from "@constants/sessionStorageDefaults";
import { MERCHANT_DATA_KEY } from "@constants/sessionStorageKeys";
import { useGetOTPInfo } from "@pages/SetOTP/hooks/useGetOTPInfo";
import { useVerifyOTP } from "@pages/SetOTP/hooks/useVerifyOTP";
import { authorizationStore } from "@store/authorization";
import { useDialog } from "@utils/hooks/useDialog";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { useGenerateOTP } from "@utils/network/useGenerateOTP";

import { signContractRequest } from "../SignContract.tsx/network/signContract";
import { webCheckoutPageMap } from "../constants/pageMap";

const ACCOUNT_IS_LOCKED = "ACCOUNT_IS_LOCKED";
const SOMETHING_WENT_WRONG = "SOMETHING_WENT_WRONG";
const RESET_SUCCEEDED = "RESET_SUCCEEDED";

export const SignContractOTP: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  // Dialog
  const [dialogType, setDialogType] = useState("");
  const [isDialogOpen, closeDialog, openDialog] = useDialog();
  const [currentDialogContent, setCurrentDialogContent] = useState<IDialogContents | undefined>();

  const [merchantStorageValue] = useSessionStorageState(MERCHANT_DATA_KEY, DEFAULT_MERCHANT_DATA_KEY);
  const [codeStatusData, setCodeStatusData] = useState<IOTPInfoResponse | undefined>();

  const [getNewOTPCode] = useGenerateOTP();
  const [verifyOTPCode] = useVerifyOTP();
  const [getOTPCodeStatus] = useGetOTPInfo(); // GET otp/info

  useEffect(() => {
    generateOTP();
  }, []);

  const generateOTP = async () => {
    try {
      const { data, status } = await getNewOTPCode("CONTRACT_SIGN", undefined, merchantStorageValue.orderId);

      if (status === httpCodes.OK) {
        setCodeStatusData(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleContinue = useCallback(async (otpInputValue, setInputError, setOtpMessage) => {
    try {
      const { data, status } = await verifyOTPCode(
        otpInputValue,
        undefined,
        "CONTRACT_SIGN",
        merchantStorageValue.orderId,
      );
      switch (status) {
      case httpCodes.OK:
        if (data.result === "true") {
          authorizationStore.setAuthorization(data.token);
          handleSignContract();
        } else {
          setInputError(true);
          setOtpMessage("errors.WRONG_OTP");
          await sendRequestForOtpCodeStatus();
        }
        break;
      default:
        openUnexpectedErrorDialog();
      }
    } catch (error) {
      openUnexpectedErrorDialog();
      console.log(error);
      return;
    }
  }, []);

  const handleSignContract = async () => {
    try {
      const { status } = await signContractRequest();

      if (status === httpCodes.OK) {
        history.push(webCheckoutPageMap.EPromissory.path);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleAgreeClick = () => {
    if (dialogType === ACCOUNT_IS_LOCKED || dialogType === RESET_SUCCEEDED) {
      //logout
      history.push(webCheckoutPageMap.EPromissory.path);
    }
    closeDialog();
  };

  const openUnexpectedErrorDialog = () => {
    setDialogType(SOMETHING_WENT_WRONG);
    setCurrentDialogContent(dialogContents.somethingWentWrong);
    openDialog();
  };

  const sendRequestForOtpCodeStatus = useCallback(async () => {
    try {
      const { data, status } = await getOTPCodeStatus();
      switch (status) {
      case httpCodes.OK:
        setCodeStatusData(data);
        break;
      default:
        openUnexpectedErrorDialog();
        break;
      }
    } catch (error) {
      openUnexpectedErrorDialog();
    }
  }, []);

  return (
    <>
      <SetOTPComponent
        title="pageHeader.otpVerification"
        subtitle={t("pageContent.otpVerification")}
        pinLength={4}
        onGoBack={history.goBack}
        codeStatusData={codeStatusData}
        handleResendOTP={() =>
          getNewOTPCode("CONTRACT_SIGN", merchantStorageValue.checkoutToken, merchantStorageValue.orderId)
        }
        handleContinue={handleContinue}
        dialogMethods={{ openUnexpectedErrorDialog, setCurrentDialogContent, openDialog, setDialogType }}
      />
      {currentDialogContent && (
        <BasicDialog
          open={isDialogOpen}
          // onClose={closeDialog}
          onAgree={handleAgreeClick}
          title={t(currentDialogContent.title)}
          isSuccess={currentDialogContent.isSuccess ?? false}
          description={
            <Typography variant="p3" textAlign="center" component="p">
              {typeof currentDialogContent.description === "string"
                ? t(currentDialogContent.description)
                : currentDialogContent.description}
            </Typography>
          }
          icon={currentDialogContent.icon}
          agreeButtonText={currentDialogContent.agreeText ? t(currentDialogContent.agreeText) : undefined}
        />
      )}
    </>
  );
};
