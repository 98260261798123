import { IAxiosResponseWithError, network } from "@utils/network/network";

export interface data {
  orderId: string;
}

export const getCancelDeleteAccount = async (): Promise<IAxiosResponseWithError<data>> => {
  return await network.request<data>("connect", "settings/cancel-delete-account", {
    method: "GET",
  });
};
