import axiosCommon, { AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from "axios";

import {
  BASE_URL,
  CLIENT_CONNECT,
  CLIENT_FRONTEND,
  CONNECT_SERVICE_URL,
  KEYCLOAK_SERVICE_URL,
  ORDER_SERVICE_URL,
  STORAGE_SERVICE_URL,
  UNPROTECTED_ENDPOINTS,
  WITHOUT_TOKEN_ENDPOINTS,
  unauthorizedRequests,
} from "@constants/api";
import * as httpCodes from "@constants/httpStatuses";
import { authorizationStore } from "@store/authorization";
import { languages, languageService } from "@store/languageService";
import { locationService } from "@store/location";
import { COUNTDOWN_TIMES, getTimeRemaining } from "@utils/services/Timer/Timer.service";

import { refreshTokenRequest } from "./useLogin";

type services = "keycloak" | "order" | "storage" | "connect";

export interface IAxiosResponseWithError<T, E = string> extends AxiosResponse<T> {
  error?: {
    message: string;
    error?: E;
  };
}

export const servicesMap: Record<services, string> = {
  keycloak: KEYCLOAK_SERVICE_URL,
  order: ORDER_SERVICE_URL,
  storage: STORAGE_SERVICE_URL,
  connect: CONNECT_SERVICE_URL,
};

class NetworkService {
  private readonly axiosInstance: AxiosInstance;
  private lastRequestTime?: Date;
  private cancelToken?: CancelTokenSource;
  private sleep(ms: number) {
    new Promise((r) => setTimeout(r, ms));
  }

  constructor() {
    this.axiosInstance = axiosCommon.create({
      baseURL: BASE_URL,
      headers: {
        "Content-type": "application/json",
      },
    });

    // Refresh token interceptor
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const { response } = error;

        if (error.config.url !== `${servicesMap.connect}/customer/token/refreshment`) {
          const originalRequest = error.config;

          const needRefresh =
            !WITHOUT_TOKEN_ENDPOINTS.includes(originalRequest.url) &&
            response?.status === httpCodes.UNAUTHORIZED &&
            error.config &&
            !error.config._isRetry;

          if (needRefresh) {
            originalRequest._isRetry = true;
            try {
              const clientId = UNPROTECTED_ENDPOINTS.includes(error.config.url)
                ? error.config.params.type !== "CONTRACT_SIGN"
                  ? CLIENT_FRONTEND
                  : CLIENT_CONNECT
                : CLIENT_CONNECT;
              const refreshResponse = await refreshTokenRequest(authorizationStore.getValue().refresh_token, clientId);

              authorizationStore.setAuthorization(refreshResponse.data);

              const res = this.axiosInstance.request(originalRequest);
              await this.sleep(500);
              return res;
            } catch (e) {
              console.log("NOT AUTHORIZED");
            }
          }

          return {
            error: { ...response?.data },
            status: response?.status,
          };
        }

        return {
          error: { ...response?.data },
          status: response?.status,
        };
      },
    );

    // Auto logout interceptor
    this.axiosInstance.interceptors.request.use((req) => {
      if (!unauthorizedRequests.includes(req?.url || "") && req && req.headers) {
        const authConfigs = authorizationStore.getValue();

        if (authConfigs.token_type && authConfigs.access_token) {
          req.headers.Authorization = `${authConfigs.token_type} ${authConfigs.access_token}`;
        }
      }
      req.headers = { ...req.headers, "Accept-Language": languageService.getValue().languageCode ?? languages.AR };

      if (this.lastRequestTime) {
        const timeBeforeLogout = getTimeRemaining(this.lastRequestTime, COUNTDOWN_TIMES["15min"]);

        if (timeBeforeLogout && req.url !== `${servicesMap.connect}/customer/logout`) {
          const requestTime = new Date();
          this.lastRequestTime = new Date(new Date(requestTime.getTime() + requestTime.getTimezoneOffset() * 60000));
        } else if (
          !timeBeforeLogout &&
          !unauthorizedRequests.includes(req.url as string) &&
          req.url !== `${servicesMap.connect}/customer/logout`
        ) {
          this.cancelToken && this.cancelToken.cancel();

          this.lastRequestTime = undefined;
          authorizationStore.setIsLogged(false);
        }
      } else {
        const currentDate = new Date();
        this.lastRequestTime = new Date(currentDate.getTime() + currentDate.getTimezoneOffset() * 60000);
      }
      authorizationStore.setLastRequest(req.url || "");

      return req;
    });
  }

  request<T, E = string>(
    service: services,
    action: string,
    config: Omit<AxiosRequestConfig, "url">,
  ): Promise<IAxiosResponseWithError<T, E>> {
    locationService.setLoadingFlag(true);
    this.cancelToken = axiosCommon.CancelToken.source();
    const modifiedConfigs = { ...config, cancelToken: this.cancelToken.token };

    const url = `${servicesMap[service]}/${action}`;

    return this.axiosInstance.request({ ...modifiedConfigs, url }).finally(() => locationService.setLoadingFlag(false));
  }
}

export const network = new NetworkService();
