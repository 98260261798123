import { useHistory } from "react-router-dom";

import { ORDER_STATUSES } from "@constants/business/orderStatuses";
import { E_PROMISSORY_NOTE_STATE } from "@utils/network/contractInfo/types";

import { orderStatusMap } from "../constants/orderStatusMap";
import { webCheckoutPageMap } from "../constants/pageMap";

export const useRedirectByStatus = (
  currentStatus: keyof typeof ORDER_STATUSES | "",
  fromSMSLink: boolean,
): {
  redirectByStatus(
    status: keyof typeof ORDER_STATUSES | "",
    isNoteSigned: boolean,
    promissoryNoteState?: E_PROMISSORY_NOTE_STATE,
  ): boolean;
} => {
  const history = useHistory();

  const redirectByStatus = (
    statusFromBack: keyof typeof ORDER_STATUSES,
    isNoteSigned: boolean,
    promissoryNoteState: E_PROMISSORY_NOTE_STATE = E_PROMISSORY_NOTE_STATE.UNDEFINED,
  ) => {
    const isContractSigned = statusFromBack === ORDER_STATUSES.SIGNED || statusFromBack === ORDER_STATUSES.DISBURSED;
    let isEPromissoryOk = false;

    switch (promissoryNoteState) {
    case E_PROMISSORY_NOTE_STATE.UNDEFINED: {
      isEPromissoryOk = isNoteSigned;
      break;
    }
    case E_PROMISSORY_NOTE_STATE.SIGNED:
    case E_PROMISSORY_NOTE_STATE.NOT_NEED: {
      isEPromissoryOk = true;
      break;
    }
    case E_PROMISSORY_NOTE_STATE.WAITING: {
      isEPromissoryOk = false;
      break;
    }
    }

    if (
      currentStatus === statusFromBack &&
      !(currentStatus === ORDER_STATUSES.SIGNED || currentStatus === ORDER_STATUSES.DISBURSED)
    ) {
      return true;
    } else if (statusFromBack !== ORDER_STATUSES.SIGNED && statusFromBack !== ORDER_STATUSES.DISBURSED) {
      const nextStep = orderStatusMap[statusFromBack] || "";

      if (nextStep) {
        history.push(nextStep);

        return false;
      }

      return true;
    } else if (!fromSMSLink) {
      const nextStep = isEPromissoryOk ? webCheckoutPageMap.LoanActivated.path : webCheckoutPageMap.EPromissory.path;

      history.push(nextStep);

      return !isEPromissoryOk && webCheckoutPageMap.EPromissory.path === history.location.pathname;
    } else if (fromSMSLink && isEPromissoryOk && isContractSigned) {
      history.push(webCheckoutPageMap.ContractSigned.path);

      return false;
    } else if (fromSMSLink && !isEPromissoryOk && isContractSigned) {
      history.push(webCheckoutPageMap.EPromissory.path);
    }

    return true;
  };

  return { redirectByStatus };
};
