import { Box } from "@mui/material";
import { Button, Typography } from "@ui-kit";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import CONTRACT_TEMPLATE from "@assets/uiImages/contract_template.png";
import { StepperArabic } from "@components/StepperArabic";

import { webCheckoutPageMap } from "..";
import { useSteps } from "../hooks/useSteps";

export const WaitingForSigning: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const steps = useSteps();

  const handleGoToSign = () => {
    history.push(webCheckoutPageMap.SignContract.path);
  };

  return (
    <Box margin="auto" maxWidth="470px">
      <StepperArabic steps={steps} activeStep={1} />
      <Box mt="40px" display="flex" justifyContent="center">
        <img alt="Contract template" src={CONTRACT_TEMPLATE} />
      </Box>
      <Box display="flex" flexDirection="column" mt="40px">
        <Box>
          <Typography fontWeight="bold" textAlign="center" variant="h3">
            {t("checkout.title.contractReady")}
          </Typography>
        </Box>
        <Box mt="16px">
          <Typography component="p" textAlign="center" variant="p1">
            {t("checkout.content.fullAgreementVersion")}
          </Typography>
        </Box>
        <Box mt="40px" display="flex" justifyContent="center">
          <Button onClick={handleGoToSign}>{t("buttons.goToContract") as string}</Button>
        </Box>
      </Box>
    </Box>
  );
};
