import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import {
  Button,
  PasswordInput,
  PasswordStrengthIndicator,
  Typography,
  strengthType,
  CustomIconButton,
  ArrowLeftIcon,
  ArrowRightIcon,
  COLORS,
} from "@ui-kit";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  getPasswordRequirements,
  getPasswordStrength,
  TPasswordRequirementReturnType,
} from "@utils/services/Password/Password.service";

import { PASSWORD_DONT_MATCH_CRITERIA_ERROR, PASSWORD_DONT_MATCH_ERROR } from "./SetupPassword.constants";
import { PasswordRequirement } from "./components/PasswordRequirement";

interface ISetupPassword {
  title: string;
  onGoBack: () => void;
  onContinue: (password?: string) => void;
  goBackText?: string;
  continueText: string;
}

export const SetupPassword: React.FC<ISetupPassword> = ({ title, onGoBack, onContinue, goBackText, continueText }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [passwordInputValue, setPasswordInputValue] = useState("");
  const [repeatPasswordInputValue, setRepeatPasswordInputValue] = useState("");
  const [passwordRequirements, setPasswordRequirements] = useState<Array<TPasswordRequirementReturnType>>();
  const [passwordStrength, setPasswordStrength] = useState<strengthType>("Insufficient");
  const [passwordError, setPasswordError] = useState("");
  const [repeatPasswordError, setRepeatPasswordError] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handlePasswordInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPasswordInputValue(event.target.value);
    setRepeatPasswordError("");
  };

  const handleRepeatPasswordInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRepeatPasswordInputValue(event.target.value);
    setRepeatPasswordError("");
    setButtonDisabled(false);
  };

  const handlePasswordInputBlur = () => {
    if (passwordInputValue && passwordStrength === "Insufficient") {
      setPasswordError(`errors.${PASSWORD_DONT_MATCH_CRITERIA_ERROR}`);
    } else {
      setPasswordError("");
    }
  };

  const handleContinueClick = useCallback(() => {
    if (passwordInputValue !== repeatPasswordInputValue) {
      setRepeatPasswordError(`errors.${PASSWORD_DONT_MATCH_ERROR}`);
      setButtonDisabled(true);
    } else {
      setRepeatPasswordError("");
      onContinue(passwordInputValue);
    }
  }, [passwordInputValue, repeatPasswordInputValue, theme.direction]);

  useEffect(() => {
    const passwordStrength = getPasswordStrength(passwordInputValue);
    setPasswordStrength(passwordStrength);
    setPasswordRequirements(getPasswordRequirements(passwordInputValue, passwordStrength, theme.direction === "rtl"));
  }, [passwordInputValue, theme.direction]);

  const renderPasswordRequirements = useCallback(() => {
    return passwordRequirements?.map((requirement) => (
      <PasswordRequirement key={requirement.message} requirement={requirement} isMobile={isMobile} />
    ));
  }, [passwordRequirements, isMobile]);

  return (
    <Box>
      {!isMobile && (
        <Box>
          <Typography
            variant="p1"
            fontWeight="bold"
            display={"block"}
            textAlign={{ xs: "center", sm: theme.direction === "ltr" ? "left" : "right" }}
          >
            {title}
          </Typography>
        </Box>
      )}
      <Grid container padding={{ xs: "0 0 30px", sm: "40px 0" }} justifyContent={{ xs: "center", sm: "space-between" }}>
        <Grid item md={12} lg={6}>
          <Box sx={{ marginBottom: "24px" }} maxWidth={"376px"}>
            <PasswordInput
              label={t("labels.password")}
              labelId="password-input"
              value={passwordInputValue}
              onChange={handlePasswordInputChange}
              onBlur={handlePasswordInputBlur}
              onFocus={() => setPasswordError("")}
              showAdditionalMessage={!!passwordError}
              errorText={t(passwordError)}
              placeholder={t("labels.password")}
            />
            {passwordStrength !== "Insufficient" && (
              <PasswordStrengthIndicator
                strengthCode={passwordStrength}
                label={t(`labels.passwordStrength.${passwordStrength}`)}
              />
            )}
          </Box>
          {isMobile && renderPasswordRequirements()}
          <PasswordInput
            label={t("labels.passwordRepeat")}
            labelId="repeat-password-input"
            value={repeatPasswordInputValue}
            onChange={handleRepeatPasswordInputChange}
            showAdditionalMessage={!!repeatPasswordError}
            errorText={t(repeatPasswordError)}
            placeholder={t("labels.passwordRepeat")}
          />
        </Grid>
        <Grid
          item
          md={12}
          lg={6}
          display={"grid"}
          justifyContent={{ xs: "center", sm: "start", lg: "end" }}
        >
          {!isMobile && (
            <>
              <Typography variant="p3" sx={{ color: COLORS.GREY_32, display: "block", marginBottom: "8px" }}>
                {t("labels.passwordRequire")}
              </Typography>
              {renderPasswordRequirements()}
            </>
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent={"space-between"}>
        {!!goBackText && (
          <>
            {isMobile ? (
              <CustomIconButton width="56px" height="56px" onClick={onGoBack}>
                {theme.direction === "rtl" ? <ArrowRightIcon /> : <ArrowLeftIcon />}
              </CustomIconButton>
            ) : (
              <Button
                tertiary
                sx={{ padding: "14px 22px", minWidth: { sm: "240px" } }}
                onClick={onGoBack}
                color={!isMobile ? "secondary" : "inherit"}
              >
                {goBackText}
              </Button>
            )}
          </>
        )}
        <Button
          sx={{ width: { xs: "255px", sm: "270px" } }}
          variant="contained"
          disabled={
            !repeatPasswordInputValue ||
            (passwordStrength === "Insufficient" && !!repeatPasswordInputValue) ||
            buttonDisabled
          }
          onClick={handleContinueClick}
        >
          {continueText}
        </Button>
      </Grid>
    </Box>
  );
};
