import { IAxiosResponseWithError, network } from "@utils/network/network";

import { IContractTokenInfo } from "./types";

export const getContractTokenStatusRequest = async (
  checkoutToken: string,
): Promise<IAxiosResponseWithError<IContractTokenInfo>> => {
  return await network.request<IContractTokenInfo>("order", `web/customer/contract/info?orderToken=${checkoutToken}`, {
    method: "GET",
  });
};
