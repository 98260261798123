import { IAxiosResponseWithError, network } from "@utils/network/network";

export interface CommodityRes {
  result: boolean;
}

export const acceptCommodity = async (
  checkoutToken: string,
  decision: string,
): Promise<IAxiosResponseWithError<CommodityRes>> => {
  return await network.request<CommodityRes>("order", "customer/checkout/nafaes/offer", {
    method: "POST",
    params: {
      checkoutToken,
      decision,
    },
  });
};
