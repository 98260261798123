import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { ArrowLeftIcon, BaseInput, Button, CustomIconButton, PhoneNumberInput, Typography } from "@ui-kit";
import { noop } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { BasicDialog } from "@components/Dialogs/BasicDialog";
import * as httpCodes from "@constants/httpStatuses";
import { dialogContents } from "@constants/popups";
import { DEFAULT_USER_INFO } from "@constants/sessionStorageDefaults";
import { USER_INFO_KEY } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";
import {
  DEFAULT_FORM_DATA,
  DEFAULT_FORM_ERRORS,
  DEFAULT_RESET_PASSWORD_DATA,
  FORM_FIELDS,
} from "@pages/ForgotPassword/forgotPassword.constants";
import { FormErrors, FormFields } from "@pages/ForgotPassword/forgotPassword.types";
import { forgotPasswordValidators } from "@pages/ForgotPassword/forgotPassword.validators";
import { useVerifyUser } from "@pages/ForgotPassword/hooks/useVerifyUser";
import { menuService } from "@store/menu";
import { useDialog } from "@utils/hooks/useDialog";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { hasObjectValuesCorrect as hasObjectValues } from "@utils/services/Objects/Objects.service";
import { validateFormFields } from "@utils/services/Validation";

export const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();

  const [userInfo, setUserInfoToStorage] = useSessionStorageState(USER_INFO_KEY, DEFAULT_USER_INFO);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [errors, setFormErrors] = useState<FormErrors>(DEFAULT_FORM_ERRORS);
  const [storageValue, setDataToStorage] = useSessionStorageState("resetPassword", { ...DEFAULT_RESET_PASSWORD_DATA });
  const [isDialogOpen, closeDialog, openDialog] = useDialog();
  const [dialogContent, setDialogContent] = useState(dialogContents.defaultDialogContent);

  const [formFields, setFormData] = useState<FormFields>({
    ...(storageValue?.form || DEFAULT_FORM_DATA),
  });

  const [verifyUserRequest] = useVerifyUser();

  const handleNationalIdInputChange =
    (formKey: string, maxLength: number) => (event: React.ChangeEvent<{ value: string }>) => {
      if (event.target.value.length <= maxLength) {
        setFormData((prevState) => ({ ...prevState, [formKey]: event.target.value }));
      }
    };

  const handlePhoneNumberChange = (event: React.ChangeEvent<{ value: string }>) => {
    const maxLength = event.target.value.startsWith("05") ? 10 : 9;

    if (event.target.value.length <= maxLength) {
      setFormData((prevState) => ({ ...prevState, [FORM_FIELDS.MOBILE_PHONE]: event.target.value }));
    }
  };

  const handleAgreeClick = () => {
    closeDialog();

    if (dialogContent.title === dialogContents.accountLockedLogin.title) {
      history.push(siteMap.UnblockUser.path, { nationalId: formFields[FORM_FIELDS.NATIONAL_ID] });
    }
  };

  const handleContinueClick = async () => {
    const { isFormValid, formErrors } = validateFormFields(formFields, forgotPasswordValidators);

    if (isFormValid) {
      try {
        await setDataToStorage({ form: { ...formFields } });
        setFormErrors(DEFAULT_FORM_ERRORS);

        const { status } = await verifyUserRequest(formFields);
        switch (status) {
        case httpCodes.OK:
          setUserInfoToStorage({
            ...userInfo,
            phoneNumber: "+966*****" + formFields[FORM_FIELDS.MOBILE_PHONE].slice(-4),
          });
          history.push(siteMap.ResetPasswordPage.path);
          break;
        case httpCodes.FORBIDDEN:
          setDialogContent({ ...dialogContents.accountLockedLogin, cancelText: "" });
          openDialog();
          break;
        case httpCodes.NOT_FOUND:
          setDialogContent(dialogContents.incorrectData);
          openDialog();
          break;
        default:
          setDialogContent(dialogContents.somethingWentWrong);
          openDialog();
          break;
        }
      } catch (error) {
        console.log(error);
        setDialogContent(dialogContents.somethingWentWrong);
        openDialog();
      }
    } else {
      setFormErrors(formErrors as FormErrors);
    }
  };

  useEffect(() => {
    menuService.setMenuData({ leftSide: "pageHeader.forgotPassword", rightSide: "", needIcon: false });
  }, []);

  return (
    <Box>
      {!isMobile && (
        <Typography
          variant="p1"
          fontWeight={theme.direction === "ltr" ? "bold" : "600"}
          textAlign={{ xs: "center", sm: theme.direction === "ltr" ? "left" : "right" }}
          fontSize={theme.direction === "ltr" ? "2rem" : "2.4rem"}
          component="h1"
        >
          {t("pageHeader.forgotPassword")}
        </Typography>
      )}
      <Box sx={{ marginTop: "8px" }}>
        <Typography
          variant="p3"
          component="p"
          textAlign={{ xs: "center", sm: theme.direction === "ltr" ? "left" : "right" }}
        >
          {t("pageContent.verifyDataToResetPassword")}
        </Typography>
      </Box>
      <Grid container columnSpacing={{ xs: 0, sm: 5 }} paddingY="40px">
        <Grid
          item
          md={6}
          sm={12}
          xs={12}
        >
          <BaseInput
            onChange={handleNationalIdInputChange(FORM_FIELDS.NATIONAL_ID, 10)}
            value={formFields[FORM_FIELDS.NATIONAL_ID]}
            errorText={errors[FORM_FIELDS.NATIONAL_ID].length ? t(`errors.${errors[FORM_FIELDS.NATIONAL_ID][0]}`) : ""}
            type="number"
            fullWidth
            label={t("labels.nationalIdOrIQAMA")}
            placeholder={t("placeholders.nationalIdOrIQAMA")}
            labelId="national-id"
            allowNumbersOnly={true}
          />
        </Grid>
        <Grid
          item
          md={6}
          sm={12}
          xs={12}
        >
          <PhoneNumberInput
            value={formFields[FORM_FIELDS.MOBILE_PHONE]}
            errorText={
              errors[FORM_FIELDS.MOBILE_PHONE].length ? t(`errors.${errors[FORM_FIELDS.MOBILE_PHONE][0]}`) : ""
            }
            onChange={handlePhoneNumberChange}
            label={t("labels.mobile")}
            type="number"
            labelId="phone-label-error"
            placeholder={t("placeholders.mobileNumber")}
            inputProps={{ maxLength: 10 }}
          />
        </Grid>
      </Grid>

      {/*buttons*/}
      <Grid container justifyContent={"space-between"}>
        {isMobile ? (
          <CustomIconButton
            color={"primary"}
            width="56px"
            height="56px"
            onClick={history.goBack}
          >
            <ArrowLeftIcon />
          </CustomIconButton>
        ) : (
          <Button
            tertiary
            color={!isMobile ? "secondary" : "inherit"}
            sx={{
              padding: "14px 22px",
              fontWeight: theme.direction === "ltr" ? "bold" : "600",
              minWidth: { sm: "240px" },
            }}
            onClick={history.goBack}
          >
            {t("buttons.goBack") as string}
          </Button>
        )}
        <Button
          sx={{ width: { xs: "200px", sm: "270px" }, fontWeight: theme.direction === "ltr" ? "bold" : "600" }}
          variant="contained"
          onClick={handleContinueClick}
          disabled={!hasObjectValues(formFields)}
        >
          {t("buttons.continue") as string}
        </Button>
      </Grid>
      <BasicDialog
        open={isDialogOpen}
        onAgree={handleAgreeClick}
        title={t(dialogContent.title)}
        description={
          <Typography variant="p3" textAlign="center" component="p">
            {t(dialogContent.description)}
          </Typography>
        }
        icon={dialogContent.icon}
        agreeButtonText={t(dialogContent.agreeText)}
      />
    </Box>
  );
};
