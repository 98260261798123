import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { IDialogInfo, IDialogWithHandler } from "@appTypes/dialogs";
import { SECTOR_INFO_KEY } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";

import { useSessionStorageState } from "../useSessionStorageState";
import { CHECK_SRS_DIALOG_TYPES, useCheckSRS } from "./useCheckSRS";
import { ISafetyCheckResponse, SAFETY_CHECK_DIALOG_TYPES, useSafetyCheck } from "./useSafetyCheck";

type srsFlowDialogs = SAFETY_CHECK_DIALOG_TYPES | CHECK_SRS_DIALOG_TYPES;

export interface ISRSFlowResult extends IDialogWithHandler<srsFlowDialogs, never> {
  isSuccess: boolean;
}

interface ISRSFlow {
  performSafetyCheck(): Promise<
    IDialogInfo<SAFETY_CHECK_DIALOG_TYPES, never> & { isSuccess: boolean; result?: ISafetyCheckResponse }
  >;
  runSrsFlow(nextPage?: string): Promise<ISRSFlowResult | void>;
}

export const useSRSFlow = (): ISRSFlow => {
  const history = useHistory();
  const [, setSectorInfo] = useSessionStorageState(SECTOR_INFO_KEY, {
    isSysEmploymentActual: false,
    selectedSector: "",
  });

  const { safetyCheck } = useSafetyCheck();
  const { performSrsCheck } = useCheckSRS();

  const performSafetyCheck = useCallback(async (): Promise<
    IDialogInfo<SAFETY_CHECK_DIALOG_TYPES, never> & { isSuccess: boolean; result?: ISafetyCheckResponse }
  > => {
    const { isSuccess, dialog, dialogType, result } = await safetyCheck();

    return { isSuccess, result, dialog, dialogType };
  }, [safetyCheck]);

  const runSrsFlow = async (nextPage = siteMap.EmploymentDetailsPage.path): Promise<ISRSFlowResult | void> => {
    const { isSuccess, result, dialog, dialogType } = await performSafetyCheck();
    if (!isSuccess || !result) {
      return { isSuccess, dialog, dialogType, lastAction: runSrsFlow, parameters: [nextPage] };
    }
    const { isRiskScoreActual, isSysEmploymentActual, selectedSector } = result;

    setSectorInfo({ isSysEmploymentActual, selectedSector });

    if (!isRiskScoreActual) {
      const checkSrs = async (isRetry?: boolean) => {
        const { isSuccess, dialog, dialogType } = await performSrsCheck(isRetry);

        if (!isSuccess) {
          return { isSuccess, dialog, dialogType, lastAction: checkSrs, parameters: [isRetry] };
        } else {
          history.push(nextPage);
          return;
        }
      };

      return checkSrs(false);
    }
    history.push(nextPage);

    return;
  };

  return { performSafetyCheck, runSrsFlow };
};
