import { IOTPInfoResponse } from "@appTypes/responses";

import { IAxiosResponseWithError, network } from "./network";

type useGetOTPStatusReturnType = [
  getNewOTPCode: (
    type?: string,
    checkoutToken?: string,
    orderId?: string,
  ) => Promise<IAxiosResponseWithError<IOTPInfoResponse>>,
];

export const useGenerateOTP = (): useGetOTPStatusReturnType => {
  const getNewOTPCode = (
    type?: string,
    checkoutToken?: string,
    orderId?: string,
  ): Promise<IAxiosResponseWithError<IOTPInfoResponse>> => {
    return network.request<IOTPInfoResponse>("connect", "otp/verify", {
      method: "GET",
      params: { type, checkoutToken, orderId },
    });
  };

  return [getNewOTPCode];
};
