import { IAxiosResponseWithError, network } from "@utils/network/network";

interface IOrderItem {
  id: string;
  category: string;
  name: string;
  imageUrl: string;
  quantity: number;
  unitPrice: {
    amount: number;
    currency: string;
  };
  price: string;
}

interface IOrderAmount {
  amount: number;
  currency: string;
}

export interface IOrderDetails {
  totalAmount: IOrderAmount;
  quantity: number;
  merchantType?: string;
  expirationDate?: string;
  items: IOrderItem[];
}
export interface ICustomerDetailsResponse {
  orderDetails: IOrderDetails;
  merchantType?: string;
}

type useRequestType = [
  loginUserRequest: (token: {
    checkoutToken?: string;
    orderToken?: string;
  }) => Promise<IAxiosResponseWithError<ICustomerDetailsResponse>>,
];

export const useCustomerDetails = (): useRequestType => {
  const customerDetailsRequest = async ({
    checkoutToken = "",
    orderToken = "",
  }): Promise<IAxiosResponseWithError<ICustomerDetailsResponse>> => {
    return await network.request<ICustomerDetailsResponse>("order", "customer/checkout/details", {
      method: "GET",
      params: checkoutToken ? { checkoutToken } : { orderToken },
    });
  };

  return [customerDetailsRequest];
};
