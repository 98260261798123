import { getEncryptedValue, saveEncryptedValue } from "@utils/hooks/useSessionStorageState";
import { useLogout } from "@utils/network/useLogout";

export const logoutWithRedirect = async (redirectUrl?: string): Promise<void> => {
  const [logoutUser] = useLogout();
  const tokenExpire = getEncryptedValue("tokenExpire");
  await logoutUser();
  await sessionStorage.clear();

  saveEncryptedValue("tokenExpire", tokenExpire || "");

  if (redirectUrl) {
    window.location.href = redirectUrl;
  }
};
