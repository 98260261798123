import { IAxiosResponseWithError, network } from "@utils/network/network";

export interface MerchantURLs {
  orderId: string;
  success: string;
  cancel: string;
  failure: string;
  merchantUrl: string;
}

export const getMerchantURLs = async (token: string): Promise<IAxiosResponseWithError<MerchantURLs>> => {
  return await network.request<MerchantURLs>("order", "customer/orders/urls?checkoutToken=" + token, {
    method: "GET",
  });
};
