import { useTheme, Box } from "@mui/material";
import { COLORS, Typography } from "@ui-kit";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { CountdownTimer } from "@components/CountdownTimer";
import { ResendButton } from "@components/ResendButton";
import { ITimeObject } from "@utils/services/Timer/timer.types";

interface IResendCodeProps {
  resendTimer: ITimeObject<number> | null;
  setResendTimer: Dispatch<SetStateAction<ITimeObject<number> | null>>;
  handleResendClick: () => void;
  isMobile?: boolean;
}

export const ResendCode: React.FC<IResendCodeProps> = ({ resendTimer, setResendTimer, handleResendClick }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      textAlign={{ xs: "center", sm: theme.direction === "rtl" ? "right" : "left" }}
      color={COLORS.GREY_64}
      marginTop="8px"
    >
      {resendTimer?.minutes || resendTimer?.seconds ? (
        <>
          <Typography
            sx={{
              color: COLORS.GREY_64,
              fontWeight: theme.direction === "rtl" ? 600 : "bold",
              display: "inline",
            }}
            fontSize={"1.4rem"}
          >
            {t("pageContent.resendCodeIn")}{" "}
          </Typography>
          <CountdownTimer
            hours={resendTimer?.hours}
            minutes={resendTimer?.minutes}
            seconds={resendTimer?.seconds}
            setTimer={setResendTimer}
          />
        </>
      ) : (
        <>
          <Typography display="inline" variant="p3" color={COLORS.GREY_64}>
            {t("pageContent.didntReceiveCode")}{" "}
          </Typography>
          <ResendButton onClick={handleResendClick}>
            <Typography variant="p3">{t("buttons.resend")}</Typography>
          </ResendButton>
        </>
      )}
    </Box>
  );
};
