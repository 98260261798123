import { Grid, Stack } from "@mui/material";
import { Button } from "@ui-kit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { ReactComponent as Icon } from "@assets/icons/confirm_commodity_purchase.svg";
import * as httpCodes from "@constants/httpStatuses";
import { DEFAULT_MERCHANT_DATA_KEY } from "@constants/sessionStorageDefaults";
import { MERCHANT_DATA_KEY } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";
import { menuService } from "@store/menu";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";

import { CommodityProduct, getCommodityProducts } from "./hooks/getCommodityProducts";
import { TitleText, DescText, MainBox, CenterContainer, Row, ProductName, Quantity, Price } from "./styles";

export const CommodityPurchase: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<CommodityProduct>();
  const [merchantData] = useSessionStorageState(MERCHANT_DATA_KEY, DEFAULT_MERCHANT_DATA_KEY);

  useEffect(() => {
    menuService.setMenuData({ leftSide: "", rightSide: "", needIcon: false });
    getProducts();
  }, []);

  const getProducts = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await getCommodityProducts(merchantData.checkoutToken);

      if (status === httpCodes.OK) {
        setData(data);
        console.log(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        // openUnexpectedErrorDialog();
      }
    } catch (e) {
      setIsLoading(false);
      // openUnexpectedErrorDialog();
    }
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent={"center"}>
            <Icon />
          </Stack>
          <Stack direction="row" justifyContent={"center"}>
            <TitleText variant="h4">{t("pageHeader.commodityOurchase")}</TitleText>
          </Stack>
          <Stack direction="row" justifyContent={"center"}>
            <DescText variant="h6">{t("pageContent.commodityOurchase")}</DescText>
          </Stack>
        </Grid>
        <CenterContainer item xs={12} textAlign={"start"}>
          <MainBox>
            <Row>
              <Grid>
                <ProductName>{data?.commodityName}</ProductName>
                <Quantity>
                  {t("labels.quantity")} : {data?.quantity}
                </Quantity>
              </Grid>
              <Price>
                {data?.amount} {t("currency." + data?.currency)}
              </Price>
            </Row>
          </MainBox>
        </CenterContainer>
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent={{ sm: "flex-end" }}
        >
          <Button
            sx={{ width: { xs: "100%", sm: "240px" } }}
            disabled={isLoading}
            onClick={() => history.push(siteMap.ConfirmCommodityPurchase.path)}
          >
            {t("buttons.proceed") as string}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
