import { FC } from "react";
import { Switch } from "react-router-dom";

import { PrivateRoute } from "@pages/AppRouter/PrivateRoute";

import { EmploymentDetails } from "./EmploymentDetails";
import { employmentSectorPageMap } from "./constants/pageMap";

export const EmploymentSectorRouter: FC = () => {
  return (
    <Switch>
      <PrivateRoute path={employmentSectorPageMap.EmploymentDetailsPage.path}>
        <EmploymentDetails />
      </PrivateRoute>
    </Switch>
  );
};
