import { COLORS } from "@ui-kit";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as ProcessCompleted } from "@assets/uiImages/process_completed.svg";
import { TextWithEmail } from "@components/TextWithEmail";
import { SUPPORT_EMAIL } from "@constants/common";
import { MERCHANT_DATA_KEY } from "@constants/sessionStorageKeys";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { logoutWithRedirect } from "@utils/services/Authentication/logoutWithRedirect";

import { OrderStatusContent } from "../components/OrderStatusContent";

export const Completed: FC = () => {
  const { t } = useTranslation();
  const [merchantData] = useSessionStorageState(MERCHANT_DATA_KEY, {
    checkoutToken: "",
    merchantUrl: "",
    merchantSuccessUrl: "",
    merchantFailureUrl: "",
    merchantCancelUrl: "",
    merchantOrderId: "",
  });

  const handleBackToStoreClick = () => {
    logoutWithRedirect(merchantData.merchantUrl);
  };

  return (
    <>
      <OrderStatusContent
        icon={<ProcessCompleted />}
        title={t("checkout.title.orderCompleted")}
        content={
          <TextWithEmail
            textKey={"checkout.content.orderCompleted"}
            email={SUPPORT_EMAIL}
            typographyVariant="p1"
            color={COLORS.GREY_64}
          />
        }
        continueButtonText={t("buttons.backToStore")}
        onContinue={handleBackToStoreClick}
      />
    </>
  );
};
