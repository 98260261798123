import { CUSTOMER_STATUSES } from "@appTypes/customerStatuses";
import { IAxiosResponseWithError, network } from "@utils/network/network";

export interface IUserInfo {
  englishFirstName: string;
  arabicFirstName: string;
  phoneNumber: string;
  otpGeneratedAt: string;
  blockedUntil: string;
  dateOfBirth: string;
  status: CUSTOMER_STATUSES;
}

export const getCustomerInfoRequest = async (): Promise<IAxiosResponseWithError<IUserInfo>> => {
  return await network.request<IUserInfo>("connect", "customer/info", {
    method: "GET",
  });
};
