import { DateObject } from "react-multi-date-picker";

interface IValidationResult {
  formErrors: { [formKey: string]: string[] };
  isFormValid: boolean;
}

/**
 * Check form-fields using validators object.
 * Each form field validates with validator according to formKey in validators object.
 * Returns object with keys of form field, which contains array of errors (string[]) or emty array
 * @param formFields - form data
 * @param validators - validators for form
 * @param currentDate - current date for
 * @returns IValidationResult
 */
export const validateFormFields = (
  formFields: { [formKey: string]: string | Date | DateObject },
  // eslint-disable-next-line
  validators: { [formKey: string]: (value: any) => string[] },
  currentDate?: Date,
): IValidationResult => {
  let isFormValid = true;

  const formErrors = Object.keys(formFields).reduce((formErrors: { [formKey: string]: string[] }, fieldKey: string) => {
    let errors: string[] = [];

    if (fieldKey.includes("_DATE") && !!validators[fieldKey] && currentDate) {
      // eslint-disable-next-line
      const chosenDate = new Date((formFields[fieldKey] as any) || "");
      const diff = currentDate.getTime() - (chosenDate || currentDate).getTime();
      const clientAge = chosenDate ? new Date(diff).getFullYear() - 1970 : "";

      errors = validators[fieldKey](String(clientAge));
    } else {
      errors = validators ? validators[fieldKey as keyof typeof validators](formFields[fieldKey] || "") : [];
    }
    if (errors.length) {
      formErrors = { ...formErrors, [fieldKey]: [...errors] };
      isFormValid = false;
    } else {
      formErrors = { ...formErrors, [fieldKey]: [] };
    }
    return { ...formErrors };
  }, {});

  return { formErrors, isFormValid };
};
