import { Box, useMediaQuery, useTheme } from "@mui/material";
import Step from "@mui/material/Step";
import MUIStepper from "@mui/material/Stepper";
import { FC } from "react";

import { COLORS } from "../../constants/enums/COLORS";
import { Typography } from "../Typography";
import { StepConnector, StepLabel } from "./Stepper.styles";
import { StepIcon } from "./components/StepIcon";

interface IStepperProps {
  steps: string[];
  activeStep?: number;
}

export const Stepper: FC<IStepperProps> = ({ steps, activeStep = 0 }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <MUIStepper alternativeLabel activeStep={activeStep} connector={<StepConnector />}>
      {steps.map((step, i) => (
        <Step key={step}>
          <StepLabel StepIconComponent={StepIcon}>
            <Box
              maxWidth="90px"
              display="flex"
              margin="auto"
              justifyContent="center"
            >
              <Typography
                variant={isMobile ? "c1" : "p3"}
                fontWeight={activeStep === i ? "bold" : ""}
                color={activeStep === i ? COLORS.SECONDARY_NAVY : "inherit"}
              >
                {step}
              </Typography>
            </Box>
          </StepLabel>
        </Step>
      ))}
    </MUIStepper>
  );
};
