import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { languages as LANGUAGES } from "@store/languageService";
import { convertDigitsToArabic } from "@utils/services/Text/Text.service";
import { convertSecondsToMinAndSec } from "@utils/services/Timer/Timer.service";
import { ITimeObject } from "@utils/services/Timer/timer.types";

export interface IUseCountdown {
  countdown: string;
  isExpired: boolean;
  startCountdown: (time: ITimeObject<number>) => void;
  resetCountdown: () => void;
}

export const useCountdown = (): IUseCountdown => {
  const { i18n } = useTranslation();
  const [counter, setCounter] = useState<number>(0);
  const [isExpired, setIsExpired] = useState<boolean>(true);

  const startCountdown = useCallback(
    (time: ITimeObject<number>) => {
      const { hours, minutes, seconds } = time;
      setIsExpired(false);
      setCounter(hours * 60 * 60 + minutes * 60 + seconds);
    },
    [setCounter],
  );

  const resetCountdown = useCallback(() => {
    setCounter(0);
  }, [setCounter]);

  const timerObject = convertSecondsToMinAndSec(counter);
  const translatedTimer = Object.keys(timerObject).reduce((timer: Record<string, string>, timerKey: string) => {
    return {
      ...timer,
      [timerKey]: convertDigitsToArabic(
        timerObject[timerKey as keyof ITimeObject<string>],
        i18n.language === LANGUAGES.AR,
      ),
    };
  }, {});

  const countdown = `${timerObject.hours !== "00" ? `${translatedTimer.hours}:` : ""}${translatedTimer.minutes}:${
    translatedTimer.seconds
  }`;

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      setIsExpired(true);
    }
    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  return { countdown, isExpired, startCountdown, resetCountdown };
};
