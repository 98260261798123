import { Switch, Route, Redirect } from "react-router-dom";

import "@translations/translations";

import { siteMap } from "@constants/siteMap";
import { EmploymentSectorRouter } from "@modules/EmploymentSector/EmploymentSector.router";
import { RegistrationRouter } from "@modules/Registration/Registration.router";
import { Terms } from "@modules/Registration/Terms";
import { WebCheckoutRouter } from "@modules/WebCheckout/WebCheckout.router";
import { Confirmation } from "@pages/Confirmation/Confirmation";
import { TokenExpired } from "@pages/TokenExpired";

import { LoginRouter } from "./LoginRouter";

export const MainContentRouter: React.FC = () => {
  return (
    <Switch>
      <Route path={siteMap.Terms.path}>
        <Terms />
      </Route>
      <Route path={siteMap.CreateAccountPage.path}>
        <RegistrationRouter />
      </Route>
      <Route path={siteMap.EmploymentDetailsPage.path}>
        <EmploymentSectorRouter />
      </Route>
      <Route path={siteMap.LogInPage.path}>
        <LoginRouter />
      </Route>
      <Route path={siteMap.TokenExpired.path}>
        <TokenExpired />
      </Route>
      <Route path={siteMap.InitialStep.path}>
        <WebCheckoutRouter />
      </Route>
      <Route path={siteMap.ConfirmationPage.path}>
        <Confirmation />
      </Route>
      <Route exact path="/">
        <Redirect to={siteMap.CreateAccountPage.path} />
      </Route>
      <Route path="*">
        <Redirect to={siteMap.Error404Page.path} />
      </Route>
    </Switch>
  );
};
