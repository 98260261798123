import { COMMON_REQUEST_DIALOGS, IDialogInfo } from "@appTypes/dialogs";
import * as httpCodes from "@constants/httpStatuses";
import { dialogContents } from "@constants/popups";
import { getCustomerInfoRequest, IUserInfo } from "@utils/network/customerInfo/getCustomerInfoRequest";

export interface IRequestResult extends IDialogInfo<COMMON_REQUEST_DIALOGS> {
  isSuccess: boolean;
  customerData?: IUserInfo;
}

type loginUserType = {
  getCustomerInfo: () => Promise<IRequestResult>;
};

export const useCustomerInfo = (): loginUserType => {
  const getCustomerInfo = async (): Promise<IRequestResult> => {
    const { data, status } = await getCustomerInfoRequest();

    switch (status) {
    case httpCodes.OK:
      return {
        isSuccess: true,
        customerData: data,
      };

    default:
      return {
        isSuccess: false,
        dialogType: COMMON_REQUEST_DIALOGS.SOMETHING_WENT_WRONG,
        dialog: dialogContents.somethingWentWrong,
      };
    }
  };

  return { getCustomerInfo };
};
