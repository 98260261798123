import { IAxiosResponseWithError, network } from "@utils/network/network";

export interface relativeResponse {
  isQfEmployee: boolean;
  relatedParties: relative[];
}

export interface relative {
  ResidentialID: string;
  Name: string;
  Position: string;
  Relationship: string;
  FromDate: string;
  ToDate: string;
}

export const getRelatives = async (orderId: string): Promise<IAxiosResponseWithError<relativeResponse>> => {
  return await network.request<relativeResponse>("connect", "customer/employmentRelatedPartiesInfo", {
    method: "GET",
    params: {
      orderId,
    },
  });
};
