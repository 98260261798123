import { Route, Switch } from "react-router-dom";

import { PrivateRoute } from "@pages/AppRouter/PrivateRoute";
import { CommodityPurchase } from "@pages/CommodityPurchase";
import { ConfirmCommodityPurchase } from "@pages/ConfirmCommodityPurchase";
import { NewCreditLimitCalculation } from "@pages/NewCreditLimitCalculation";
import { OfferDetails } from "@pages/OfferDetails";

import { SetKYC } from "./SetKYC";
import { SetUserData } from "./SetUserData";
import { SetUserPassword as SetUserPasswordV2 } from "./SetUserPassword";
import { UpdateDateOfBirth } from "./UpdateDateOfBirth";
import { registrationSiteMap } from "./pageMap";

export const RegistrationRouter: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute path={registrationSiteMap.NewCreditLimitCalculationPage.path}>
        <NewCreditLimitCalculation />
      </PrivateRoute>
      <PrivateRoute path={registrationSiteMap.OfferDetailsPage.path}>
        <OfferDetails />
      </PrivateRoute>
      <PrivateRoute path={registrationSiteMap.CommodityPurchase.path}>
        <CommodityPurchase />
      </PrivateRoute>
      <PrivateRoute path={registrationSiteMap.ConfirmCommodityPurchase.path}>
        <ConfirmCommodityPurchase />
      </PrivateRoute>
      <Route path={registrationSiteMap.SetupKYCPage.path}>
        <SetKYC />
      </Route>
      <Route path={registrationSiteMap.SetupPasswordPage.path}>
        <SetUserPasswordV2 />
      </Route>
      <Route path={registrationSiteMap.UpdateDateOfBirth.path}>
        <UpdateDateOfBirth />
      </Route>
      <Route path={registrationSiteMap.CreateAccountPage.path}>
        <SetUserData />
      </Route>
    </Switch>
  );
};
