export interface IDialogContents {
  closeBtnTitle?: string;
  title: string;
  description: string | JSX.Element;
  icon?: JSX.Element;
  agreeText?: string;
  cancelText?: string;
  isNotError?: boolean;
  isSuccess?: boolean;
}

export interface IDialogInfo<DialogType, OptionsType = undefined> {
  dialog?: IDialogContents;
  dialogType?: DialogType;
  dialogOptions?: OptionsType;
  isBlockingPopup?: boolean;
}

export interface IDialogWithHandler<DialogType, OptionsType = undefined> extends IDialogInfo<DialogType, OptionsType> {
  // eslint-disable-next-line
  lastAction?: (...params: any[]) => any;
  // eslint-disable-next-line
  parameters?: any[];
}

export interface IDialogComponentProps<DialogType, OptionsType = void>
  extends IDialogWithHandler<DialogType, OptionsType> {
  isDialogOpen: boolean;
  openDialog: () => void;
  closeDialog: () => void;
}

export enum COMMON_REQUEST_DIALOGS {
  SOMETHING_WENT_WRONG = "SOMETHING_WENT_WRONG",
}
