import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { Button, CustomDatePicker } from "@ui-kit";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateObject } from "react-multi-date-picker";
import { useHistory } from "react-router-dom";

import { IDialogWithHandler, COMMON_REQUEST_DIALOGS } from "@appTypes/dialogs";
import { StartPageHeader } from "@components/StartPageHeader/StartPageHeader";
import { TextWithLink } from "@components/TextWithLink/TextWithLink";
import { REGISTRATION_KEY } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";
import { languages } from "@store/languageService";
import { menuService } from "@store/menu";
import { useCheckSRS } from "@utils/hooks/useCheckSRS/useCheckSRS";
import { useDialog } from "@utils/hooks/useDialog";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { useLogout } from "@utils/network/useLogout";
import { convertArabicToDigit } from "@utils/services/Text/Text.service";
import { validateFormFields } from "@utils/services/Validation";
import { hasEmptyFields } from "@utils/services/Validation/validators";

import { DEFAULT_FORM_DATA, DEFAULT_FORM_ERRORS, FORM_FIELDS } from "./UpdateDateOfBirth.constants";
import { FormErrors, FormFields } from "./UpdateDateOfBirth.types";
import { UPDATE_DOB_DIALOGS } from "./UpdateDateOfBirth.types";
import { registrationInfoValidators } from "./UpdateDateOfBirth.validators";
import { UpdateDOBDialog } from "./components/UpdateDOBDialog";
import { useUpdateDateOfBirth, IUpdateDOBResult } from "./hooks/useUpdateDOB";

const StyledContinueButton = styled(Button)(({ theme }) => ({
  fontWeight: theme.direction === "ltr" ? "bold" : 600,
  width: "100%",
  maxWidth: "376px",
  [theme.breakpoints.up("sm")]: {
    width: "270px",
  },
}));

export const UpdateDateOfBirth: React.FC = () => {
  const history = useHistory();
  const [isDialogOpen, closeDialog, openDialog] = useDialog();

  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [storageValue, setDataToStorage] =
    useSessionStorageState<{ form: FormFields & { NATIONAL_ID?: string } }>(REGISTRATION_KEY);
  const [formFields, setFormData] = useState<FormFields>({ ...DEFAULT_FORM_DATA });
  const [isHijri, setHijriFlag] = useState(false);
  const [errors, setFormErrors] = useState<FormErrors>(DEFAULT_FORM_ERRORS);
  const [dialogInfo, setDialogInfo] =
    useState<IDialogWithHandler<COMMON_REQUEST_DIALOGS | UPDATE_DOB_DIALOGS, { blockedUntil?: string }>>();
  const currentDate = useMemo(() => {
    return new Date();
  }, []);
  const isNextDisabled = hasEmptyFields(formFields) || formFields.BIRTH_DATE === storageValue.form.BIRTH_DATE;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [updateDateOfBirth] = useUpdateDateOfBirth();
  const [logoutUser] = useLogout();
  const { checkSRS } = useCheckSRS();

  useEffect(() => {
    storageValue.form?.NATIONAL_ID && setHijriFlag(storageValue.form.NATIONAL_ID.startsWith("1"));

    // Set default value to 1400/01/01 Hijri
    setFormData((prevState) => ({ ...prevState, ...{ BIRTH_DATE: new DateObject("1979/11/21") } }));

    menuService.setMenuData({
      leftSide: "pageHeader.createAccount.create",
      rightSide: "pageHeader.createAccount.account",
      needIcon: true,
    });
  }, []);

  const handleDateChange = (chosenDate: DateObject): void =>
    setFormData((prevState) => ({ ...prevState, [FORM_FIELDS.BIRTH_DATE]: chosenDate }));

  const handleContinueClick = async () => {
    const { isFormValid, formErrors } = validateFormFields(formFields, registrationInfoValidators, currentDate);

    if (isFormValid) {
      const convertedDate = convertArabicToDigit((formFields.BIRTH_DATE as DateObject).format("YYYY-MM-DD"));

      const prevValues = storageValue.form;
      await setDataToStorage({
        form: {
          ...prevValues,
          [FORM_FIELDS.BIRTH_DATE]: convertedDate,
        },
      });

      const updateResult = await updateDateOfBirth(convertedDate);

      handleUpdateDateOfBirthResult(updateResult);
    } else {
      setFormErrors(formErrors as FormErrors);
    }
  };

  const handleUpdateDateOfBirthResult = useCallback(
    async (result: IUpdateDOBResult) => {
      const { isSuccess, ...dialogData } = result;

      if (isSuccess) {
        await checkSRS();

        history.push(siteMap.EmploymentDetailsPage.path);
      } else {
        if (!dialogData.dialog) {
          return setFormErrors({
            [FORM_FIELDS.BIRTH_DATE]: ["DATE_OF_BIRTH_IS_INCORRECT"],
          } as FormErrors);
        }
        setDialogInfo({ ...dialogData, lastAction: handleContinueClick });
        openDialog();
      }
    },
    [handleContinueClick, openDialog],
  );

  const handleLogInClick = useCallback(async () => {
    await logoutUser();
  }, []);

  return (
    <>
      <Box>
        {!isMobile && (
          <StartPageHeader
            buttonText={t("buttons.logIn") as string}
            leftSideHeaderText={t("pageHeader.createAccount.create")}
            rightSideHeaderText={t("pageHeader.createAccount.account")}
            linkPathname={siteMap.LogInPage.path}
            onLoginClick={handleLogInClick}
          />
        )}
        <Grid container flexDirection="column" rowSpacing={{ md: 3, lg: 3 }}>
          <Grid item container columnSpacing={5}>
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              mb={{
                xs: errors[FORM_FIELDS.BIRTH_DATE].length ? "16px" : "0px",
                sm: errors[FORM_FIELDS.BIRTH_DATE].length ? "16px" : "0px",
              }}
            >
              <CustomDatePicker
                lang={i18n.language === languages.AR ? languages.AR : languages.EN}
                dateValue={formFields[FORM_FIELDS.BIRTH_DATE] as DateObject}
                onChange={handleDateChange}
                dateFormat={isHijri ? "YYYY/M/D" : "D/MM/YYYY"}
                isHijri={isHijri}
                placeholder={t("placeholders.birthDate")}
                calendarTitle={t("labels.setBirthday")}
                inputProps={{
                  label: t("labels.birthDate"),
                  labelId: "date-picker-input",
                  errorText: errors[FORM_FIELDS.BIRTH_DATE].length
                    ? t(`errors.${errors[FORM_FIELDS.BIRTH_DATE][0]}`)
                    : "",
                  showAdditionalMessage: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            <StyledContinueButton onClick={handleContinueClick} disabled={isNextDisabled} variant="contained">
              {t("buttons.continue") as string}
            </StyledContinueButton>
            {isMobile ? (
              <TextWithLink
                text={t("pageContent.alreadyHaveAccount")}
                linkPath={siteMap.LogInPage.path}
                linkText={t("buttons.logInSingle")}
              />
            ) : null}
          </Grid>
        </Grid>
      </Box>
      {isDialogOpen && (
        <UpdateDOBDialog
          {...dialogInfo}
          closeDialog={closeDialog}
          isDialogOpen={isDialogOpen}
          openDialog={openDialog}
        />
      )}
    </>
  );
};
