import { Typography } from "@ui-kit";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as ErrorIcon } from "@assets/icons/popup_error_icon.svg";
import { BasicDialog } from "@components/Dialogs/BasicDialog";

interface IConfirmationDialog {
  isDialogOpen: boolean;
  closeDialog: () => void;
  onCancelClick: () => void;
  onAgreeClick: () => void;
}

export const ConfirmationDialog: FC<IConfirmationDialog> = ({
  isDialogOpen,
  closeDialog,
  onCancelClick,
  onAgreeClick,
}) => {
  const { t } = useTranslation();

  return (
    <BasicDialog
      open={isDialogOpen}
      onClose={closeDialog}
      onCancel={onCancelClick}
      onAgree={onAgreeClick}
      title={t("dialogTitle.rejectContract")}
      description={
        <Typography variant="p3" textAlign="center" component="p">
          {t("dialogContent.confirmRejection")}
        </Typography>
      }
      icon={<ErrorIcon />}
      cancelButtonText={t("buttons.no")}
      agreeButtonText={t("buttons.yes")}
    />
  );
};
