import { AxiosResponse } from "axios";

import { DEFAULT_FORM_DATA } from "@pages/ForgotPassword/forgotPassword.constants";
import { network } from "@utils/network/network";

export interface IVerificationResponse {
  result: boolean;
}

type useRequestType = [
  verifyUserRequest: (form: typeof DEFAULT_FORM_DATA) => Promise<AxiosResponse<IVerificationResponse>>,
];

export const useVerifyUser = (): useRequestType => {
  const verifyUserRequest = (
    verificationData: typeof DEFAULT_FORM_DATA,
  ): Promise<AxiosResponse<IVerificationResponse>> => {
    return network.request<IVerificationResponse>("keycloak", "password/reset/check", {
      method: "GET",
      params: {
        phone: `+966${verificationData.MOBILE_PHONE}`,
        id: verificationData.NATIONAL_ID,
      },
    });
  };

  return [verifyUserRequest];
};
