import { COLORS, Typography } from "@ui-kit";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as SandClockRed } from "@assets/uiImages/sand_clock_red.svg";
import { MobileDialog } from "@components/Dialogs/BasicDialog";
import { useDialog } from "@utils/hooks/useDialog";

import { OrderStatusContent } from "../components/OrderStatusContent";

export const Late: FC = () => {
  const { t } = useTranslation();
  const [isAppDialogOpen, closeMobileDialog, openMobileDialog] = useDialog();

  const handleBackToStoreClick = () => {
    openMobileDialog();
  };

  return (
    <>
      <OrderStatusContent
        icon={<SandClockRed />}
        title={t("checkout.title.missedPayment")}
        content={
          <Typography variant="p1" color={COLORS.GREY_64}>
            {t("checkout.content.missedPayment")}
          </Typography>
        }
        continueButtonText={t("buttons.goToTheApp")}
        onContinue={handleBackToStoreClick}
      />
      <MobileDialog isOpen={isAppDialogOpen} onClose={closeMobileDialog} />
    </>
  );
};
