import { useTranslation } from "react-i18next";

import { DEFAULT_USER_REGISTRATION_INFO } from "@constants/sessionStorageDefaults";
import { REGISTRATION_KEY } from "@constants/sessionStorageKeys";
import { languages as LANGUAGES } from "@store/languageService";

import { useSessionStorageState } from "../useSessionStorageState";

interface IUseCustomerLanguage {
  getCustomerLanguage: () => LANGUAGES;
}

export const useCustomerLanguage = (): IUseCustomerLanguage => {
  const [userLoginInfo] = useSessionStorageState(REGISTRATION_KEY, DEFAULT_USER_REGISTRATION_INFO);
  const { i18n } = useTranslation();

  const getCustomerLanguage = (): LANGUAGES => {
    const isIdUsed = userLoginInfo.form.NATIONAL_ID.startsWith("1");

    if (isIdUsed) {
      return LANGUAGES.AR;
    }

    const selectedLanguage = i18n.language as LANGUAGES;

    return selectedLanguage;
  };

  return { getCustomerLanguage };
};
