import { IDialogContents } from "@appTypes/dialogs";
import { ReactComponent as ErrorIcon } from "@assets/icons/popup_error_icon.svg";

export enum MODAL_TYPES {
  CONFIRM_CANCEL_PROCESS = "CONFIRM_CANCEL_PROCESS",
  CAN_NOT_PROVIDE_OFFER = "CAN_NOT_PROVIDE_OFFER",
}

export const REDIRECT_PATH = "/registration/employment-details/";

export const ConfirmCommodityModalContent: Record<keyof typeof MODAL_TYPES, IDialogContents> = {
  CONFIRM_CANCEL_PROCESS: {
    title: "dialogTitle.confirmCancelQuestion",
    icon: <ErrorIcon />,
    description: "dialogContent.cancelOffer",
    agreeText: "buttons.yes",
    cancelText: "buttons.no",
  },
  CAN_NOT_PROVIDE_OFFER: {
    title: "dialogTitle.couldNotProvideOffer",
    icon: <ErrorIcon />,
    description: "dialogContent.exitOrContact",
    agreeText: "buttons.exit",
  },
};
