import { Typography } from "@ui-kit";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { IDialogContents } from "@appTypes/dialogs";
import { IOTPInfoResponse } from "@appTypes/responses";
import { BasicDialog } from "@components/Dialogs/BasicDialog";
import { SetOTPComponent } from "@components/SetOTP";
import * as httpCodes from "@constants/httpStatuses";
import { dialogContents } from "@constants/popups";
import { siteMap } from "@constants/siteMap";
import { DEFAULT_RESET_PASSWORD_DATA } from "@pages/ForgotPassword/forgotPassword.constants";
import { useGetInfoResetPasswordOTP } from "@pages/ForgotPasswordOTP/hooks/useGetInfoResetPasswordOTP";
import { useVerifyResetPasswordOTP } from "@pages/ForgotPasswordOTP/hooks/useVerifyResetPasswordOTP";
import { useDialog } from "@utils/hooks/useDialog";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";

import { useResendResetPasswordOTP } from "./hooks/useResendResetPasswordOTP";

const ACCOUNT_IS_LOCKED = "ACCOUNT_IS_LOCKED";
const SOMETHING_WENT_WRONG = "SOMETHING_WENT_WRONG";
const RESET_SUCCEEDED = "RESET_SUCCEEDED";

export const ForgotPasswordOTP: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [resetPasswordData] = useSessionStorageState("resetPassword", { ...DEFAULT_RESET_PASSWORD_DATA });
  const [dialogType, setDialogType] = useState("");
  const [isDialogOpen, closeDialog, openDialog] = useDialog();
  const [currentDialogContent, setCurrentDialogContent] = useState<IDialogContents | undefined>();
  const [codeStatusData, setCodeStatusData] = useState<IOTPInfoResponse | undefined>();

  const [getResetPasswordOTPCodeStatus] = useGetInfoResetPasswordOTP();
  const [verifyResetPasswordOTPCode] = useVerifyResetPasswordOTP();
  const [resendResetPasswordOTP] = useResendResetPasswordOTP();

  useEffect(() => {
    getResetPasswordOTPCodeStatus(resetPasswordData.form["NATIONAL_ID"], resetPasswordData.form["MOBILE_PHONE"])
      .then((response) => response.data)
      .then((data) => {
        setCodeStatusData(data);
      })
      .catch(() => openUnexpectedErrorDialog());
  }, []);

  const handleContinue = useCallback(async (otpInputValue, setInputError, setOtpMessage) => {
    try {
      const { data, status } = await verifyResetPasswordOTPCode(
        otpInputValue,
        resetPasswordData.form["NATIONAL_ID"],
        resetPasswordData.form["MOBILE_PHONE"],
      );
      switch (status) {
      case httpCodes.OK:
        if (data.result === "true") {
          openSuccessDialog();
        } else {
          setInputError(true);
          setOtpMessage(t("errors.WRONG_OTP"));
          await sendRequestForOtpCodeStatus();
        }
        break;
      default:
        openUnexpectedErrorDialog();
      }
    } catch (error) {
      openUnexpectedErrorDialog();
      console.log(error);
      return;
    }
  }, []);

  const handleAgreeClick = () => {
    if (dialogType === ACCOUNT_IS_LOCKED || dialogType === RESET_SUCCEEDED) {
      //logout
      history.push(siteMap.LogInPage.path);
    }
    closeDialog();
  };

  const openUnexpectedErrorDialog = () => {
    setDialogType(SOMETHING_WENT_WRONG);
    setCurrentDialogContent(dialogContents.somethingWentWrong);
    openDialog();
  };

  const openSuccessDialog = () => {
    setDialogType(RESET_SUCCEEDED);
    setCurrentDialogContent(dialogContents.successResetPassword);
    openDialog();
  };

  const sendRequestForOtpCodeStatus = useCallback(async () => {
    try {
      const { data, status } = await getResetPasswordOTPCodeStatus(
        resetPasswordData.form["MOBILE_PHONE"],
        resetPasswordData.form["NATIONAL_ID"],
      );
      switch (status) {
      case httpCodes.OK:
        setCodeStatusData(data);
        break;
      default:
        openUnexpectedErrorDialog();
        break;
      }
    } catch (error) {
      openUnexpectedErrorDialog();
      console.log(error);
    }
  }, []);

  return (
    <>
      <SetOTPComponent
        title="pageHeader.verifyNewPassword"
        subtitle={t("pageContent.newPasswordCodeSentToNumber")}
        pinLength={4}
        onGoBack={history.goBack}
        handleResendOTP={() =>
          resendResetPasswordOTP(resetPasswordData.form["NATIONAL_ID"], resetPasswordData.form["MOBILE_PHONE"])
        }
        handleContinue={handleContinue}
        codeStatusData={codeStatusData}
        dialogMethods={{ openUnexpectedErrorDialog, setCurrentDialogContent, openDialog, setDialogType }}
        isOnForgotPassword={true}
      />
      {currentDialogContent && (
        <BasicDialog
          open={isDialogOpen}
          // onClose={closeDialog}
          onAgree={handleAgreeClick}
          title={t(currentDialogContent.title)}
          isSuccess={currentDialogContent.isSuccess ?? false}
          description={
            <Typography variant="p3" textAlign="center" component="p">
              {typeof currentDialogContent.description === "string"
                ? t(currentDialogContent.description)
                : currentDialogContent.description}
            </Typography>
          }
          icon={currentDialogContent.icon}
          agreeButtonText={currentDialogContent.agreeText ? t(currentDialogContent.agreeText) : undefined}
        />
      )}
    </>
  );
};
