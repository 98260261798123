import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { noop } from "rxjs";

import { IDialogComponentProps } from "@appTypes/dialogs";
import { BasicDialog } from "@components/Dialogs/BasicDialog";
import { MERCHANT_DATA_KEY } from "@constants/sessionStorageKeys";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { logoutWithRedirect } from "@utils/services/Authentication/logoutWithRedirect";
import { getTimeBefore } from "@utils/services/Timer/Timer.service";
import { ITimeObject } from "@utils/services/Timer/timer.types";

import { kycDialogs, KYC_GENERATION_DIALOGS } from "../SetKYC.types";
import { DialogContentsSwitcher } from "./DialogContentSwitcher";

type IUserDialogs = IDialogComponentProps<kycDialogs, { blockedUntil?: string }>;

export const KYCDialogs: FC<IUserDialogs> = memo(
  ({ dialog, dialogType, dialogOptions, isDialogOpen, closeDialog, lastAction = noop, parameters = [] }) => {
    const { t } = useTranslation();

    const [blockingTimer, setBlockingTimer] = useState<ITimeObject<number> | null>(null);
    const [merchantData] = useSessionStorageState(MERCHANT_DATA_KEY, {
      checkoutToken: "",
      merchantUrl: "",
      merchantSuccessUrl: "",
      merchantFailureUrl: "",
      merchantCancelUrl: "",
    });

    const extendedDialogOptions = useMemo(() => {
      return {
        blockingTimer,
        closeDialog,
      };
    }, [blockingTimer]);

    useEffect(() => {
      if (dialogType === KYC_GENERATION_DIALOGS.USER_TEMP_BLOCKED && dialogOptions?.blockedUntil) {
        setBlockingTimer(getTimeBefore((dialogOptions?.blockedUntil as string) || ""));
      }
    }, [dialogOptions, dialogType]);

    const closeDialogHandler = useMemo(() => {
      const isBlockingDialog = dialogType === KYC_GENERATION_DIALOGS.USER_TEMP_BLOCKED;

      return isBlockingDialog ? noop : closeDialog;
    }, [dialogType]);

    const handleAgreeDialogClick = useCallback(() => {
      switch (dialogType) {
      case KYC_GENERATION_DIALOGS.USER_TEMP_BLOCKED:
        logoutWithRedirect(merchantData.merchantUrl);
        break;

      default:
        closeDialog();
        lastAction(...parameters);
        break;
      }
    }, [dialogType, lastAction]);

    return (
      <BasicDialog
        open={isDialogOpen}
        onClose={closeDialogHandler}
        onAgree={handleAgreeDialogClick}
        title={t(dialog?.title as string)}
        description={
          <DialogContentsSwitcher
            dialogType={dialogType}
            description={dialog?.description}
            dialogOptions={extendedDialogOptions}
          />
        }
        icon={dialog?.icon}
        cancelButtonText={dialog?.cancelText ? t(dialog.cancelText as string) : ""}
        agreeButtonText={dialog?.agreeText ? t(dialog.agreeText as string) : ""}
        isSuccess={dialog?.isSuccess}
      />
    );
  },
);

KYCDialogs.displayName = "KYCDialogs";
