import { IAuthorization } from "@store/authorization/authorization";
import { IAxiosResponseWithError, network } from "@utils/network/network";

interface IVerifyOTPResponse {
  result: string;
  attempts: number;
  token?: IAuthorization;
}

type useVerifyOTPReturnType = [
  verifyResetPasswordOTPCode: (
    otpCode: string,
    id: string,
    phoneNumber: string,
  ) => Promise<IAxiosResponseWithError<IVerifyOTPResponse>>,
];

export const useVerifyResetPasswordOTP = (): useVerifyOTPReturnType => {
  const verifyResetPasswordOTPCode = (
    otpCode: string,
    id: string,
    phoneNumber: string,
  ): Promise<IAxiosResponseWithError<IVerifyOTPResponse>> => {
    const otpData = {
      otpCode,
      id,
      phoneNumber: `+966${phoneNumber}`,
    };

    return network.request<IVerifyOTPResponse>("keycloak", "password/reset/otp/verify", {
      method: "POST",
      data: otpData,
    });
  };

  return [verifyResetPasswordOTPCode];
};
