import { AxiosResponse } from "axios";

import { network } from "@utils/network/network";

export interface IUserDataForm {
  NATIONAL_ID: string;
  MOBILE_PHONE: string;
}

type useRequestType = [
  setUpNewPasswordRequest: (form: IUserDataForm, newPassword: string) => Promise<AxiosResponse<void>>,
];

export const useSetUpNewPasswordRequest = (): useRequestType => {
  const setUpNewPasswordRequest = (
    userData: IUserDataForm,
    passwordInputValue: string,
  ): Promise<AxiosResponse<void>> => {
    const requestData = {
      id: userData.NATIONAL_ID,
      phoneNumber: `+966${userData.MOBILE_PHONE}`,
      password: passwordInputValue,
    };

    return network.request<void>("keycloak", "password/reset/submit", {
      method: "PUT",
      data: requestData,
    });
  };

  return [setUpNewPasswordRequest];
};
