import { useRef } from "react";

import * as httpStatuses from "@constants/httpStatuses";
import { getEncryptedValue } from "@utils/hooks/useSessionStorageState";

import { IAxiosResponseWithError } from "../network";
import { getContractStatusRequest } from "./getContractStatus";
import { IContractInfo } from "./types";

type poolingHandler = (data: IAxiosResponseWithError<IContractInfo>) => boolean;

/**
 * Hook for polling customer info dat
 * @param onPollingSuccess - handler for success request, should return flag to continue puling or not
 * @param onPollingError - handler for error request, should return flag to continue puling or not
 * @param pollingInterval - pooling interval
 */
export const useGetContractInfoPooling = (): {
  startContractInfoPolling: (
    onPollingSuccess: poolingHandler,
    onPollingError: poolingHandler,
    pollingInterval: number,
  ) => void;
  stopPolling: () => void;
} => {
  const timerRef = useRef<number | null>(0);

  const stopPolling = () => {
    timerRef.current = null;
  };

  const startContractInfoPolling = async (
    onPollingSuccess: poolingHandler,
    onPollingError: poolingHandler,
    pollingInterval = 10000,
  ) => {
    let needContinue = true;

    try {
      const item_value = getEncryptedValue("merchantData")?.toString();
      const orderId = JSON.parse(item_value ?? "{}").orderId ?? "";
      const response = await getContractStatusRequest(orderId);

      if (response.status === httpStatuses.OK) {
        needContinue = onPollingSuccess(response);
      } else if (onPollingError) {
        needContinue = onPollingError(response);
      }
    } catch (e) {
      console.log("Customer info fetching error");
    }

    if (needContinue) {
      if (timerRef.current !== null) {
        const id = setTimeout(
          startContractInfoPolling,
          pollingInterval,
          onPollingSuccess,
          onPollingError,
          pollingInterval,
        );
        timerRef.current = id as unknown as number;
      }
    }
  };

  return { startContractInfoPolling, stopPolling };
};
