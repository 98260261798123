export const encryptMobilePhoneNumber = (phoneNumber: string, withCode = true): string => {
  const shortPhone = withCode ? phoneNumber.slice(4) : phoneNumber;

  if (shortPhone.length === 9) {
    return `+966 ${shortPhone.replace(/(\d{3})(\d{2})(\d{4})/, "*** ** $3")}`;
  } else if (shortPhone.length === 10) {
    return `+966 ${shortPhone.replace(/(\d{4})(\d{2})(\d{4})/, "**** ** $3")}`;
  }
  return phoneNumber;
};

export const convertAmountToString = (amount: number | string = 0, afterComa = 2): string => {
  return amount
    .toLocaleString("en", { useGrouping: false, minimumFractionDigits: afterComa })
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
