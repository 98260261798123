import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { CUSTOMER_STATUSES } from "@appTypes/customerStatuses";
import { IDialogWithHandler } from "@appTypes/dialogs";
import { siteMap } from "@constants/siteMap";
import { CHECK_SRS_DIALOG_TYPES, useCheckSRS } from "@utils/hooks/useCheckSRS/useCheckSRS";
import { ISRSFlowResult, useSRSFlow } from "@utils/hooks/useCheckSRS/useSRSFlow";
import { SAFETY_CHECK_DIALOG_TYPES, ISafetyCheckResponse } from "@utils/hooks/useCheckSRS/useSafetyCheck";

import { useGoToContractStatus } from "./useGoToContractStatus";

export interface IUseHandleCustomerStatusResult
  extends IDialogWithHandler<SAFETY_CHECK_DIALOG_TYPES | CHECK_SRS_DIALOG_TYPES, never> {
  isSuccess: boolean;
  result?: ISafetyCheckResponse;
}

export const useHandleCustomerStatus = (): {
  handleCustomerStatus: (status: string) => Promise<IUseHandleCustomerStatusResult | ISRSFlowResult | undefined>;
} => {
  const history = useHistory();
  const { redirectByContractStatus, isRedirectBySMS } = useGoToContractStatus();

  const { runSrsFlow } = useSRSFlow();
  const { checkSRS } = useCheckSRS();

  const handleCustomerStatus = useCallback(
    async (status: string) => {
      switch (status) {
      // TODO 2243: ADD BLOCKED STATUS within 2243
      case CUSTOMER_STATUSES.ACTIVE: {
        if (isRedirectBySMS) {
          redirectByContractStatus();

          return;
        } else {
          const srsResult = await runSrsFlow();

          if (srsResult?.isSuccess === false) {
            return srsResult;
          }
          return;
        }
      }
      case CUSTOMER_STATUSES.UNVERIFIED_DOB: {
        history.push(siteMap.UpdateDateOfBirth.path);
        return;
      }
      case CUSTOMER_STATUSES.UNVERIFIED_RS: {
        await checkSRS();

        history.push(siteMap.EmploymentDetailsPage.path);
        return;
      }
      case CUSTOMER_STATUSES.UNVERIFIED_SECTOR: {
        history.push(siteMap.EmploymentDetailsPage.path);
        return;
      }
      default: {
        return;
      }
      }
    },
    [redirectByContractStatus, history, isRedirectBySMS],
  );

  return { handleCustomerStatus };
};
