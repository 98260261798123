import { FC } from "react";

import { Button } from "../../Button/Button";

interface IButtonsProps {
  onSelect: () => void;
  onCancel: () => void;
  selectText: string;
  cancelText: string;
}

export const ButtonsContainer: FC<IButtonsProps> = ({ onSelect, onCancel, selectText, cancelText }) => {
  return (
    <div className="buttons-container">
      <Button color="secondary" className={"cancel-button"} onClick={onCancel}>
        {cancelText}
      </Button>
      <Button className={"select-button"} onClick={onSelect}>
        {selectText}
      </Button>
    </div>
  );
};
