import { COLORS, Typography } from "@ui-kit";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as SandClock } from "@assets/uiImages/sand_clock.svg";
import { MERCHANT_DATA_KEY } from "@constants/sessionStorageKeys";
import { OrderStatusContent } from "@modules/WebCheckout/components/OrderStatusContent";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { logoutWithRedirect } from "@utils/services/Authentication/logoutWithRedirect";

export const TokenExpired: FC = () => {
  const { t } = useTranslation();
  const [merchantData] = useSessionStorageState(MERCHANT_DATA_KEY, {
    checkoutToken: "",
    merchantUrl: "",
    merchantSuccessUrl: "",
    merchantFailureUrl: "",
    merchantCancelUrl: "",
  });

  const handleBackToStoreClick = () => {
    logoutWithRedirect(merchantData.merchantCancelUrl);
  };

  return (
    <>
      <OrderStatusContent
        icon={<SandClock />}
        title={t("checkout.title.contractExpired")}
        content={
          <Typography variant="p1" color={COLORS.GREY_64}>
            {t("checkout.content.contractExpired")}
          </Typography>
        }
        continueButtonText={t("buttons.backToStore")}
        onContinue={handleBackToStoreClick}
      />
    </>
  );
};
