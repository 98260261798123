export const webCheckoutPageMap = {
  InitialStep: {
    path: "/web-checkout",
  },
  WaitingConfirmation: {
    path: "/web-checkout/waiting-confirmation",
  },
  DidntMeetQuaraCriteria: {
    path: "/web-checkout/didnt-meet-quara",
  },
  WaitingForSigning: {
    path: "/web-checkout/waiting-for-signing",
  },
  SignContract: {
    path: "/web-checkout/sign-contract",
  },
  SignContractConfirmation: {
    path: "/web-checkout/sign-contract-confirmation",
  },
  DeclinedByUser: {
    path: "/web-checkout/declined-by-user",
  },
  ContractExpired: {
    path: "/web-checkout/contract-expired",
  },
  EPromissory: {
    path: "/web-checkout/epromissory",
  },
  ContractSigned: {
    path: "/web-checkout/contract-signed",
  },
  Completed: {
    path: "/web-checkout/completed",
  },
  CanceledFromMerchant: {
    path: "/web-checkout/canceled-from-merchant",
  },
  LoanActivated: {
    path: "/web-checkout/loan-activated",
  },
  Late: {
    path: "/web-checkout/late",
  },
};
