import { useCallback, useState } from "react";
import { noop } from "rxjs";

import { IDialogWithHandler } from "@appTypes/dialogs";
import { POPUP_TYPES } from "@constants/popups";
import { popupService } from "@store/popups/popups";

type dialogWithParamsType = <T, U>(dialogData: IDialogWithHandler<T, U>) => void;
// eslint-disable-next-line
type lastActionType = (...params: any) => any;

interface IUsePopup<Codes> {
  openPopupWithParams: dialogWithParamsType;
  popupCode: Codes | undefined;
  // eslint-disable-next-line
  handlerParams: any[];
  lastAction: lastActionType;
}

export const usePopup = <PopupCodes extends string>(
  dialogsMap: Record<PopupCodes, POPUP_TYPES>,
): IUsePopup<PopupCodes> => {
  const [popupCode, setPopupCode] = useState<PopupCodes>();
  // eslint-disable-next-line
  const [handlerParams, setHandlerParams] = useState<any[]>([]);
  const [lastAction, setLastAction] = useState<lastActionType>(noop);

  const openPopupWithParams = useCallback<dialogWithParamsType>(
    ({ dialog, dialogType, dialogOptions, lastAction = noop, parameters = [], isBlockingPopup = false }) => {
      popupService
        .setPopupData(dialog)
        .setPopupStatus({ isOpen: true, dialogType: dialogsMap[dialogType], isBlockingPopup })
        .setPopupOptions(dialogOptions);

      setPopupCode(dialogType as typeof popupCode);
      setLastAction(() => lastAction);
      setHandlerParams(parameters);
    },
    [setPopupCode, setLastAction, setHandlerParams],
  );

  return { openPopupWithParams, popupCode, handlerParams, lastAction };
};
