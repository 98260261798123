import { Typography, styled, Box, Grid } from "@mui/material";

export const TitleText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  paddingTop: 24,
  [theme.breakpoints.down("sm")]: {},
}));

export const DescText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: 1.8,
  padding: "0 17%",
  textAlign: "center",
  paddingTop: 18,
  [theme.breakpoints.down("sm")]: {},
}));

export const CenterContainer = styled(Grid)(({ theme }) => ({
  margin: "4% 12%",
  [theme.breakpoints.down("sm")]: {
    margin: "4% 0",
  },
}));

export const MainBox = styled(Box)(({ theme }) => ({
  border: "2px solid #E9EBFC",
  padding: "20px",
  borderRadius: 10,
  [theme.breakpoints.down("sm")]: {},
}));

export const Row = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {},
}));

export const ProductName = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  [theme.breakpoints.down("sm")]: {},
}));
export const Quantity = styled(Typography)(({ theme }) => ({
  textAlign: "start",
  color: "#6B727C",
  [theme.breakpoints.down("sm")]: {},
}));
export const Price = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 18,
  [theme.breakpoints.down("sm")]: {},
}));
