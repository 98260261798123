import { Grid, Stack, useMediaQuery } from "@mui/material";
import { Button, theme, Typography } from "@ui-kit";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { IDialogContents } from "@appTypes/dialogs";
import { ReactComponent as Icon } from "@assets/icons/commodity_purchase.svg";
import { BasicDialog } from "@components/Dialogs/BasicDialog";
import { TextWithEmail } from "@components/TextWithEmail";
import { SUPPORT_EMAIL } from "@constants/common";
import * as httpCodes from "@constants/httpStatuses";
import { DEFAULT_MERCHANT_DATA_KEY } from "@constants/sessionStorageDefaults";
import { MERCHANT_DATA_KEY } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";
import { menuService } from "@store/menu";
import { useDialog } from "@utils/hooks/useDialog";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { logoutWithRedirect } from "@utils/services/Authentication/logoutWithRedirect";

import { ConfirmCommodityModalContent, MODAL_TYPES } from "./ConfirmCommodityPurchase.constants";
import { acceptCommodity } from "./hooks/acceptCommodity";
import { TitleText, DescText } from "./styles";

interface IButtonsConfig {
  onAgree?(): void;
  onCancel?(): void;
}

export const ConfirmCommodityPurchase: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isLoading, setIsLoading] = useState(false);
  const [merchantData] = useSessionStorageState(MERCHANT_DATA_KEY, DEFAULT_MERCHANT_DATA_KEY);

  // Dialog functions
  const [isDialogOpen, closeDialog, openDialog] = useDialog();
  const [modalType, setModalType] = useState<keyof typeof MODAL_TYPES>(MODAL_TYPES.CAN_NOT_PROVIDE_OFFER);
  const [modalContent, setModalContent] = useState<IDialogContents>(ConfirmCommodityModalContent.CAN_NOT_PROVIDE_OFFER);

  useEffect(() => {
    menuService.setMenuData({ leftSide: "", rightSide: "", needIcon: false });
  }, []);

  const onPressAcceptBtn = async () => {
    await callCommodityApi("CONFIRM", () => history.push(siteMap.ConfirmationPage.path));
  };

  const callCommodityApi = async (type: string, callBackIfSuccess?: () => void | undefined) => {
    setIsLoading(true);
    try {
      const { status } = await acceptCommodity(merchantData.checkoutToken, type);

      if (status === httpCodes.OK) {
        callBackIfSuccess ? callBackIfSuccess() : null;
        setIsLoading(false);
      } else {
        setIsLoading(false);
        openUnexpectedErrorDialog();
      }
    } catch (e) {
      setIsLoading(false);
      openUnexpectedErrorDialog();
    }
  };

  const modalButtonsConfig = useMemo(() => {
    const modalHandlers: Record<keyof typeof MODAL_TYPES, IButtonsConfig> = {
      CONFIRM_CANCEL_PROCESS: {
        onCancel: () => closeDialog(),
        onAgree: async () => {
          await callCommodityApi("DECLINE", () => {
            logoutWithRedirect(merchantData.merchantCancelUrl);
          });
        },
      },
      CAN_NOT_PROVIDE_OFFER: {
        onAgree: async () => {
          await logoutWithRedirect(merchantData.merchantFailureUrl);
        },
      },
    };

    return { ...modalHandlers[modalType] } || {};
  }, [modalType]);

  const handleCancelClick = () => {
    setModalType(MODAL_TYPES.CONFIRM_CANCEL_PROCESS);
    setModalContent(ConfirmCommodityModalContent[MODAL_TYPES.CONFIRM_CANCEL_PROCESS]);
    openDialog();
  };

  const openUnexpectedErrorDialog = () => {
    setModalType(MODAL_TYPES.CAN_NOT_PROVIDE_OFFER);
    setModalContent({
      ...ConfirmCommodityModalContent.CAN_NOT_PROVIDE_OFFER,
      description: (
        <TextWithEmail
          textKey={ConfirmCommodityModalContent.CAN_NOT_PROVIDE_OFFER.description as string}
          email={SUPPORT_EMAIL}
        />
      ),
    });
    openDialog();
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent={"center"}>
            <Icon />
          </Stack>
          <Stack direction="row" justifyContent={"center"}>
            <TitleText variant="h4">{t("pageHeader.confirmCommodityOurchase")}</TitleText>
          </Stack>
          <Stack direction="row" justifyContent={"center"}>
            <DescText variant="h6">{t("pageContent.confirmCommodityOurchase")}</DescText>
          </Stack>
        </Grid>
        <Grid container justifyContent="space-between" paddingTop={8}>
          <Grid item>
            <Button
              tertiary
              sx={{ minWidth: { md: "240px", xs: "150px" } }}
              color={!isMobile ? "secondary" : "inherit"}
              onClick={handleCancelClick}
            >
              {t("buttons.reject") as string}
            </Button>
          </Grid>
          <Grid item>
            <Button
              sx={{ minWidth: { md: "240px", xs: "150px" } }}
              onClick={() => onPressAcceptBtn()}
              disabled={isLoading}
            >
              {t("buttons.accept") as string}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <BasicDialog
        open={isDialogOpen}
        icon={modalContent.icon}
        title={t(modalContent.title)}
        onAgree={modalButtonsConfig.onAgree}
        onCancel={modalButtonsConfig.onCancel}
        closeBtnTitle={t(modalContent.closeBtnTitle ?? "")}
        description={
          <Typography variant="p3" textAlign="center" component="p">
            {typeof modalContent.description === "string" ? t(modalContent.description) : modalContent.description}
          </Typography>
        }
        agreeButtonText={t(modalContent.agreeText || "")}
        cancelButtonText={t(modalContent.cancelText || "")}
      />
    </>
  );
};
