import { IAxiosResponseWithError, network } from "@utils/network/network";

interface IPhoneInfoResponse {
  number: string;
}

type useGetPhoneStatusType = { getPhoneInfo: () => Promise<IAxiosResponseWithError<IPhoneInfoResponse>> };

export const useGetPhoneInfo = (): useGetPhoneStatusType => {
  const getPhoneInfo = (): Promise<IAxiosResponseWithError<IPhoneInfoResponse>> => {
    return network.request<IPhoneInfoResponse>("connect", "customer/phone-number-masked", {
      method: "GET",
    });
  };

  return { getPhoneInfo };
};
