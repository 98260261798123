import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Grid,
  Link,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  BaseInput,
  Button,
  CurrencyInput,
  Dropdown,
  QuantityInput,
  Typography,
  ArrowDropDownIcon,
  COLORS,
} from "@ui-kit";
import React, { useCallback, useEffect, useReducer, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { CUSTOMER_STATUSES } from "@appTypes/customerStatuses";
import { IDialogWithHandler } from "@appTypes/dialogs";
import { ReactComponent as InfoIcon } from "@assets/icons/info_icon.svg";
import { ReactComponent as ErrorIcon } from "@assets/icons/popup_error_icon.svg";
import StyledRadioButtonGroup, { TTextValue } from "@components/RadioButtonsGroup/StyledRadioButtonGroup";
import { EMPLOYMENT_SECTORS, EMPLOYMENT_SECTORS_ACTIONS } from "@constants/employmentsSectors";
import * as httpCodes from "@constants/httpStatuses";
import { BOX_BACKGROUND_COLOR_DEFAULT_CONTRACT, DEFAULT_MERCHANT_DATA_KEY } from "@constants/sessionStorageDefaults";
import { BOX_BACKGROUND_COLOR, MERCHANT_DATA_KEY, USER_INFO_KEY } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";
import {
  ACCOMMODATION_TYPES,
  RELATIONSHIPS,
} from "@modules/EmploymentSector/EmploymentDetails/EmploymentDetails.constants";
import { useEmploymentDetails } from "@modules/EmploymentSector/EmploymentDetails/hooks/useEmploymentDetails";
import { menuService } from "@store/menu";
import { useSRSFlow } from "@utils/hooks/useCheckSRS/useSRSFlow";
import { useDialog } from "@utils/hooks/useDialog";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";

import { EmploymentDetailsFieldCode, IEmploymentDetailsRequestParams } from "../EmploymentSector.types";
import { EmploymentDialogs } from "../components/EmploymentDialogs";
import {
  employmentDialogTypes,
  EMPLOYMENT_DETAILS_DIALOGS,
  TEmploymentDetails,
  TEmploymentRelativeDetails,
} from "./EmploymentDetails.types";
import { getRelatives } from "./hooks/getRelative";

const StyledDropdown = styled(Dropdown)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    width: "376px",
  },
}));

const StyledQuantityInput = styled(QuantityInput)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    width: "247px",
  },
}));

const StyledContinueButton = styled(Button)(({ theme }) => ({
  fontWeight: theme.direction === "ltr" ? "bold" : 600,
  width: "100%",
  maxWidth: "376px",
  [theme.breakpoints.up("sm")]: {
    width: "270px",
  },
}));

const StyledAccordion = styled(Accordion)(() => ({
  marginBottom: "15px",
  borderWidth: "0px",
  boxShadow: "unset",
  borderRadius: "10px",
  "&::before": {
    opacity: 0,
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  padding: "10px",
  paddingLeft: "30px",
}));

const expensionIcon = <ArrowDropDownIcon />;

const employmentDetails: TEmploymentDetails = {
  employmentSector: EMPLOYMENT_SECTORS.NONE,
  monthlyIncome: 0,
  monthlyRent: 0,
  householdWage: 0,
  utilityExpense: 0,
  insurancePremium: 0,
  transportExpense: 0,
  healthcareExpense: 0,
  communicationExpense: 0,
  foodAndLivingExpense: 0,
  expatDependentGovtFees: 0,
  educationSuppliesExpense: 0,
  monthlyRemittance: 0,
  otherMonthlyExpense: 0,
  otherObligation: 0,
  allDependants: 0,
  dependantsPrivateSchool: 0,
  dependantsPublicSchool: 0,
  householdHelp: 0,
  accomodationType: "",
  isBreadWinner: "NO",
  isHomeOwner: "NO",
  isPolitician: "NO",
  isRDEF: "NO",
  isResponsibleForPayment: "YES",
  responsiblePersonName: "",
  isQuaraFinanceEmployee: "NO",
  isQuaraFinanceRelative: "NO",
  quaraFinanceRelativeDetails: [],
};

// eslint-disable-next-line
const employmentReducer = (state: TEmploymentDetails, action: any): TEmploymentDetails => {
  switch (action.type) {
  case EMPLOYMENT_SECTORS_ACTIONS.SECTOR:
    return { ...state, employmentSector: action.value };
  case EMPLOYMENT_SECTORS_ACTIONS.MONTHLY_INCOME:
    return { ...state, monthlyIncome: action.value };
  case EMPLOYMENT_SECTORS_ACTIONS.MONTHLY_RENT:
    return { ...state, monthlyRent: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.HOUSEHOLD_WAGE:
    return { ...state, householdWage: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.UTILITY_EXPENSE:
    return { ...state, utilityExpense: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.INSURANCE_PREMIUM:
    return { ...state, insurancePremium: parseInt(action.value) };

  case EMPLOYMENT_SECTORS_ACTIONS.TRANSPORT_EXPENSE:
    return { ...state, transportExpense: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.HEALTHCARE_EXPENSE:
    return { ...state, healthcareExpense: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.COMMUNICATION_EXPENSE:
    return { ...state, communicationExpense: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.FOOD_LIVING_EXPENSE:
    return { ...state, foodAndLivingExpense: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.EXPAT_DEPENDENT_GOVT_FEES:
    return { ...state, expatDependentGovtFees: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.EDUCATION_SUPPLIES_EXPENSE:
    return { ...state, educationSuppliesExpense: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.MONTHLY_REMITTANCE:
    return { ...state, monthlyRemittance: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.OTHER_MONTHLY_EXPENSE:
    return { ...state, otherMonthlyExpense: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.OTHER_OBLIGATION:
    return { ...state, otherObligation: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.NUMBER_OF_DEPENDANTS_PUBLIC_SCHOOL:
    return { ...state, dependantsPublicSchool: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.NUMBER_OF_DEPENDANTS_PRIVATE_SCHOOL:
    return { ...state, dependantsPrivateSchool: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.NUMBER_OF_HOUSEHOLD_HELP:
    return { ...state, householdHelp: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.NUMBER_OF_ALL_DEPENDANTS:
    return { ...state, allDependants: parseInt(action.value) };

  case EMPLOYMENT_SECTORS_ACTIONS.RESPONSIBLE_FOR_PAYMENT:
    if (action.value == "YES") return { ...state, isResponsibleForPayment: action.value, responsiblePersonName: "" };
    else
      return { ...state, isResponsibleForPayment: action.value, responsiblePersonName: state.responsiblePersonName };
  case EMPLOYMENT_SECTORS_ACTIONS.PERSON_RESPONSIBLE:
    return { ...state, responsiblePersonName: action.value };
  case EMPLOYMENT_SECTORS_ACTIONS.SOLE_BREADWINNER:
    return { ...state, isBreadWinner: action.value };
  case EMPLOYMENT_SECTORS_ACTIONS.RDEF:
    return { ...state, isRDEF: action.value };
  case EMPLOYMENT_SECTORS_ACTIONS.ACCOMODATION_TYPE:
    return { ...state, accomodationType: action.value };
  case EMPLOYMENT_SECTORS_ACTIONS.HOME_OWNER:
    return { ...state, isHomeOwner: action.value };
  case EMPLOYMENT_SECTORS_ACTIONS.POLITICAL_PERSON:
    return { ...state, isPolitician: action.value };
  case EMPLOYMENT_SECTORS_ACTIONS.QUARA_FINANCE_EMPLOYEE:
    return { ...state, isQuaraFinanceEmployee: action.value };
  case EMPLOYMENT_SECTORS_ACTIONS.QUARA_FINANCE_RELATIVE:
    return { ...state, isQuaraFinanceRelative: action.value };
  case EMPLOYMENT_SECTORS_ACTIONS.QUARA_FINANCE_RELATIVE_DETAILS:
    return { ...state, quaraFinanceRelativeDetails: action.value };
  default:
    return state;
  }
};

export const EmploymentDetails: React.FC = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const history = useHistory();

  const [isDialogOpen, closeDialog, openDialog] = useDialog();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [userLoginInfo] = useSessionStorageState(USER_INFO_KEY, { status: "" });
  const [merchantToken] = useSessionStorageState<{ checkoutToken: string }>(MERCHANT_DATA_KEY);
  const [merchantStorageValue] = useSessionStorageState(MERCHANT_DATA_KEY, DEFAULT_MERCHANT_DATA_KEY);
  const [, setBoxBackgroundColor] = useSessionStorageState(BOX_BACKGROUND_COLOR, BOX_BACKGROUND_COLOR_DEFAULT_CONTRACT);
  const [dialogInfo, setDialogInfo] = useState<IDialogWithHandler<employmentDialogTypes, void>>();
  const [monthlyIncomeError, setMonthlyIncomeError] = useState("");
  const { setEmploymentDetails } = useEmploymentDetails();
  const { runSrsFlow } = useSRSFlow();

  const yesNoValues = useMemo<TTextValue[]>(() => {
    return [
      { text: t("buttons.yes"), value: "YES" },
      { text: t("buttons.no"), value: "NO" },
    ];
  }, [i18n.language]);

  const [employmentData, dispatch] = useReducer(employmentReducer, employmentDetails);
  const [consent, setConsent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handleContinueClick = async () => {
    setIsLoading(true);
    const setEmploymentRequestData = async (token: string, employmentRequestData: IEmploymentDetailsRequestParams) => {
      const { isSuccess, ...dialogData } = await setEmploymentDetails(
        merchantStorageValue.orderId,
        merchantStorageValue.checkoutToken,
        employmentRequestData,
      );
      if (isSuccess) {
        if (userLoginInfo.status !== CUSTOMER_STATUSES.ACTIVE) {
          const srsResult = await runSrsFlow(siteMap.NewCreditLimitCalculationPage.path);

          if (srsResult) {
            openDialog();
            setIsLoading(false);
            setDialogInfo(srsResult);
          } else {
            history.push(siteMap.NewCreditLimitCalculationPage.path, {
              employmentSector: employmentData.employmentSector,
            });
          }
        } else {
          history.push(siteMap.NewCreditLimitCalculationPage.path, {
            employmentSector: employmentData.employmentSector,
          });
        }
      } else {
        setIsLoading(false);
        setDialogInfo({ ...dialogData, lastAction: setEmploymentRequestData, parameters: [token, employmentData] });
        openDialog();
      }
    };
    setEmploymentRequestData(merchantToken.checkoutToken, {
      employmentSector: employmentData.employmentSector,
      accommodation: employmentData.accomodationType == "labels.rental" ? "Rental" : "Own house",
      dependents: employmentData.allDependants,
      monthInstalments: 0,
      monthSalary: employmentData.monthlyIncome,
      isPolitician: employmentData.isPolitician == "YES",
      isQfEmployee: employmentData.isQuaraFinanceEmployee == "YES",
      hasQfRelative: employmentData.isQuaraFinanceRelative == "YES",
      relatives: employmentData.isQuaraFinanceRelative == "YES" ? employmentData.quaraFinanceRelativeDetails : [],
      otherObligation: employmentData.otherObligation,
      financialDetails: {
        homeOwner: employmentData.isHomeOwner == "YES",
        isBreadwinner: employmentData.isBreadWinner == "YES",
        isResponsible: employmentData.isResponsibleForPayment == "YES",
        responsiblePerson: employmentData.responsiblePersonName,
        rdef: employmentData.isRDEF == "YES",
        expenses: [
          { code: EmploymentDetailsFieldCode.MONTHLY_RENT, value: employmentData.monthlyRent },
          { code: EmploymentDetailsFieldCode.HOUSEHOLD_WAGE, value: employmentData.householdWage },
          { code: EmploymentDetailsFieldCode.UTILITY_EXPENSE, value: employmentData.utilityExpense },
          { code: EmploymentDetailsFieldCode.INSURANCE_PREMIUM, value: employmentData.insurancePremium },
          { code: EmploymentDetailsFieldCode.TRANSPORT_EXPENSE, value: employmentData.transportExpense },
          { code: EmploymentDetailsFieldCode.HELTHCARE_EXPENSE, value: employmentData.healthcareExpense },
          { code: EmploymentDetailsFieldCode.COMMUNICATION_EXPENSE, value: employmentData.communicationExpense },
          { code: EmploymentDetailsFieldCode.FOOD_AND_LIVING_EXPENSE, value: employmentData.foodAndLivingExpense },
          { code: EmploymentDetailsFieldCode.EXPAT_DEPENDENT_EXPENSE, value: employmentData.expatDependentGovtFees },
          { code: EmploymentDetailsFieldCode.EDUCATION_EXPENSE, value: employmentData.educationSuppliesExpense },
          { code: EmploymentDetailsFieldCode.MONTHLY_REMITTANCE, value: employmentData.monthlyRemittance },
          { code: EmploymentDetailsFieldCode.OTHER_EXPENSE, value: employmentData.otherMonthlyExpense },
        ],
        dependents: [
          { code: EmploymentDetailsFieldCode.PUBLIC_SCHOOL, value: employmentData.dependantsPublicSchool },
          { code: EmploymentDetailsFieldCode.PRIVATE_SCHOOL, value: employmentData.dependantsPrivateSchool },
          { code: EmploymentDetailsFieldCode.HOUSEHOLD_HELP, value: employmentData.householdHelp },
        ],
      },
    });
  };
  const handleSetConsent = () => {
    setConsent((value) => !value);
  };

  const handleOpenDialog = (dialogData: IDialogWithHandler<employmentDialogTypes, void>): void => {
    setDialogInfo(dialogData);
    openDialog();
  };
  const handleOpenConsentDialog = useCallback(() => {
    setDialogInfo({
      dialogType: EMPLOYMENT_DETAILS_DIALOGS.CONSENT,
      dialog: { title: "", description: "", agreeText: t("buttons.accept") },
    });
    openDialog();
  }, []);

  const handleDeleteRelativeDialog = useCallback((onAgreeClick: () => void) => {
    setDialogInfo({
      dialogType: EMPLOYMENT_DETAILS_DIALOGS.DELETE_RELATIVE,
      dialog: {
        icon: <ErrorIcon />,
        title: t("dialogTitle.removeRelative"),
        description: t("dialogContent.removeRelative"),
        agreeText: t("buttons.yesDelete"),
        cancelText: t("buttons.noLeaveIt"),
      },
      lastAction: onAgreeClick,
    });
    openDialog();
  }, []);

  const translatedOptions = useMemo(() => {
    return Object.values(ACCOMMODATION_TYPES).map((item: { label: string; key: string }) => ({
      label: t(item.label),
      key: item.key,
    }));
  }, [i18n.language]);

  const translatedRelationshipsOptions = useMemo(() => {
    return Object.values(RELATIONSHIPS).map((item: { label: string; key: string }) => ({
      label: t(item.label),
      key: item.key,
    }));
  }, [i18n.language]);

  const translatedSectorOptions = useMemo(() => {
    return [
      { text: t("pageContent.privateSector"), value: "PRIVATE" },
      { text: t("pageContent.governmentSector"), value: "GOVERNMENT" },
    ];
  }, [i18n.language]);

  const handleCloseDialog = useCallback(() => {
    setDialogInfo({});
    closeDialog();
  }, [closeDialog]);

  useEffect(() => {
    getRelativesOnLoad();
    setTimeout(function () {
      setBoxBackgroundColor({ withColor: false });
    }, 50);
    menuService.setMenuData({ leftSide: "pageHeader.employmentDetails", rightSide: "", needIcon: false });
  }, []);

  const parseNum = (val: string): number => {
    return parseInt(String(val).replace(/^0+/, "")) || 0;
  };

  const getRelativesOnLoad = async () => {
    const { status, data } = await getRelatives(merchantStorageValue.orderId);
    if (status === httpCodes.OK) {
      dispatch({
        type: EMPLOYMENT_SECTORS_ACTIONS.QUARA_FINANCE_EMPLOYEE,
        value: data.isQfEmployee ? "YES" : "NO",
      });
      dispatch({
        type: EMPLOYMENT_SECTORS_ACTIONS.QUARA_FINANCE_RELATIVE,
        value: data.relatedParties?.length > 0 ? "YES" : "NO",
      });

      const newVal: TEmploymentRelativeDetails[] = [];

      if (data.relatedParties?.length > 0) {
        data.relatedParties.map((item) => {
          newVal.push({
            relativeId: item.ResidentialID,
            relativeName: item.Name,
            relationshipPosition: item.Position,
            relationship: item.Relationship,
          });
        });
      }
      dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.QUARA_FINANCE_RELATIVE_DETAILS, value: newVal });
    }
  };

  // Update form validation whenever the relevant values changes
  useEffect(() => {
    validateForm();
  }, [employmentData, consent, isLoading]);

  function validateForm() {
    const isAccommodationTypeEmpty = employmentData.accomodationType == "";
    const isMonthlyIncomeValid = employmentData.monthlyIncome >= 4000;
    const isResponsiblePersonEmpty =
      employmentData.isResponsibleForPayment == "NO" && employmentData.responsiblePersonName == "";
    const isEmploymentSectorNotSelected = employmentData.employmentSector !== EMPLOYMENT_SECTORS.NONE;

    const isRelativeInfoValid = employmentData.quaraFinanceRelativeDetails.every((r) => {
      // All values should be filled.
      return r.relativeId !== "" && r.relationship !== "" && r.relationshipPosition !== "" && r.relativeName !== "";
    });

    setIsValid(
      consent &&
        !isAccommodationTypeEmpty &&
        isMonthlyIncomeValid &&
        !isResponsiblePersonEmpty &&
        !isLoading &&
        isEmploymentSectorNotSelected &&
        isRelativeInfoValid,
    );
  }

  return (
    <>
      {!isMobile && (
        <Typography
          variant="h5"
          fontWeight="bold"
          component="h1"
          marginBottom={5}
        >
          {t("pageHeader.employmentDetails")}
        </Typography>
      )}
      <Grid
        container
        spacing={2}
        style={{
          background: "white",
          padding: "20px",
          borderRadius: "16px",
          margin: "auto",
          marginBottom: "20px",
          width: "100%",
        }}
      >
        <Grid item lg={7} xs={12}>
          <StyledRadioButtonGroup
            groupId="sector-button-group"
            groupTitle={t("buttons.selectSector")}
            value={employmentData.employmentSector}
            textValues={translatedSectorOptions}
            onChange={(e) => dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.SECTOR, value: e.target.value })}
          />
        </Grid>
        <Grid
          item
          lg={5}
          xs={12}
          paddingLeft={0}
        >
          <CurrencyInput
            currency={t("currency.SAR")}
            label={t("labels.monthlyIncome")}
            labelId="monthly-income"
            hintText={
              <Grid display="flex" alignItems="center" paddingBottom={2}>
                <InfoIcon />
                <Grid marginX={1}>{t("labels.monthlyIncomeDescription")}</Grid>
              </Grid>
            }
            errorText={monthlyIncomeError}
            placeholder="0.00"
            value={employmentData.monthlyIncome}
            onChange={(e) => {
              const val = parseNum(e.target.value);
              setMonthlyIncomeError(val < 4000 ? t(`errors.Minmum_monthly_income`) : "");
              dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.MONTHLY_INCOME, value: val });
            }}
          />
        </Grid>
      </Grid>

      <StyledAccordion>
        <StyledAccordionSummary expandIcon={expensionIcon}>
          <Typography fontWeight="bold">{t("labels.monthlyExpenses")}</Typography>
        </StyledAccordionSummary>
        <AccordionDetails style={{ padding: "8px 40px 16px" }}>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={t("labels.monthlyRent")}
                labelId="monthly-rent"
                placeholder="0.00"
                value={employmentData.monthlyRent}
                onChange={(e) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.MONTHLY_RENT, value: parseNum(e.target.value) })
                }
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={t("labels.householdWage")}
                labelId="household-wage"
                placeholder="0.00"
                value={employmentData.householdWage}
                onChange={(e) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.HOUSEHOLD_WAGE, value: parseNum(e.target.value) })
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={t("labels.utilityExpense")}
                labelId="utility-expense"
                placeholder="0.00"
                value={employmentData.utilityExpense}
                onChange={(e) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.UTILITY_EXPENSE, value: parseNum(e.target.value) })
                }
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={t("labels.insurancePremium")}
                labelId="insurance-premium"
                placeholder="0.00"
                value={employmentData.insurancePremium}
                onChange={(e) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.INSURANCE_PREMIUM, value: parseNum(e.target.value) })
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={t("labels.transportExpenses")}
                labelId="transport-expenses"
                placeholder="0.00"
                value={employmentData.transportExpense}
                onChange={(e) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.TRANSPORT_EXPENSE, value: parseNum(e.target.value) })
                }
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={t("labels.healthcareExpenses")}
                labelId="healthcare-expenses"
                placeholder="0.00"
                value={employmentData.healthcareExpense}
                onChange={(e) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.HEALTHCARE_EXPENSE, value: parseNum(e.target.value) })
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={t("labels.communicationExpenses")}
                labelId="communication-expenses"
                placeholder="0.00"
                value={employmentData.communicationExpense}
                onChange={(e) =>
                  dispatch({
                    type: EMPLOYMENT_SECTORS_ACTIONS.COMMUNICATION_EXPENSE,
                    value: parseNum(e.target.value),
                  })
                }
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={t("labels.foodAndLivingExpenses")}
                labelId="food-and-living-expenses"
                placeholder="0.00"
                value={employmentData.foodAndLivingExpense}
                onChange={(e) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.FOOD_LIVING_EXPENSE, value: parseNum(e.target.value) })
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={t("labels.expatDependentGovFees")}
                labelId="expat-dependent-govt-fees"
                placeholder="0.00"
                value={employmentData.expatDependentGovtFees}
                onChange={(e) =>
                  dispatch({
                    type: EMPLOYMENT_SECTORS_ACTIONS.EXPAT_DEPENDENT_GOVT_FEES,
                    value: parseNum(e.target.value),
                  })
                }
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={t("labels.educationSuppliesExpense")}
                labelId="education-supplies-expenses"
                placeholder="0.00"
                value={employmentData.educationSuppliesExpense}
                onChange={(e) =>
                  dispatch({
                    type: EMPLOYMENT_SECTORS_ACTIONS.EDUCATION_SUPPLIES_EXPENSE,
                    value: parseNum(e.target.value),
                  })
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} flexWrap="wrap">
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={
                  <Box display="flex" alignItems="center" flexWrap="wrap">
                    <Typography fontWeight={800} fontSize={12} color="#6B727C">
                      {t("labels.monthlyRemittance")}
                    </Typography>
                    <Typography fontSize={10} color="#6B727C" paddingX={0.5}>
                      {t("labels.monthlyRemittanceDescription")}
                    </Typography>
                  </Box>
                }
                labelId="monthly-remittance"
                placeholder="0.00"
                value={employmentData.monthlyRemittance}
                onChange={(e) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.MONTHLY_REMITTANCE, value: parseNum(e.target.value) })
                }
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={
                  <Box display="flex" alignItems="center" flexWrap="wrap">
                    <Typography fontWeight={800} fontSize={12} color="#6B727C">
                      {t("labels.otherMonthlyExpenses")}
                    </Typography>
                    <Typography fontSize={10} color="#6B727C" paddingX={0.5}>
                      {t("labels.otherMonthlyExpensesDescription")}
                    </Typography>
                  </Box>
                }
                labelId="other-monthly-expenses"
                placeholder="0.00"
                value={employmentData.otherMonthlyExpense}
                onChange={(e) =>
                  dispatch({
                    type: EMPLOYMENT_SECTORS_ACTIONS.OTHER_MONTHLY_EXPENSE,
                    value: parseNum(e.target.value),
                  })
                }
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={
                  <Box display="flex" alignItems="center" flexWrap="wrap">
                    <Typography fontWeight={800} fontSize={12} color="#6B727C">
                      {t("labels.MonthlyObligations")}
                    </Typography>
                  </Box>
                }
                labelId="other-obligation"
                placeholder="0.00"
                value={employmentData.otherObligation}
                onChange={(e) =>
                  dispatch({
                    type: EMPLOYMENT_SECTORS_ACTIONS.OTHER_OBLIGATION,
                    value: parseNum(e.target.value),
                  })
                }
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion>
        <StyledAccordionSummary expandIcon={expensionIcon}>
          <Typography fontWeight="bold">{t("labels.numberOfDependants")}</Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <StyledQuantityInput
                onValueChange={(value) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.NUMBER_OF_DEPENDANTS_PUBLIC_SCHOOL, value })
                }
                label={t("labels.publicSchoolDependants")}
                labelId="dependents-number-public-school"
                numbersType={(i18n.language as "en" | "ar") || "en"}
                maxValue={10}
                value={employmentData.dependantsPublicSchool}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <StyledQuantityInput
                onValueChange={(value) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.NUMBER_OF_DEPENDANTS_PRIVATE_SCHOOL, value })
                }
                label={t("labels.privateSchoolDependants")}
                labelId="dependents-number-private-school"
                numbersType={(i18n.language as "en" | "ar") || "en"}
                maxValue={10}
                value={employmentData.dependantsPrivateSchool}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <StyledQuantityInput
                onValueChange={(value) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.NUMBER_OF_HOUSEHOLD_HELP, value })
                }
                label={t("labels.numberOfHouseholdHelp")}
                labelId="dependents-number-household-help"
                numbersType={(i18n.language as "en" | "ar") || "en"}
                maxValue={10}
                value={employmentData.householdHelp}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <StyledQuantityInput
                onValueChange={(value) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.NUMBER_OF_ALL_DEPENDANTS, value })
                }
                label={t("labels.numberOfAllDependants")}
                labelId="dependents-number-all"
                value={employmentData.allDependants}
                numbersType={(i18n.language as "en" | "ar") || "en"}
                maxValue={10}
              />
              <Typography fontSize={12}>{t("labels.numberOfAllDependantsDescription")}</Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </StyledAccordion>

      <Grid
        style={{
          background: "white",
          padding: "40px",
          borderRadius: "16px",
          margin: "auto",
          marginBottom: "20px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <StyledRadioButtonGroup
              groupId="payment-responsible-group-lable"
              groupTitle={t("labels.resonsibleTitle")}
              value={employmentData.isResponsibleForPayment}
              textValues={yesNoValues}
              onChange={(e) =>
                dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.RESPONSIBLE_FOR_PAYMENT, value: e.target.value })
              }
            />
          </Grid>
          {employmentData.isResponsibleForPayment == "NO" && (
            <Grid item lg={6} xs={12}>
              <BaseInput
                type="text"
                value={employmentData.responsiblePersonName}
                onChange={(e) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.PERSON_RESPONSIBLE, value: e.target.value })
                }
                fullWidth
                minWidth="227px"
                label={t("labels.nameOfResponsiblePerson")}
                labelId="name-of-person"
                placeholder={t("labels.enterNameOfResponsiblePerson")}
              />
            </Grid>
          )}
        </Grid>

        <Grid container spacing={2} marginTop="5px">
          <Grid item lg={6} xs={12}>
            <StyledRadioButtonGroup
              groupId="bread-winner-group-lable"
              groupTitle={t("labels.breadwinnerTitle")}
              value={employmentData.isBreadWinner}
              textValues={yesNoValues}
              onChange={(e) => dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.SOLE_BREADWINNER, value: e.target.value })}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <StyledRadioButtonGroup
              groupId="rdef-group-lable"
              groupTitle={t("labels.rdef")}
              value={employmentData.isRDEF}
              textValues={yesNoValues}
              onChange={(e) => dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.RDEF, value: e.target.value })}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} marginTop="8px">
          <Grid item lg={6} xs={12}>
            <StyledDropdown
              placeholder={t("labels.chooseAccommodation")}
              optionsArray={translatedOptions}
              onClick={(value) => dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.ACCOMODATION_TYPE, value })}
              value={t(employmentData.accomodationType)}
              label={t("labels.accommodationType")}
              labelId="accommodationType"
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <StyledRadioButtonGroup
              groupId="home-owner-group-lable"
              groupTitle={t("labels.homeOwnerTitle")}
              value={employmentData.isHomeOwner}
              textValues={yesNoValues}
              onChange={(e) => dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.HOME_OWNER, value: e.target.value })}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <StyledRadioButtonGroup
              groupId="political-person-group-lable"
              groupTitle={t("labels.politicalPersonTitle")}
              value={employmentData.isPolitician}
              textValues={yesNoValues}
              onChange={(e) => dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.POLITICAL_PERSON, value: e.target.value })}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <StyledRadioButtonGroup
              groupId="quara-finance-employee-group-lable"
              groupTitle={t("labels.quaraFinanceEmployee")}
              value={employmentData.isQuaraFinanceEmployee}
              textValues={yesNoValues}
              onChange={(e) =>
                dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.QUARA_FINANCE_EMPLOYEE, value: e.target.value })
              }
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <StyledRadioButtonGroup
              groupId="quara-finance-relative-group-lable"
              groupTitle={t("labels.quaraFinanceRelative")}
              value={employmentData.isQuaraFinanceRelative}
              textValues={yesNoValues}
              onChange={(e) => {
                if (employmentData.quaraFinanceRelativeDetails.length === 0) {
                  const newVal: TEmploymentRelativeDetails[] = [
                    { relativeId: "", relativeName: "", relationshipPosition: "", relationship: "" },
                  ];
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.QUARA_FINANCE_RELATIVE_DETAILS, value: newVal });
                } else {
                  const newVal: TEmploymentRelativeDetails[] = [];
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.QUARA_FINANCE_RELATIVE_DETAILS, value: newVal });
                }
                dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.QUARA_FINANCE_RELATIVE, value: e.target.value });
              }}
            />
          </Grid>
        </Grid>
        {employmentData.isQuaraFinanceRelative == "YES" &&
          employmentData.quaraFinanceRelativeDetails.map((_, index) => (
            <Grid
              key={index}
              container
              spacing={2}
              marginTop="8px"
            >
              <Grid
                item
                lg={12}
                paddingLeft={0}
                display="flex"
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography fontWeight="bold">
                  {(index + 1 > 5 ? index + 1 + "th" : t("labels.relativeNumbers." + (index + 1))) +
                    " " +
                    t("labels.relative")}
                </Typography>
                {index > 0 && (
                  <Button
                    tertiary
                    color="warning"
                    style={{ color: "red" }}
                    onClick={() => {
                      handleDeleteRelativeDialog(() => {
                        const newVal = employmentData.quaraFinanceRelativeDetails.filter((_, i) => i !== index);
                        dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.QUARA_FINANCE_RELATIVE_DETAILS, value: newVal });
                      });
                    }}
                  >
                    {t("buttons.removeLine") as string}
                  </Button>
                )}
              </Grid>
              <Grid
                item
                lg={6}
                xs={12}
                paddingLeft={0}
              >
                <BaseInput
                  type="text"
                  value={employmentData.quaraFinanceRelativeDetails[index].relativeId}
                  onChange={(e) => {
                    if (e.target.value.length <= 16) {
                      const newVal = [...employmentData.quaraFinanceRelativeDetails];
                      newVal[index].relativeId = e.target.value;
                      dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.QUARA_FINANCE_RELATIVE_DETAILS, value: newVal });
                    }
                  }}
                  fullWidth
                  minWidth="227px"
                  label={t("labels.relativeID")}
                  labelId="name-of-person"
                  placeholder={t("labels.placeholderRelativeID")}
                  required={true}
                />
              </Grid>
              <Grid
                item
                lg={6}
                xs={12}
                paddingLeft={0}
              >
                <BaseInput
                  type="text"
                  value={employmentData.quaraFinanceRelativeDetails[index].relativeName}
                  onChange={(e) => {
                    if (e.target.value.length <= 32) {
                      const newVal = [...employmentData.quaraFinanceRelativeDetails];
                      newVal[index].relativeName = e.target.value;
                      dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.QUARA_FINANCE_RELATIVE_DETAILS, value: newVal });
                    }
                  }}
                  fullWidth
                  minWidth="227px"
                  label={t("labels.relativeName")}
                  labelId="name-of-person"
                  placeholder={t("labels.placeholderRelativeName")}
                  required={true}
                />
              </Grid>
              <Grid
                item
                lg={6}
                xs={12}
                paddingLeft={0}
              >
                <BaseInput
                  type="text"
                  value={employmentData.quaraFinanceRelativeDetails[index].relationshipPosition}
                  onChange={(e) => {
                    if (e.target.value.length <= 32) {
                      const newVal = [...employmentData.quaraFinanceRelativeDetails];
                      newVal[index].relationshipPosition = e.target.value;
                      dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.QUARA_FINANCE_RELATIVE_DETAILS, value: newVal });
                    }
                  }}
                  fullWidth
                  minWidth="227px"
                  label={t("labels.relationshipPosition")}
                  labelId="name-of-person"
                  placeholder={t("labels.placeholderRelationshipPosition")}
                  required={true}
                />
              </Grid>
              <Grid
                item
                lg={6}
                xs={12}
                paddingLeft={0}
              >
                <StyledDropdown
                  placeholder={t("labels.placeholderRelationship")}
                  optionsArray={translatedRelationshipsOptions}
                  onClick={(value) => {
                    const newVal = [...employmentData.quaraFinanceRelativeDetails];
                    newVal[index].relationship = value;
                    dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.QUARA_FINANCE_RELATIVE_DETAILS, value: newVal });
                  }}
                  value={employmentData.quaraFinanceRelativeDetails[index].relationship}
                  label={t("labels.Relationship")}
                  labelId="Relationship"
                />
              </Grid>
            </Grid>
          ))}
        {employmentData.isQuaraFinanceRelative == "YES" && (
          <Grid
            item
            lg={12}
            xs={12}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <Button
              tertiary
              color="info"
              onClick={() => {
                const newVal = [...employmentData.quaraFinanceRelativeDetails];
                newVal.push({ relativeId: "", relativeName: "", relationshipPosition: "", relationship: "" });
                dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.QUARA_FINANCE_RELATIVE_DETAILS, value: newVal });
              }}
            >
              {t("buttons.addMoreLine") as string}
            </Button>
          </Grid>
        )}
      </Grid>

      <Grid
        style={{
          background: "white",
          padding: "30px 20px",
          borderRadius: "16px",
          margin: "auto",
          marginBottom: "20px",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            container
            item
            lg={6}
            xs={12}
          >
            <Grid item display="flex" alignItems="center">
              <Checkbox checked={consent} onChange={handleSetConsent} />
              <Typography onClick={() => setConsent((value) => !value)}>
                {t("employmentDetails.consent")}{" "}
                <Link
                  component="button"
                  onClick={handleOpenConsentDialog}
                  style={{ color: COLORS.PRIMARY_DARK, textDecorationColor: COLORS.PRIMARY_DARK }}
                >
                  {t("employmentDetails.fullConsent")}
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            lg={6}
            justifyContent="space-between"
            alignItems="center"
          >
            <Button tertiary color="secondary" onClick={history.goBack}>
              {t("buttons.back") as string}
            </Button>
            <StyledContinueButton
              sx={{ width: { xs: "150px", sm: "170px" } }}
              onClick={handleContinueClick}
              disabled={!isValid}
            >
              {t("buttons.continue") as string}
            </StyledContinueButton>
          </Grid>
        </Grid>
      </Grid>
      {isDialogOpen && (
        <EmploymentDialogs
          {...dialogInfo}
          onReopenDialog={handleOpenDialog}
          closeDialog={handleCloseDialog}
          isDialogOpen={isDialogOpen}
          openDialog={openDialog}
        />
      )}
    </>
  );
};
