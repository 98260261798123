import { IAxiosResponseWithError, network } from "@utils/network/network";

export interface CommodityProduct {
  amount: string;
  commodityName: string;
  currency: string;
  quantity: string;
}

export const getCommodityProducts = async (
  checkoutToken: string,
): Promise<IAxiosResponseWithError<CommodityProduct>> => {
  return await network.request<CommodityProduct>("order", "customer/checkout/commodity/details", {
    method: "GET",
    params: { checkoutToken },
  });
};
