import { COMMON_REQUEST_DIALOGS } from "@appTypes/dialogs";
import { EMPLOYMENT_SECTORS } from "@constants/employmentsSectors";
import { CHECK_SRS_DIALOG_TYPES } from "@utils/hooks/useCheckSRS/useCheckSRS";
import { SAFETY_CHECK_DIALOG_TYPES } from "@utils/hooks/useCheckSRS/useSafetyCheck";

export enum EMPLOYMENT_DETAILS_DIALOGS {
  PRIVATE_SECTOR_NOT_VERIFIED = "PRIVATE_SECTOR_NOT_VERIFIED",
  GOVERNMENT_SECTOR_NOT_VERIFIED = "GOVERNMENT_SECTOR_NOT_VERIFIED",
  CONSENT = "CONSENT",
  DELETE_RELATIVE = "DELETE_RELATIVE",
}

export type employmentDialogTypes =
  | EMPLOYMENT_DETAILS_DIALOGS
  | COMMON_REQUEST_DIALOGS
  | CHECK_SRS_DIALOG_TYPES
  | SAFETY_CHECK_DIALOG_TYPES;

export type TEmploymentDetails = {
  employmentSector: EMPLOYMENT_SECTORS;
  monthlyIncome: number;
  monthlyRent: number;
  householdWage: number;
  utilityExpense: number;
  insurancePremium: number;
  transportExpense: number;
  healthcareExpense: number;
  communicationExpense: number;
  foodAndLivingExpense: number;
  expatDependentGovtFees: number;
  educationSuppliesExpense: number;
  monthlyRemittance: number;
  otherMonthlyExpense: number;
  otherObligation: number;
  dependantsPublicSchool: number;
  dependantsPrivateSchool: number;
  householdHelp: number;
  allDependants: number;
  isResponsibleForPayment: "YES" | "NO";
  responsiblePersonName: string;
  isBreadWinner: "YES" | "NO";
  isRDEF: "YES" | "NO";
  accomodationType: string;
  isHomeOwner: "YES" | "NO";
  isPolitician: "YES" | "NO";
  isQuaraFinanceEmployee: "YES" | "NO";
  isQuaraFinanceRelative: "YES" | "NO";
  quaraFinanceRelativeDetails: TEmploymentRelativeDetails[];
};

export type TEmploymentRelativeDetails = {
  relativeId: string;
  relativeName: string;
  relationshipPosition: string;
  relationship: string;
};
