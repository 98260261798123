import { Grid } from "@mui/material";
import { Button, Typography } from "@ui-kit";
import { FC } from "react";

interface IOrderStatusProps {
  icon: JSX.Element;
  title: string;
  content: JSX.Element;
  continueButtonText: string;
  onContinue?: () => void;
}

export const OrderStatusContent: FC<IOrderStatusProps> = ({ icon, title, content, continueButtonText, onContinue }) => {
  return (
    <>
      <Grid
        container
        flexDirection="column"
        maxWidth="463px"
        margin="auto"
        alignItems="center"
        mb="36px"
      >
        <Grid item mb="40px">
          {icon}
        </Grid>
        <Grid item mb="16px">
          <Typography variant="h3" fontWeight="bold" textAlign="center">
            {title}
          </Typography>
        </Grid>
        <Grid item textAlign="center">
          {content}
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item sx={{ width: { xs: "100%", sm: "auto" } }}>
          <Button onClick={onContinue} sx={{ minWidth: "240px", width: { xs: "100%" } }}>
            {continueButtonText}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
