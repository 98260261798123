import { OSTypes } from "@constants/common";

const appleRegexps = [/iPhone/i, /iPad/i, /iPod/i];
const androidRegexps = [/Android/i];

const matchDevices = (regexpArr: RegExp[]): boolean => {
  return regexpArr.some((toMatchItem: RegExp) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export const getOSType = (): keyof typeof OSTypes => {
  if (matchDevices(appleRegexps)) {
    return "iOS";
  }

  if (matchDevices(androidRegexps)) {
    return "Android";
  }

  return "PC";
};
