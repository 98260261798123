import { IAxiosResponseWithError, network } from "@utils/network/network";

export interface data {
  apr: string;
}

export const getAPRNumber = async (checkoutToken: string): Promise<IAxiosResponseWithError<data>> => {
  return await network.request<data>("order", "customer/checkout/offer/apr", {
    method: "GET",
    params: {
      checkoutToken,
    },
  });
};
