import React from "react";
import { Switch, Route } from "react-router-dom";

import { siteMap } from "@constants/siteMap";
import { PrivateRoute } from "@pages/AppRouter/PrivateRoute";
import { ForgotPassword } from "@pages/ForgotPassword";
import { ForgotPasswordOTP } from "@pages/ForgotPasswordOTP";
import { Login } from "@pages/Login";
import { ResetUserPassword } from "@pages/ResetUserPassword";
import { SetOTP } from "@pages/SetOTP";
import { UnblockUser } from "@pages/UnblockUser";

export const LoginRouter: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute path={siteMap.LoginOTPPage.path}>
        <SetOTP />
      </PrivateRoute>
      <Route path={siteMap.ForgotPasswordOTPPage.path}>
        <ForgotPasswordOTP />
      </Route>
      <Route path={siteMap.ForgotPasswordPage.path}>
        <ForgotPassword />
      </Route>
      <Route path={siteMap.ResetPasswordPage.path}>
        <ResetUserPassword />
      </Route>
      <Route path={siteMap.UnblockUser.path}>
        <UnblockUser />
      </Route>
      <Route path={siteMap.LogInPage.path}>
        <Login />
      </Route>
    </Switch>
  );
};
