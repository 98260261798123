import { Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { Button, Typography } from "@ui-kit";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { IDialogContents } from "@appTypes/dialogs";
import { BasicDialog } from "@components/Dialogs/BasicDialog";
import { TextWithEmail } from "@components/TextWithEmail";
import { SUPPORT_EMAIL } from "@constants/common";
import * as httpCodes from "@constants/httpStatuses";
import { BOX_BACKGROUND_COLOR_DEFAULT_CONTRACT, DEFAULT_MERCHANT_DATA_KEY } from "@constants/sessionStorageDefaults";
import { BOX_BACKGROUND_COLOR, MERCHANT_DATA_KEY } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";
import { menuService } from "@store/menu";
import { useDialog } from "@utils/hooks/useDialog";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { getMerchantURLs } from "@utils/network/customerInfo/getMerchantURLs";
import { logoutWithRedirect } from "@utils/services/Authentication/logoutWithRedirect";

import { MODAL_TYPES, offerDetailsModalContent } from "./CreditLimitCalculation.constants";
import { useCustomerDecision } from "./hooks/useCustomerDecision";
import { useLinkOrder, IRegularBNPLOffer } from "./hooks/useUserLink";
import { AmountTxt, RequestAmountTxt, PricingItem, PricingItemBox, PricingMonths, PricingCost } from "./styles";

interface IButtonsConfig {
  onAgree?(): void;
  onCancel?(): void;
}

type LocationProps = {
  state: {
    offers: IRegularBNPLOffer[];
    offerObject: IRegularBNPLOffer;
    reqAmount: number;
  };
};

export const NewCreditLimitCalculation: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation() as unknown as LocationProps;

  const [isDialogOpen, closeDialog, openDialog] = useDialog();
  const [offersList, setOffersList] = useState<IRegularBNPLOffer[]>();
  const [requestedAmount, setRequestedAmount] = useState<number>(0);
  const [selectedOffer, setSelectedOffer] = useState<{ key: number; offerItem: IRegularBNPLOffer } | undefined>();
  const [merchantData, setMerchantData] = useSessionStorageState(MERCHANT_DATA_KEY, DEFAULT_MERCHANT_DATA_KEY);
  const [linkOrderRequest] = useLinkOrder(merchantData.checkoutToken);
  const [sendDecisionRequest] = useCustomerDecision(merchantData.checkoutToken);
  const [, setBoxBackgroundColor] = useSessionStorageState(BOX_BACKGROUND_COLOR, BOX_BACKGROUND_COLOR_DEFAULT_CONTRACT);

  const [modalType, setModalType] = useState<keyof typeof MODAL_TYPES>(MODAL_TYPES.OFFER_EXPIRED);
  const [modalContent, setModalContent] = useState<IDialogContents>(offerDetailsModalContent.OFFER_EXPIRED);

  useEffect(() => {
    setBoxBackgroundColor({ withColor: true });
    menuService.setMenuData({ leftSide: "", rightSide: "", needIcon: false });
    if (!location.state.offers) {
      onLoadPage();
    } else {
      const indexNum: number = location.state.offers.findIndex(function (item) {
        return item.tenure === location.state.offerObject.tenure;
      });
      setOffersList(location.state.offers);
      setRequestedAmount(location.state.reqAmount);
      onChooseOffer(indexNum, location.state.offerObject);
    }
  }, []);

  const getMerchanturls = async (callBack: () => void) => {
    try {
      const { data, status } = await getMerchantURLs(merchantData.checkoutToken);
      if (status === httpCodes.OK) {
        setMerchantData({
          ...merchantData,
          orderId: data.orderId,
          merchantUrl: data.merchantUrl,
          merchantSuccessUrl: data.success,
          merchantFailureUrl: data.failure,
          merchantCancelUrl: data.cancel,
        });
      } else {
        console.log("User info request status:", status);
      }
    } catch (e) {
      console.log("User info request error");
    }
    callBack();
  };

  const onLoadPage = () => {
    getMerchanturls(() => linkOrder());
    const linkOrder = async () => {
      try {
        const { data, status, error } = await linkOrderRequest();
        switch (status) {
        case httpCodes.OK: {
          const hasOffers = !!data.regularOffers?.length;

          if (data.eligibilityStatus && hasOffers) {
            setOffersList(data.regularOffers);
            setRequestedAmount(data.principle);
            onChooseOffer(0, data.regularOffers[0]);
          } else {
            openUnexpectedErrorDialog();
          }
          break;
        }
        case httpCodes.CONFLICT:
          history.push({
            pathname: siteMap.ConfirmationPage.path,
            state: { hasMultipleOpenContracts: true, orderStatus: error?.error },
          });
          break;
        default:
          openUnexpectedErrorDialog();
        }
      } catch (e) {
        openUnexpectedErrorDialog();
      }
    };
  };

  const openUnexpectedErrorDialog = () => {
    setModalType(MODAL_TYPES.CAN_NOT_PROVIDE_OFFER);
    setModalContent({
      ...offerDetailsModalContent.CAN_NOT_PROVIDE_OFFER,
      description: (
        <TextWithEmail
          textKey={offerDetailsModalContent.CAN_NOT_PROVIDE_OFFER.description as string}
          email={SUPPORT_EMAIL}
        />
      ),
    });
    openDialog();
  };

  const handleCancelClick = () => {
    setModalType(MODAL_TYPES.CONFIRM_CANCEL_PROCESS);
    setModalContent(offerDetailsModalContent[MODAL_TYPES.CONFIRM_CANCEL_PROCESS]);
    openDialog();
  };

  const onChooseOffer = (itemKey: number, object: IRegularBNPLOffer) => {
    setSelectedOffer({ key: itemKey, offerItem: object });
  };
  const handleCancelOffer = async () => {
    try {
      await sendDecisionRequest("DECLINE");
      await logoutWithRedirect(merchantData.merchantCancelUrl);
    } catch (e) {
      console.log("Decline offer error: ", e);
    }
  };

  const handleGoToMerchantCancel = async () => {
    await logoutWithRedirect(merchantData.merchantCancelUrl);
  };

  const handleFailure = async () => {
    await logoutWithRedirect(merchantData.merchantFailureUrl);
  };

  const modalButtonsConfig = useMemo(() => {
    const modalHandlers: Record<keyof typeof MODAL_TYPES, IButtonsConfig> = {
      CONFIRM_CANCEL_PROCESS: {
        onCancel: closeDialog,
        onAgree: handleCancelOffer,
      },
      OFFER_EXPIRED: {
        onAgree: handleGoToMerchantCancel,
      },
      CAN_NOT_PROVIDE_OFFER: {
        onAgree: handleFailure,
      },
      APR: {},
    };

    return { ...modalHandlers[modalType] } || {};
  }, [modalType]);

  return (
    <>
      {requestedAmount !== 0 ? (
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent={"center"}>
              <RequestAmountTxt variant="h4">{t("newOfferCalculator.requestAmount")}</RequestAmountTxt>
              <AmountTxt variant="h4">
                {requestedAmount ?? 0} {t("currency.SAR")}
              </AmountTxt>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            textAlign={"center"}
            style={{ paddingTop: 30 }}
          >
            <Typography variant="h6">{t("newOfferCalculator.description")}</Typography>
          </Grid>
          <Grid item xs={12} textAlign={"center"}>
            <Grid container rowSpacing={5} padding={"8% 12%"}>
              {offersList?.map((item, i) => (
                <PricingItem
                  key={i}
                  item
                  sm={4}
                  onClick={() => onChooseOffer(i, item)}
                >
                  <PricingItemBox className={selectedOffer?.key == i ? "checked" : ""}>
                    <PricingMonths>
                      {item.tenure} {t("units.month_other")}
                    </PricingMonths>
                    <PricingCost>
                      {item.installments} {t("newOfferCalculator.sarPerMonth")}
                    </PricingCost>
                  </PricingItemBox>
                </PricingItem>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent={{ sm: "space-around" }}
          >
            <Button
              tertiary
              sx={{ width: { xs: "157px", sm: "240px" } }}
              onClick={handleCancelClick}
              color={!isMobile ? "secondary" : "inherit"}
            >
              {t("buttons.cancel") as string}
            </Button>
            <Button
              onClick={() =>
                history.push({
                  pathname: siteMap.OfferDetailsPage.path,
                  state: { offers: offersList, offerObject: selectedOffer?.offerItem, reqAmount: requestedAmount },
                })
              }
              disabled={requestedAmount == 0 ? true : false}
              sx={{ width: { xs: "157px", sm: "240px" } }}
            >
              {t("buttons.continue") as string}
            </Button>
          </Grid>
        </Grid>
      ) : null}
      <BasicDialog
        open={isDialogOpen}
        icon={modalContent.icon}
        title={t(modalContent.title)}
        onAgree={modalButtonsConfig.onAgree}
        onCancel={modalButtonsConfig.onCancel}
        description={
          <Typography variant="p3" textAlign="center" component="p">
            {typeof modalContent.description === "string" ? t(modalContent.description) : modalContent.description}
          </Typography>
        }
        agreeButtonText={t(modalContent.agreeText || "")}
        cancelButtonText={t(modalContent.cancelText || "")}
      />
    </>
  );
};
