import { Route, Redirect, RouteProps } from "react-router-dom";

import { siteMap } from "@constants/siteMap";
import { authorizationStore } from "@store/authorization";

interface IPrivateRouteProps extends RouteProps {
  children: React.ReactNode;
}

export const PrivateRoute: React.FC<IPrivateRouteProps> = ({ children, ...rest }) => {
  const isLoggedIn = authorizationStore.isLoggedIn();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: siteMap.CreateAccountPage.path,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
