import { ReactComponent as InfoIcon } from "@assets/icons/popup_error_icon.svg";

export const privateSectorVerificationFailedDialog = {
  title: "dialogTitle.privateSectorVerificationFailed",
  description: "dialogContent.contactForMoreOptions",
  agreeText: "buttons.selectSector",
  cancelText: "buttons.backToStore",
  icon: <InfoIcon />,
};

export const governmentSectorVerificationFailedDialog = {
  title: "dialogTitle.governmentSectorVerificationFailed",
  description: "dialogContent.contactForMoreOptions",
  agreeText: "buttons.selectSector",
  cancelText: "buttons.backToStore",
  icon: <InfoIcon />,
};

export const ACCOMMODATION_TYPES = {
  ownHouse: {
    label: "labels.ownHouse",
    key: "labels.ownHouse",
  },
  rental: {
    label: "labels.rental",
    key: "labels.rental",
  },
};

export const RELATIONSHIPS = {
  grandparent: {
    label: "pageContent.relationship.Grandparent",
    key: "grandparent",
  },
  parent: {
    label: "pageContent.relationship.Parent",
    key: "parent",
  },
  sibling: {
    label: "pageContent.relationship.Sibling",
    key: "sibling",
  },
  cousin: {
    label: "pageContent.relationship.Cousin",
    key: "cousin",
  },
  spouse: {
    label: "pageContent.relationship.Spouse",
    key: "spouse",
  },
  offspring: {
    label: "pageContent.relationship.Offspring",
    key: "offspring",
  },
};
