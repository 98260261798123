import {
  IEmploymentDetailsRequestParams,
  IEmploymentDetailsResult,
  IEmploymentSectorResponse,
} from "@modules/EmploymentSector/EmploymentSector.types";
import { getSectorVerificationResult } from "@modules/EmploymentSector/helpers/sectorVerificationDialogs";
import { IAxiosResponseWithError, network } from "@utils/network/network";

type useRequestType = {
  employmentDetailsRequest: (
    orderId: string,
    checkoutToken: string,
    employmentDetailsData: IEmploymentDetailsRequestParams,
  ) => Promise<IAxiosResponseWithError<IEmploymentSectorResponse>>;
  setEmploymentDetails: (
    orderId: string,
    checkoutToken: string,
    employmentDetailsData: IEmploymentDetailsRequestParams,
  ) => Promise<IEmploymentDetailsResult>;
};

export const useEmploymentDetails = (): useRequestType => {
  const employmentDetailsRequest = (
    orderId: string,
    checkoutToken: string,
    employmentDetailsData: IEmploymentDetailsRequestParams,
  ): Promise<IAxiosResponseWithError<IEmploymentSectorResponse>> => {
    return network.request<IEmploymentSectorResponse>("connect", "customer/employment", {
      method: "POST",
      data: employmentDetailsData,
      params: {
        orderId,
        checkoutToken,
      },
    });
  };

  const setEmploymentDetails = async (
    orderId: string,
    checkoutToken: string,
    employmentDetailsData: IEmploymentDetailsRequestParams,
  ): Promise<IEmploymentDetailsResult> => {
    const { data, status } = await employmentDetailsRequest(orderId, checkoutToken, employmentDetailsData);

    return getSectorVerificationResult(status, data?.result, employmentDetailsData.employmentSector);
  };

  return { employmentDetailsRequest, setEmploymentDetails };
};
