import { DateObject } from "react-multi-date-picker";

import { FORM_ERRORS } from "@modules/Registration/SetUserData/SetUserData.constants";
import {
  minValueValidator,
  composeValidators,
  emailValidator,
  phoneValidator,
  luhnValidator,
  lengthValidator,
  containsOnlyNumbersValidator,
  startsWithValidator,
} from "@utils/services/Validation";

import { FormErrors } from "./UnblockUserData.types";

export type UnblockUserFormFields = {
  MOBILE_PHONE: string;
  BIRTH_DATE: DateObject | Date | string;
};

export enum UNBLOCK_USER_FORM_FIELDS {
  BIRTH_DATE = "BIRTH_DATE",
  MOBILE_PHONE = "MOBILE_PHONE",
}

export const DEFAULT_UNBLOCK_USER_FORM_DATA = {
  [UNBLOCK_USER_FORM_FIELDS.BIRTH_DATE]: "",
  [UNBLOCK_USER_FORM_FIELDS.MOBILE_PHONE]: "",
};

export const DEFAULT_FORM_ERRORS: FormErrors = {
  [UNBLOCK_USER_FORM_FIELDS.BIRTH_DATE]: [],
  [UNBLOCK_USER_FORM_FIELDS.MOBILE_PHONE]: [],
};

const isPhoneValid = phoneValidator(FORM_ERRORS.PHONE_NOT_VALID_ERROR);
const validatePhone = composeValidators(isPhoneValid);

export const UnblockUserInfoValidators = {
  [UNBLOCK_USER_FORM_FIELDS.BIRTH_DATE]: (): [] => [],
  [UNBLOCK_USER_FORM_FIELDS.MOBILE_PHONE]: validatePhone,
};
