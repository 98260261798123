import { Grid, Stack } from "@mui/material";
import { Button, Typography } from "@ui-kit";
import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { IDialogContents } from "@appTypes/dialogs";
import { BasicDialog } from "@components/Dialogs/BasicDialog";
import { TextWithEmail } from "@components/TextWithEmail";
import { SUPPORT_EMAIL } from "@constants/common";
import * as httpCodes from "@constants/httpStatuses";
import { DEFAULT_MERCHANT_DATA_KEY, STORAGE_DEFAULT_CONTRACT } from "@constants/sessionStorageDefaults";
import { CONTRACT_DATA_KEY, MERCHANT_DATA_KEY } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";
import {
  MODAL_TYPES,
  offerDetailsModalContent,
} from "@pages/NewCreditLimitCalculation/CreditLimitCalculation.constants";
import { decisionType, useCustomerDecision } from "@pages/NewCreditLimitCalculation/hooks/useCustomerDecision";
import { IRegularBNPLOffer } from "@pages/NewCreditLimitCalculation/hooks/useUserLink";
import { menuService } from "@store/menu";
import { useDialog } from "@utils/hooks/useDialog";
import { getEncryptedValue, useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { getContractStatusRequest } from "@utils/network/contractInfo/getContractStatus";
import { logoutWithRedirect } from "@utils/services/Authentication/logoutWithRedirect";

import { getAPRNumber } from "./hooks/getAPRNumber";
import {
  AmountTxt,
  RequestAmountTxt,
  MainBox,
  BoxRow,
  CenterContainer,
  RequestedAmount,
  RequestedAmountValue,
  TotalAmount,
  APRAmount,
} from "./styles";

type LocationProps = {
  state: {
    offers: IRegularBNPLOffer[];
    offerObject: IRegularBNPLOffer;
    reqAmount: number;
  };
};
interface IButtonsConfig {
  onAgree?(): void;
  onCancel?(): void;
}

export const OfferDetails: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation() as unknown as LocationProps;
  const [merchantData] = useSessionStorageState(MERCHANT_DATA_KEY, DEFAULT_MERCHANT_DATA_KEY);
  const [sendDecisionRequest] = useCustomerDecision(merchantData.checkoutToken);
  const [contractData, setContractData] = useSessionStorageState(CONTRACT_DATA_KEY, STORAGE_DEFAULT_CONTRACT);
  const [isLoading, setIsLoading] = useState(false);
  const [apr, setAPR] = useState("");
  const merchantType = JSON.parse(getEncryptedValue("orderData") ?? "").merchantType;

  // Dialog functions
  const [isDialogOpen, closeDialog, openDialog] = useDialog();
  const [modalType, setModalType] = useState<keyof typeof MODAL_TYPES>(MODAL_TYPES.OFFER_EXPIRED);
  const [modalContent, setModalContent] = useState<IDialogContents>(offerDetailsModalContent.OFFER_EXPIRED);

  useEffect(() => {
    menuService.setMenuData({ leftSide: "", rightSide: "", needIcon: false });
  }, []);

  const handleAPR = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await getAPRNumber(merchantData.checkoutToken);

      if (status === httpCodes.OK) {
        setAPR(data.apr);
        openAPRDialog(data.apr);
        setIsLoading(false);

        // check promissory note if it's required or not to skip the promissory note step
        const {
          data: { promissoryNoteState },
        } = await getContractStatusRequest(merchantData.orderId);

        await setContractData({ ...contractData, ePromissoryNoteState: promissoryNoteState });
      } else {
        setIsLoading(false);
        openUnexpectedErrorDialog();
      }
    } catch (e) {
      setIsLoading(false);
      openUnexpectedErrorDialog();
    }
  };

  const handleContinueClick = async () => {
    setIsLoading(true);
    try {
      await sendDecision("CONFIRM", async (success) => {
        if (success) {
          handleAPR();
        } else {
          history.push(siteMap.Error404Page.path);
        }
      });
    } catch (e) {
      setIsLoading(false);
      openUnexpectedErrorDialog();
    }
  };

  const handleCancelClick = () => {
    setModalType(MODAL_TYPES.CONFIRM_CANCEL_PROCESS);
    setModalContent(offerDetailsModalContent[MODAL_TYPES.CONFIRM_CANCEL_PROCESS]);
    openDialog();
  };

  const handleGoToMerchantCancel = async () => {
    sendDecision("DECLINE", async (success) => {
      if (success) {
        await logoutWithRedirect(merchantData.merchantCancelUrl);
      }
    });
  };
  const handleGoToMerchantFailure = async () => {
    sendDecision("DECLINE", async (success) => {
      if (success) {
        await logoutWithRedirect(merchantData.merchantFailureUrl);
      }
    });
  };

  const sendDecision = async (decision: decisionType, callBack: (success: boolean) => void) => {
    const { status } = await sendDecisionRequest(decision, {
      offerType: location.state.offerObject.offerType.toUpperCase(),
      ...{},
    });

    if (status === httpCodes.OK) {
      callBack(true);
    } else {
      callBack(false);
    }
  };

  const modalButtonsConfig = useMemo(() => {
    const modalHandlers: Record<keyof typeof MODAL_TYPES, IButtonsConfig> = {
      CONFIRM_CANCEL_PROCESS: {
        onCancel: () => (apr === "" ? closeDialog() : openAPRDialog(apr)),
        onAgree: handleGoToMerchantCancel,
      },
      OFFER_EXPIRED: {
        onAgree: handleGoToMerchantCancel,
      },
      CAN_NOT_PROVIDE_OFFER: {
        onAgree: handleGoToMerchantFailure,
      },
      APR: {
        onAgree: () => {
          console.log("orderData", getEncryptedValue("orderData"));
          console.log("merchantType", merchantType);
          merchantType == "SERVICE"
            ? history.push(siteMap.CommodityPurchase.path)
            : history.push(siteMap.ConfirmationPage.path);
        },
        onCancel: handleCancelClick,
      },
    };

    return { ...modalHandlers[modalType] } || {};
  }, [modalType]);

  const openUnexpectedErrorDialog = () => {
    setModalType(MODAL_TYPES.CAN_NOT_PROVIDE_OFFER);
    setModalContent({
      ...offerDetailsModalContent.CAN_NOT_PROVIDE_OFFER,
      description: (
        <TextWithEmail
          textKey={offerDetailsModalContent.CAN_NOT_PROVIDE_OFFER.description as string}
          email={SUPPORT_EMAIL}
        />
      ),
    });
    openDialog();
  };

  const openAPRDialog = (apr: string) => {
    setModalType(MODAL_TYPES.APR);
    setModalContent({
      ...offerDetailsModalContent.APR,
      description: (
        <>
          <RequestedAmount>{t("offerDetails.requested_amount")}</RequestedAmount>
          <RequestedAmountValue>
            {location.state.reqAmount} {t("currency.SAR")}
          </RequestedAmountValue>
          <TotalAmount>
            {t("offerDetails.totalAmount")}: {location.state.offerObject.loanAmount} {t("currency.SAR")}
          </TotalAmount>
          <APRAmount>
            {t("offerDetails.apr")} {apr}%
          </APRAmount>
        </>
      ),
    });
    openDialog();
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent={"center"}>
            <RequestAmountTxt variant="h4">{t("offerDetails.totalAmount")}</RequestAmountTxt>
            <AmountTxt variant="h4">
              {t("currency.SAR")} {location.state.offerObject.loanAmount}
            </AmountTxt>
          </Stack>
        </Grid>
        <CenterContainer item xs={12} textAlign={"center"}>
          <Grid
            item
            sm={12}
            textAlign="start"
            paddingBottom={2}
            paddingTop={2}
          >
            <Typography variant="h5" fontWeight={500}>
              {t("offerDetails.description")}
            </Typography>
          </Grid>
          <MainBox>
            <BoxRow>
              <Typography>{t("offerDetails.requested_amount")}:</Typography>
              <Typography fontWeight={700}>
                {location.state.reqAmount} {t("currency.SAR")}
              </Typography>
            </BoxRow>
            <BoxRow>
              <Typography>{t("offerDetails.profit")}:</Typography>
              <Typography>
                {location.state.offerObject.profit} {t("currency.SAR")}
              </Typography>
            </BoxRow>
            <BoxRow>
              <Typography>{t("offerDetails.tenure")}:</Typography>
              <Typography>
                {location.state.offerObject.tenure} {t("units.month_other")}
              </Typography>
            </BoxRow>
            <BoxRow>
              <Typography>{t("offerDetails.monthly_payment")}:</Typography>
              <Typography>
                {location.state.offerObject.installments} {t("currency.SAR")}
              </Typography>
            </BoxRow>
            <BoxRow borderBottom={"0px !important"} marginBottom={"0px !important"} paddingBottom={"0px !important"}>
              <Typography>{t("offerDetails.interest_rate")}:</Typography>
              <Typography>{location.state.offerObject.interestRate}%</Typography>
            </BoxRow>
          </MainBox>
        </CenterContainer>
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent={{ sm: "space-around" }}
        >
          <Button
            tertiary
            sx={{ width: { xs: "100%", sm: "240px" } }}
            disabled={isLoading}
            onClick={() =>
              history.push({
                pathname: siteMap.NewCreditLimitCalculationPage.path,
                state: {
                  offers: location.state.offers,
                  offerObject: location.state.offerObject,
                  reqAmount: location.state.reqAmount,
                },
              })
            }
            style={{ marginInlineEnd: 80 }}
          >
            {t("buttons.back") as string}
          </Button>

          <Button
            tertiary
            sx={{ width: { xs: "100%", sm: "240px" }, margin: { sm: "0 20px", xs: "0" } }}
            onClick={handleCancelClick}
            color="secondary"
            disabled={location.state.reqAmount === 0 || isLoading}
          >
            {t("buttons.reject") as string}
          </Button>
          <Button onClick={handleContinueClick} sx={{ width: { xs: "100%", sm: "240px" } }} disabled={isLoading}>
            {t("buttons.accept") as string}
          </Button>
        </Grid>
      </Grid>
      <BasicDialog
        open={isDialogOpen}
        icon={modalContent.icon}
        title={t(modalContent.title)}
        onAgree={modalButtonsConfig.onAgree}
        onCancel={modalButtonsConfig.onCancel}
        closeBtnTitle={t(modalContent.closeBtnTitle ?? "")}
        description={
          <Typography variant="p3" textAlign="center" component="p">
            {typeof modalContent.description === "string" ? t(modalContent.description) : modalContent.description}
          </Typography>
        }
        agreeButtonText={t(modalContent.agreeText || "")}
        cancelButtonText={t(modalContent.cancelText || "")}
      />
    </>
  );
};
