import { Box, useTheme } from "@mui/material";
import { COLORS, Typography } from "@ui-kit";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { CountdownTimer } from "@components/CountdownTimer";
import { ITimeObject } from "@utils/services/Timer/timer.types";

interface IBlockedAccountInfoProps {
  otpMessage: string;
  blockedTimer: ITimeObject<number>;
  setBlockedTimer: Dispatch<SetStateAction<ITimeObject<number> | null>>;
  setIsInputDisabled: Dispatch<SetStateAction<boolean>>;
  setInputError: Dispatch<SetStateAction<boolean>>;
  isMobile: boolean;
}

export const BlockedAccountInfo: React.FC<IBlockedAccountInfoProps> = ({
  otpMessage,
  blockedTimer,
  setBlockedTimer,
  setIsInputDisabled,
  setInputError,
  isMobile,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return isMobile ? (
    <Box maxWidth="234px" margin="0 auto">
      <Typography
        variant="p3"
        textAlign={"center"}
        color={COLORS.DARK_NEGATIVE}
        fontWeight={theme.direction === "rtl" ? 600 : "bold"}
        component="p"
        marginTop={"8px"}
      >
        {otpMessage}
      </Typography>
      <Box textAlign="center" marginTop="20px">
        <Typography variant="p3" color={COLORS.GREY_64} fontWeight={theme.direction === "rtl" ? 600 : "bold"}>
          {t("labels.retryIn")}{" "}
        </Typography>
        <CountdownTimer
          minutes={blockedTimer.minutes}
          seconds={blockedTimer.seconds}
          hours={blockedTimer.hours}
          setTimer={setBlockedTimer}
          onTimeoutCallback={() => {
            setIsInputDisabled(false);
            setInputError(false);
          }}
        />
      </Box>
    </Box>
  ) : (
    <Typography
      variant="p3"
      color={COLORS.DARK_NEGATIVE}
      fontWeight={theme.direction === "rtl" ? 600 : "bold"}
      component="p"
    >
      {otpMessage}{" "}
      <Typography
        component="span"
        variant="p3"
        color={COLORS.GREY_64}
        fontWeight={theme.direction === "rtl" ? 600 : "bold"}
      >
        {t("labels.retryIn")}{" "}
        <CountdownTimer
          minutes={blockedTimer.minutes}
          seconds={blockedTimer.seconds}
          hours={blockedTimer.hours}
          setTimer={setBlockedTimer}
          onTimeoutCallback={() => {
            setIsInputDisabled(false);
            setInputError(false);
          }}
        />
      </Typography>
    </Typography>
  );
};
