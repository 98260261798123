import { AxiosResponse } from "axios";

import { COMMON_REQUEST_DIALOGS, IDialogInfo } from "@appTypes/dialogs";
import { IKYCVerificationResponse, KYC_VALIDATION_STATUSES } from "@appTypes/responses";
import { OK as HTTP_OK } from "@constants/httpStatuses";
import { dialogContents } from "@constants/popups";
import { network } from "@utils/network/network";

import { unverifiedDateOfBirth } from "../SetUserPassword.constants";
import { IDialogOptions, PERSONAL_DATA_DIALOGS, setPasswordDialogs } from "../SetUserPassword.types";

export interface IRequestResult extends IDialogInfo<setPasswordDialogs, IDialogOptions> {
  isSuccess: boolean;
}

type useRequestType = {
  personalDataVerify: () => Promise<IRequestResult>;
};

export const useVerifyPersonalData = (): useRequestType => {
  const dobVerifyRequest = (): Promise<AxiosResponse<IKYCVerificationResponse>> => {
    return network.request<IKYCVerificationResponse>("connect", "kyc/personal-data", {
      method: "POST",
      withCredentials: process.env.REACT_APP_WITH_MOCK_SERVER !== "true",
    });
  };

  const personalDataVerify = async (): Promise<IRequestResult> => {
    const { data, status } = await dobVerifyRequest();

    if (status === HTTP_OK) {
      switch (data.result) {
      case KYC_VALIDATION_STATUSES.SUCCESS:
        return { isSuccess: true };

      case KYC_VALIDATION_STATUSES.FAILURE:
        return {
          isSuccess: false,
          dialogType: PERSONAL_DATA_DIALOGS.UNVERIFIED_DATE_OF_BIRTH,
          dialog: unverifiedDateOfBirth,
        };

      default:
        return {
          isSuccess: false,
          dialogType: COMMON_REQUEST_DIALOGS.SOMETHING_WENT_WRONG,
          dialog: dialogContents.somethingWentWrong,
        };
      }
    }

    return {
      isSuccess: false,
      dialogType: COMMON_REQUEST_DIALOGS.SOMETHING_WENT_WRONG,
      dialog: dialogContents.somethingWentWrong,
    };
  };

  return { personalDataVerify };
};
