import { ORDER_STATUSES } from "@constants/business/orderStatuses";
import { STORAGE_DEFAULT_CONTRACT } from "@constants/sessionStorageDefaults";
import { CONTRACT_DATA_KEY } from "@constants/sessionStorageKeys";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { IContractInfo, IContractTokenInfo } from "@utils/network/contractInfo/types";
import { IAxiosResponseWithError } from "@utils/network/network";

import { useRedirectByStatus } from "./useRedirectByStatus";

export const useStatusRouter = (
  currentStatus: keyof typeof ORDER_STATUSES | "",
  fromSMSLink = false,
): { onGetContractStatus(data: IAxiosResponseWithError<IContractInfo & IContractTokenInfo>): boolean } => {
  const [contractData, setContractData] = useSessionStorageState(CONTRACT_DATA_KEY, STORAGE_DEFAULT_CONTRACT);
  const { redirectByStatus } = useRedirectByStatus(currentStatus, fromSMSLink);

  const onGetContractStatus = ({ data }: IAxiosResponseWithError<IContractInfo & IContractTokenInfo>): boolean => {
    setContractData({
      ...contractData,
      ...(data.merchantUrl
        ? {
          merchantUrl: data.merchantUrl,
        }
        : {}),
      orderId: data.orderId,
      status: data.status,
      timer: data.timer,
      isNoteSigned: data.isNoteSigned,
      ePromissoryNoteState: data.promissoryNoteState,
    });

    if (!fromSMSLink) {
      return redirectByStatus(data.status, data.isNoteSigned, data.promissoryNoteState);
    }

    redirectByStatus(data.status, data.isNoteSigned, data.promissoryNoteState);

    return false;
  };

  return { onGetContractStatus };
};
