export const hasObjectValues = <T>(objectToCheck: T): boolean => {
  if (!objectToCheck) {
    return false;
  }
  return Object.values(objectToCheck).every((x) => x !== null || x !== "");
};

// TODO: BUG - previous version does nothing;
export const hasObjectValuesCorrect = <T>(objectToCheck: T): boolean => {
  if (!objectToCheck) {
    return false;
  }
  return Object.values(objectToCheck).every((x) => x !== null && x !== "");
};
