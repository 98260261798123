import { AxiosResponse } from "axios";

import { COMMON_REQUEST_DIALOGS, IDialogInfo } from "@appTypes/dialogs";
import { OK as HTTP_OK } from "@constants/httpStatuses";
import { dialogContents } from "@constants/popups";
import { IAxiosResponseWithError, network } from "@utils/network/network";

export interface IRequestResult extends IDialogInfo<any> {
  isSuccess: boolean;
}
type useRequestType = { unblockUser: (requestData: UseUnblockUserRequestType) => Promise<IRequestResult> };

type UseUnblockUserRequestType = {
  uid: string;
  phoneNumber: string;
  dateOfBirth: string;
};

export const useUnblockUser = (): useRequestType => {
  const unblockUserRequest = (requestData: UseUnblockUserRequestType): Promise<AxiosResponse<{ result: string }>> => {
    return network.request<{ result: string }>("connect", "customer/unblockUser", {
      method: "POST",
      withCredentials: process.env.REACT_APP_WITH_MOCK_SERVER !== "true",
      data: requestData,
    });
  };
  const unblockUser = async (requestData: UseUnblockUserRequestType): Promise<IRequestResult> => {
    const { data, status } = await unblockUserRequest(requestData);

    if (status === HTTP_OK) {
      switch (data.result) {
      case "SUCCESS":
        return { isSuccess: true };

      default:
        return {
          isSuccess: false,
          dialogType: COMMON_REQUEST_DIALOGS.SOMETHING_WENT_WRONG,
          dialog: dialogContents.somethingWentWrong,
        };
      }
    }

    return {
      isSuccess: false,
      dialogType: COMMON_REQUEST_DIALOGS.SOMETHING_WENT_WRONG,
      dialog: dialogContents.somethingWentWrong,
    };
  };

  return { unblockUser };
};
