import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import * as httpStatuses from "@constants/httpStatuses";
import { STORAGE_DEFAULT_CONTRACT } from "@constants/sessionStorageDefaults";
import { CONTRACT_DATA_KEY } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";
import { useStatusRouter } from "@modules/WebCheckout/hooks/useStatusRouter";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { getContractTokenStatusRequest } from "@utils/network/contractInfo/getContractTokenStatus";

export const useGoToContractStatus = (): { redirectByContractStatus(): Promise<void>; isRedirectBySMS: boolean } => {
  const [storageContract] = useSessionStorageState(CONTRACT_DATA_KEY, STORAGE_DEFAULT_CONTRACT);
  const { onGetContractStatus } = useStatusRouter("", true);
  const history = useHistory();

  const redirectByContractStatus = (token: string) =>
    useCallback(async () => {
      if (token) {
        try {
          const response = await getContractTokenStatusRequest(token);

          if (response.status === httpStatuses.OK) {
            onGetContractStatus(response);
          } else {
            history.push(siteMap.Error404Page.path);
          }
        } catch (e) {
          history.push(siteMap.Error404Page.path);
        }
      }
    }, [token]);

  return {
    redirectByContractStatus: redirectByContractStatus(storageContract.orderToken),
    isRedirectBySMS: !!storageContract.orderToken,
  };
};
