import { Grid, useMediaQuery } from "@mui/material";
import { Button, theme } from "@ui-kit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page } from "react-pdf";
import { useHistory } from "react-router-dom";

import pdfAr from "./ar.pdf";
import pdfEn from "./en.pdf";

export const Terms: React.FC = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleContinue = (accept: boolean) => history.push("/registration", { accept });

  const [numPages, setNumPages] = useState(0);
  const [pdf, setPdf] = useState("");

  function onDocumentLoadSuccess(numPages: any): void {
    setNumPages(numPages.numPages);
  }

  useEffect(() => {
    setPdf(i18n.language === "ar" ? pdfAr : pdfEn);
  });

  return (
    <>
      <Document onLoadSuccess={onDocumentLoadSuccess} file={pdf}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} className="pdf-page" />
        ))}
      </Document>
      <Grid container justifyContent="space-between" paddingTop={8}>
        <Grid item>
          <Button
            tertiary
            sx={{ minWidth: { md: "240px", xs: "150px" } }}
            color={!isMobile ? "secondary" : "inherit"}
            onClick={() => handleContinue(false)}
          >
            {t("buttons.goBack") as string}
          </Button>
        </Grid>
        <Grid item>
          <Button sx={{ minWidth: { md: "240px", xs: "150px" } }} onClick={() => handleContinue(true)}>
            {t("buttons.accept") as string}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
