import { AxiosResponse } from "axios";

import { COMMON_REQUEST_DIALOGS, IDialogInfo } from "@appTypes/dialogs";
import { OK as HTTP_OK } from "@constants/httpStatuses";
import { dialogContents } from "@constants/popups";
import { network } from "@utils/network/network";

import { UPDATE_DOB_RESPONSE_STATUSES, DIALOG_TYPES } from "../UpdateDateOfBirth.types";
import { UPDATE_DOB_DIALOGS } from "../UpdateDateOfBirth.types";

export interface IUpdateDOBResult
  extends IDialogInfo<COMMON_REQUEST_DIALOGS | UPDATE_DOB_DIALOGS, { blockedUntil?: string }> {
  isSuccess: boolean;
}
export interface IUpdateDOBResponse {
  result: keyof typeof UPDATE_DOB_RESPONSE_STATUSES;
  attempts: number;
  blockedUntil: string;
}

type useRequestType = [updateDateOfBirth: (date: string) => Promise<IUpdateDOBResult>];
export const useUpdateDateOfBirth = (): useRequestType => {
  const updateDateOfBirthRequest = (date: string): Promise<AxiosResponse<IUpdateDOBResponse>> => {
    return network.request<IUpdateDOBResponse>("connect", "kyc/personal-data", {
      method: "POST",
      withCredentials: process.env.REACT_APP_WITH_MOCK_SERVER !== "true",
      params: {
        date,
      },
    });
  };

  const updateDateOfBirth = async (date: string) => {
    const { data, status } = await updateDateOfBirthRequest(date);

    if (status === HTTP_OK) {
      switch (data.result) {
      case UPDATE_DOB_RESPONSE_STATUSES.SUCCESS:
        return { isSuccess: true };
      case UPDATE_DOB_RESPONSE_STATUSES.TEMP_BLOCKED:
        return {
          isSuccess: false,
          dialog: dialogContents.userLockedByRegAttempts,
          dialogType: DIALOG_TYPES.TEMP_BLOCKED,
          dialogOptions: {
            blockedUntil: data.blockedUntil,
          },
        };
      case UPDATE_DOB_RESPONSE_STATUSES.FAILURE:
        return {
          isSuccess: false,
        };
      default:
        return {
          isSuccess: false,
          dialogType: DIALOG_TYPES.SOMETHING_WENT_WRONG,
          dialog: dialogContents.somethingWentWrong,
        };
      }
    }

    return {
      isSuccess: false,
      dialogType: DIALOG_TYPES.SOMETHING_WENT_WRONG,
      dialog: dialogContents.somethingWentWrong,
    };
  };

  return [updateDateOfBirth];
};
