import { strengthType } from "@ui-kit";

export const getPasswordStrength = (password: string): strengthType => {
  const lowPassRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(.{6,})/;
  const mediumPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#$%^&*_\-+=`|\\(){}[\]:"<>,.?/])(.{7,})/;
  const strongPasswordRegex =
    /^(?=(.*[a-z]){2,})(?=(.*[A-Z]){2,})(?=.*[0-9])(?=.*[~!@#$%^&*_\-+=`|\\(){}[\]:"<>,.?/])(.{9,})/;
  if (password.match(strongPasswordRegex)) {
    return "Great";
  } else if (password.match(mediumPasswordRegex)) {
    return "Medium";
  } else if (password.match(lowPassRegex)) {
    return "Low";
  }
  return "Insufficient";
};

export type TPasswordRequirementReturnType = {
  message: string;
  value: boolean;
  options?: {
    numberOfSigns: string | number;
    count?: number;
  };
};

const UPPERCASE = "UPPERCASE";
const LOWERCASE = "LOWERCASE";
const NUMBER = "NUMBER";
const SPECIAL_SYMBOL = "SPECIAL_SYMBOL";
const SYMBOLS = "SYMBOLS";

export const getPasswordRequirements = (
  password: string,
  passwordStrength: strengthType = "Low",
  isArabic: boolean,
): Array<TPasswordRequirementReturnType> => {
  const charactersMin6SignsRegex = /.{6,}/;
  const charactersMin7SignsRegex = /.{7,}/;
  const charactersMin9SignsRegex = /.{9,}/;
  const minOneLowercaseLetterRegex = /[a-z]+/;
  const minTwoLowercaseLetterRegex = /(?=(.*[a-z]){2,})/;
  const minOneUppercaseLetterRegex = /[A-Z]+/;
  const minTwoUppercaseLetterRegex = /(?=(.*[A-Z]){2,})/;
  const minOneDigitRegex = /\d+/;
  const specialCharacterRegex = /[~!@#$%^&*_\-+=`|\\(){}[\]:"<>,.?/]+/;
  switch (passwordStrength) {
  case "Great":
    return [
      {
        message: UPPERCASE,
        options: { numberOfSigns: 2, count: 2 },
        value: Boolean(password.match(minTwoUppercaseLetterRegex)),
      },
      {
        message: LOWERCASE,
        options: { numberOfSigns: 2, count: 2 },
        value: Boolean(password.match(minTwoLowercaseLetterRegex)),
      },
      { message: NUMBER, options: { numberOfSigns: 1 }, value: Boolean(password.match(minOneDigitRegex)) },
      {
        message: SPECIAL_SYMBOL,
        options: { numberOfSigns: 1 },
        value: Boolean(password.match(specialCharacterRegex)),
      },
      {
        message: SYMBOLS,
        options: { numberOfSigns: isArabic ? "٩" : 9 },
        value: Boolean(password.match(charactersMin9SignsRegex)),
      },
    ];
  case "Medium":
    return [
      {
        message: UPPERCASE,
        options: { numberOfSigns: 1, count: 1 },
        value: Boolean(password.match(minOneUppercaseLetterRegex)),
      },
      {
        message: LOWERCASE,
        options: { numberOfSigns: 1, count: 1 },
        value: Boolean(password.match(minOneLowercaseLetterRegex)),
      },
      { message: NUMBER, options: { numberOfSigns: 1 }, value: Boolean(password.match(minOneDigitRegex)) },
      {
        message: SPECIAL_SYMBOL,
        options: { numberOfSigns: 1 },
        value: Boolean(password.match(specialCharacterRegex)),
      },
      {
        message: SYMBOLS,
        options: { numberOfSigns: isArabic ? "٧" : 7 },
        value: Boolean(password.match(charactersMin7SignsRegex)),
      },
    ];
  default:
    return [
      {
        message: UPPERCASE,
        options: { numberOfSigns: isArabic ? "١" : 1, count: 1 },
        value: Boolean(password.match(minOneUppercaseLetterRegex)),
      },
      {
        message: LOWERCASE,
        options: { numberOfSigns: 1, count: 1 },
        value: Boolean(password.match(minOneLowercaseLetterRegex)),
      },
      { message: NUMBER, options: { numberOfSigns: 1 }, value: Boolean(password.match(minOneDigitRegex)) },
      {
        message: SYMBOLS,
        options: { numberOfSigns: isArabic ? "٦" : 6 },
        value: Boolean(password.match(charactersMin6SignsRegex)),
      },
    ];
  }
};
