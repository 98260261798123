import secureLocalStorage from "react-secure-storage";

const notProduction = process.env.REACT_APP_WITH_MOCK_SERVER === "true" ? true : false;
// const notProduction = true;

function getEncryptedValue(key: string): string | undefined {
  if (notProduction) return sessionStorage.getItem(key)?.toString();
  return secureLocalStorage.getItem(key)?.toString();
}

function saveEncryptedValue(key: string, value: string | number | boolean): void {
  if (notProduction) return sessionStorage.setItem(key, value.toString());
  secureLocalStorage.setItem(key, value);
}

export { getEncryptedValue, saveEncryptedValue };
