import { Grid, Box, CircularProgress } from "@mui/material";
import { Typography } from "@ui-kit";
import { FC, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as LoanActivatedIcon } from "@assets/uiImages/loan_activated.svg";
import { StepperArabic } from "@components/StepperArabic";
import { MERCHANT_DATA_KEY } from "@constants/sessionStorageKeys";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { logoutWithRedirect } from "@utils/services/Authentication/logoutWithRedirect";

import { useSteps } from "../hooks/useSteps";

export const LoanActivated: FC = () => {
  const { t } = useTranslation();
  const [merchantData] = useSessionStorageState(MERCHANT_DATA_KEY, {
    checkoutToken: "",
    merchantUrl: "",
    merchantOrderId: "",
    merchantSuccessUrl: "",
    merchantFailureUrl: "",
    merchantCancelUrl: "",
  });
  const steps = useSteps();

  const handleBackToStoreClick = async () => {
    await logoutWithRedirect(merchantData.merchantSuccessUrl);
  };

  const [counter, setCounter] = useState(0);
  const myCounter = useRef(0);
  // Also don't forget this
  useEffect(() => {
    const timeout = setInterval(() => {
      setCounter((counter) => counter + 1);
      myCounter.current = myCounter.current + 1;
      console.log("counter: ", myCounter); // counter always return 0 but myCounter the updated value
      if (myCounter.current === 5) {
        clearInterval(timeout);
        handleBackToStoreClick();
      }
    }, 1000);
    return () => clearInterval(timeout);
  }, []);

  return (
    <>
      <Box maxWidth="456px" margin="auto">
        <StepperArabic steps={steps} activeStep={4} />
        <Grid container>
          <Grid item margin="auto" mt="40px">
            <LoanActivatedIcon />
          </Grid>
          <Grid item textAlign="center" mt="34px">
            <Typography variant="h3" fontWeight="bold" mb="16px">
              {t("checkout.title.loanIsActivated")}
            </Typography>
            <Typography variant="p1">{t("checkout.content.storeInformedAboutActivation")}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        justifyContent="center"
        alignItems={"center"}
        flexDirection={"column"}
        mt="60px"
      >
        <CircularProgress
          variant="determinate"
          thickness={3}
          size={100}
          value={counter * 20}
        />
        <Typography mt="20px" fontSize={18} variant="p1">
          {t("checkout.content.redirectionMSG", { variable: 5 - (counter * 20) / 20 })}
        </Typography>
      </Grid>
    </>
  );
};
