import { IVerifyOTPResponse } from "@appTypes/responses";
import { IAxiosResponseWithError, network } from "@utils/network/network";

type useResendOTPReturnType = [
  resendResetPasswordOTP: (id: string, phoneNumber: string) => Promise<IAxiosResponseWithError<IVerifyOTPResponse>>,
];

export const useResendResetPasswordOTP = (): useResendOTPReturnType => {
  const resendResetPasswordOTP = (
    id: string,
    phoneNumber: string,
  ): Promise<IAxiosResponseWithError<IVerifyOTPResponse>> => {
    return network.request<IVerifyOTPResponse>("keycloak", "password/reset/otp/resend", {
      method: "GET",
      params: {
        phone: `+966${phoneNumber}`,
        id: id,
      },
    });
  };

  return [resendResetPasswordOTP];
};
