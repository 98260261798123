import { styled } from "@mui/system";
import { FC, memo, SyntheticEvent } from "react";

import EmptyItem from "@assets/uiImages/empty_cart_item.svg";

const ImageContainer = styled("div")(() => ({
  border: "1px solid #EDEEEF",
  flexBasis: "70px",
  boxSizing: "border-box",
  borderRadius: "10px",
  width: "70px",
  height: "70px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  backgroundColor: "",
}));

const StyledImage = styled("img")(() => ({
  objectFit: "contain",
  width: "100%",
  height: "100%",
}));

export const ImageWrapper: FC<React.ImgHTMLAttributes<HTMLImageElement>> = memo(({ ...props }) => {
  const handleLoadError = ({ currentTarget }: SyntheticEvent<HTMLImageElement, Event>) => {
    currentTarget.onerror = null;
    currentTarget.src = EmptyItem;
  };

  return (
    <ImageContainer>
      <StyledImage {...props} onError={handleLoadError} />
    </ImageContainer>
  );
});

ImageWrapper.displayName = "ImageWrapper";
