import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { Button, Typography } from "@ui-kit";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import PartyPopper from "@assets/icons/party_popper(36_36).png";
import { ReactComponent as PersonsWithContract } from "@assets/uiImages/persons_with_contract.svg";
import { MERCHANT_DATA_KEY } from "@constants/sessionStorageKeys";
import { menuService } from "@store/menu";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { logoutWithRedirect } from "@utils/services/Authentication/logoutWithRedirect";

import { webCheckoutPageMap } from "../constants/pageMap";
import { useUserData } from "./hooks/useUserData";

export const WebCheckoutInitialStep: FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { userInfo } = useUserData();
  const [merchantData] = useSessionStorageState(MERCHANT_DATA_KEY, {
    checkoutToken: "",
    merchantUrl: "",
    merchantSuccessUrl: "",
    merchantFailureUrl: "",
    merchantCancelUrl: "",
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleBackToStoreClick = async () => {
    await logoutWithRedirect(merchantData.merchantCancelUrl);
  };

  const handleContinueClick = () => {
    history.push(webCheckoutPageMap.WaitingConfirmation.path);
  };

  useEffect(() => {
    menuService.setAdditionalContentInfo({ isAdditionalContentNeeded: false });
    menuService.setMenuData({ leftSide: "", rightSide: "", needIcon: false });
  }, []);

  return (
    <>
      <Grid
        container
        flexDirection="column"
        maxWidth="463px"
        margin="auto"
        alignItems="center"
        mb="36px"
      >
        <Grid item mb="40px">
          <PersonsWithContract />
        </Grid>
        <Grid item mb="8px">
          <Typography variant="h3" fontWeight="bold">
            {t("checkout.title.greetings", {
              name: i18n.language === "en" ? userInfo.englishFirstName : userInfo.arabicFirstName,
            })}{" "}
            <img
              src={PartyPopper}
              alt="Party popper"
              width="26px"
              height="26px"
            />
          </Typography>
        </Grid>
        <Grid item textAlign="center">
          <Typography variant="p1" textAlign="center">
            {t("checkout.content.contractIntroducing")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Button
            onClick={handleBackToStoreClick}
            tertiary
            sx={{ minWidth: { md: "240px", xs: "150px" } }}
            color={!isMobile ? "secondary" : "inherit"}
          >
            {t("buttons.backToStore") as string}
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={handleContinueClick} sx={{ minWidth: { md: "240px", xs: "150px" } }}>
            {t("buttons.getStarted") as string}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
