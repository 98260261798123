import { AxiosResponse } from "axios";

import { COMMON_REQUEST_DIALOGS, IDialogInfo } from "@appTypes/dialogs";
import { IKYCVerificationResponse, KYC_VALIDATION_STATUSES } from "@appTypes/responses";
import { OK as HTTP_OK } from "@constants/httpStatuses";
import { dialogContents } from "@constants/popups";
import { network } from "@utils/network/network";

import { phoneValidationFailed } from "../SetUserData.constants";
import { IUserDialogOptions, PHONE_VALIDATION_DIALOGS } from "../SetUserData.types";

export interface IRequestResult
  extends IDialogInfo<PHONE_VALIDATION_DIALOGS | COMMON_REQUEST_DIALOGS, IUserDialogOptions> {
  isSuccess: boolean;
}

type useRequestType = { validatePhone: () => Promise<IRequestResult> };

// /kyc/mobile/verification
export const useVerifyPhone = (): useRequestType => {
  const verifyUserMobileRequest = (): Promise<AxiosResponse<IKYCVerificationResponse>> => {
    return network.request<IKYCVerificationResponse>("connect", "kyc/mobile/verification", {
      withCredentials: process.env.REACT_APP_WITH_MOCK_SERVER !== "true",
      method: "POST",
    });
  };

  const validatePhone = async (): Promise<IRequestResult> => {
    const { data, status } = await verifyUserMobileRequest();

    if (status === HTTP_OK) {
      switch (data.result) {
      case KYC_VALIDATION_STATUSES.SUCCESS:
        return { isSuccess: true };

      case KYC_VALIDATION_STATUSES.FAILURE:
        return {
          isSuccess: false,
          dialog: phoneValidationFailed,
          dialogType: PHONE_VALIDATION_DIALOGS.WRONG_ATTEMPT,
          dialogOptions: {
            attempts: data.attempts,
          },
        };

      case KYC_VALIDATION_STATUSES.TEMP_BLOCKED:
        return {
          isSuccess: false,
          dialog: dialogContents.userLockedByRegAttempts,
          dialogType: PHONE_VALIDATION_DIALOGS.UNVERIFIED_PHONE_BLOCKED,
          isBlockingPopup: true,
          dialogOptions: {
            blockedUntil: data.blockedUntil,
          },
        };

      default:
        return {
          isSuccess: false,
          dialogType: COMMON_REQUEST_DIALOGS.SOMETHING_WENT_WRONG,
          dialog: dialogContents.somethingWentWrong,
        };
      }
    }

    return {
      isSuccess: false,
      dialogType: COMMON_REQUEST_DIALOGS.SOMETHING_WENT_WRONG,
      dialog: dialogContents.somethingWentWrong,
    };
  };

  return { validatePhone };
};
