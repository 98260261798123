import { IVerifyOTPResponse } from "@appTypes/responses";
import { IAxiosResponseWithError, network } from "@utils/network/network";

type useVerifyOTPReturnType = [
  verifyOTPCode: (
    otpCode: string,
    checkoutToken?: string,
    reqType?: string,
    orderId?: string,
  ) => Promise<IAxiosResponseWithError<IVerifyOTPResponse>>,
];

export const useVerifyOTP = (): useVerifyOTPReturnType => {
  const verifyOTPCode = (
    otpCode: string,
    checkoutToken?: string,
    reqType?: string,
    orderId?: string,
  ): Promise<IAxiosResponseWithError<IVerifyOTPResponse>> => {
    return network.request<IVerifyOTPResponse>("connect", "otp/verify", {
      method: "POST",
      data: { otpCode },
      params: {
        checkoutToken,
        orderId,
        type: reqType ?? "LGN",
      },
    });
  };

  return [verifyOTPCode];
};
