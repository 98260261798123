import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { STORAGE_DEFAULT_CONTRACT } from "@constants/sessionStorageDefaults";
import { CONTRACT_DATA_KEY } from "@constants/sessionStorageKeys";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { E_PROMISSORY_NOTE_STATE } from "@utils/network/contractInfo/types";

import { CONTRACT_STEPS } from "../constants/common";

export const useSteps = (): string[] => {
  const { t, i18n } = useTranslation();
  const [contractData] = useSessionStorageState(CONTRACT_DATA_KEY, STORAGE_DEFAULT_CONTRACT);
  const translatedSteps = useMemo(() => CONTRACT_STEPS.map((step: string) => t(step)), [i18n.language]);
  const ePromissoryIsNeeded = contractData.ePromissoryNoteState !== E_PROMISSORY_NOTE_STATE.NOT_NEED;
  const filteredSteps = translatedSteps.filter((step, i) => {
    if (!ePromissoryIsNeeded && i === 2) {
      return false;
    }
    return true;
  });

  return filteredSteps;
};
