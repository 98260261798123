import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { Button, COLORS, PinInput, Typography } from "@ui-kit";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IDialogContents } from "@appTypes/dialogs";
import { IVerifyOTPResponse, IOTPInfoResponse } from "@appTypes/responses";
import { IKYCVerificationResponse } from "@appTypes/responses";
import { ResendButton } from "@components/ResendButton";
import { TextWithEmail } from "@components/TextWithEmail";
import { SUPPORT_EMAIL } from "@constants/common";
import * as httpCodes from "@constants/httpStatuses";
import { dialogContents } from "@constants/popups";
import {
  DEFAULT_USER_REGISTRATION_INFO,
  DEFAULT_USER_INFO,
  BOX_BACKGROUND_COLOR_DEFAULT_CONTRACT,
} from "@constants/sessionStorageDefaults";
import { BOX_BACKGROUND_COLOR, RESEND_OTP_TIMER_KEY } from "@constants/sessionStorageKeys";
import { REGISTRATION_KEY, USER_INFO_KEY } from "@constants/sessionStorageKeys";
import { menuService } from "@store/menu";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { IAxiosResponseWithError } from "@utils/network/network";
import { encryptMobilePhoneNumber } from "@utils/services/Encryption/Encryption.service";
import { hasObjectValues } from "@utils/services/Objects/Objects.service";
import { COUNTDOWN_TIMES, getTimeRemaining } from "@utils/services/Timer/Timer.service";
import { ITimeObject } from "@utils/services/Timer/timer.types";

import { BlockedAccountInfo } from "./components/BlockedAccountInfo";
import { ResendCode } from "./components/ResendCode";

export const ACCOUNT_IS_LOCKED = "ACCOUNT_IS_LOCKED";

interface ISetOTP {
  isKYC?: boolean;
  title: string;
  subtitle: string;
  pinLength: number;
  codeStatusData?: IVerifyOTPResponse | IOTPInfoResponse | IKYCVerificationResponse;
  resendClickTime?: string;
  onGoBack?: () => void;
  handleResendOTP?: (phone?: string, nationalId?: string) => Promise<IAxiosResponseWithError<IOTPInfoResponse>>;
  handleResendKYC?(): Promise<{ isResendSuccess?: boolean; requestAt?: string } | void>;
  handleContinue: (
    otpInputValue: string,
    setInputError: React.Dispatch<React.SetStateAction<boolean>>,
    setOtpMessage: React.Dispatch<React.SetStateAction<string>>,
    handleOtpResponse: (data: IVerifyOTPResponse | void) => boolean,
  ) => void;
  isOnForgotPassword?: boolean;
  dialogMethods: {
    openUnexpectedErrorDialog: () => void;
    setCurrentDialogContent: React.Dispatch<React.SetStateAction<IDialogContents | undefined>>;
    openDialog: () => void;
    setDialogType: React.Dispatch<React.SetStateAction<string>>;
  };
}

export const SetOTPComponent: React.FC<ISetOTP> = ({
  title,
  subtitle,
  pinLength,
  handleContinue,
  handleResendOTP,
  handleResendKYC,
  onGoBack,
  dialogMethods,
  codeStatusData,
  isOnForgotPassword = false,
  isKYC = false,
  resendClickTime,
}) => {
  const { openUnexpectedErrorDialog, setCurrentDialogContent, openDialog, setDialogType } = dialogMethods;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [inputError, setInputError] = useState(false);
  const [otpInputValue, setOtpInputValue] = useState<Array<number | undefined>>(new Array(pinLength));
  const [resendTimer, setResendTimer] = useState<ITimeObject<number> | null>(null);
  const [blockedTimer, setBlockedTimer] = useState<ITimeObject<number> | null>(null);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [otpMessage, setOtpMessage] = useState("");
  const [storageResendTimerDateValue, setResendTimerDateToStorage] = useSessionStorageState(RESEND_OTP_TIMER_KEY, {
    resendClickDate: "",
  });
  const [registrationData] = useSessionStorageState(REGISTRATION_KEY, DEFAULT_USER_REGISTRATION_INFO);
  const [userInfo] = useSessionStorageState(USER_INFO_KEY, DEFAULT_USER_INFO);
  const [, setBoxBackgroundColor] = useSessionStorageState(BOX_BACKGROUND_COLOR, BOX_BACKGROUND_COLOR_DEFAULT_CONTRACT);

  useEffect(() => {
    setBoxBackgroundColor({ withColor: true });
    const resendClickDate = new Date(resendClickTime || storageResendTimerDateValue?.resendClickDate);

    if (getTimeRemaining(resendClickDate, COUNTDOWN_TIMES["1min"], false)) {
      setTimer(COUNTDOWN_TIMES["1min"], resendClickDate, true);
    }
  }, [resendClickTime]);

  useEffect(() => {
    if (codeStatusData) {
      const resendClickDate = new Date(
        (codeStatusData as IOTPInfoResponse).otpGeneratedAt
          ? (codeStatusData as IOTPInfoResponse).otpGeneratedAt
          : (codeStatusData as IKYCVerificationResponse).requestAt || "",
      );
      setResendTimer(getTimeRemaining(resendClickDate, COUNTDOWN_TIMES["1min"], false));
    }
  }, [codeStatusData]);

  const handleOtpInputChange = (newPin: Array<number | undefined>) => {
    setOtpInputValue(newPin);
    setInputError(false);
    setOtpMessage("");
  };

  useEffect(() => {
    const sendCode = async () => {
      await handleContinue(otpInputValue.join(""), setInputError, setOtpMessage, handleOtpResponse);
    };

    if (otpInputValue.join("").length === pinLength) {
      sendCode();
    }
  }, [otpInputValue]);

  const setTimer = (countdown: number, generatedOTPDate: Date, resendTimer = false) => {
    const timeRemaining = getTimeRemaining(generatedOTPDate, countdown, false);

    if (timeRemaining) {
      resendTimer
        ? setResendTimer({ minutes: timeRemaining.minutes, seconds: timeRemaining.seconds, hours: timeRemaining.hours })
        : setBlockedTimer({
          minutes: timeRemaining.minutes,
          seconds: timeRemaining.seconds,
          hours: timeRemaining.hours,
        });
    }
  };

  useEffect(() => {
    if (codeStatusData) {
      if (!isKYC) {
        handleOtpResponse(codeStatusData as IVerifyOTPResponse);
      }
    }
  }, [codeStatusData]);

  const handleOtpResponse = (data: IVerifyOTPResponse | void) => {
    if (!hasObjectValues(data) || !data) {
      return false;
    }

    const attempts = data?.attempts;
    const generatedOTPDate = new Date(data.otpGeneratedAt);

    if (attempts >= 4) {
      setOtpMessage("errors.OTP_ATTEMPTS_EXCEEDED");
      setInputError(true);
      setIsInputDisabled(true);

      switch (attempts) {
      case 4:
        setTimer(COUNTDOWN_TIMES["10min"], generatedOTPDate);
        break;
      case 5:
        setTimer(COUNTDOWN_TIMES["15min"], generatedOTPDate);
        break;
      case 6:
        setTimer(COUNTDOWN_TIMES["30min"], generatedOTPDate);
        break;
      case 7:
        setDialogType(ACCOUNT_IS_LOCKED);
        setCurrentDialogContent({
          ...dialogContents.accountLocked,
          description: <TextWithEmail textKey={dialogContents.accountLocked.description} email={SUPPORT_EMAIL} />,
        });
        openDialog();
        break;
      }

      return false;
    }

    return true;
  };

  const handleResendClick = async () => {
    try {
      if (handleResendOTP) {
        setResendTimer({ minutes: 1, seconds: 0, hours: 0 });
        const { data, status } = isOnForgotPassword
          ? await handleResendOTP(
              registrationData.form["MOBILE_PHONE"] as string,
              registrationData.form["NATIONAL_ID"] as string,
          )
          : await handleResendOTP();

        switch (status) {
        case httpCodes.OK:
          if (!hasObjectValues(data)) {
            return;
          }
          setOtpMessage("pageContent.newCodeSent");
          setTimer(COUNTDOWN_TIMES["1min"], new Date(data.otpGeneratedAt), true);
          await setResendTimerDateToStorage({ resendClickDate: data.otpGeneratedAt.toString() });
          break;
        default:
          openUnexpectedErrorDialog();
        }
      } else if (handleResendKYC) {
        const resendResult = await handleResendKYC();
        const generatingTime = new Date();

        if (resendResult?.isResendSuccess) {
          setOtpMessage("pageContent.newCodeSent");
          setTimer(COUNTDOWN_TIMES["1min"], generatingTime, true);
        }
      }
    } catch (error) {
      openUnexpectedErrorDialog();
    }
  };

  useEffect(() => {
    menuService.setMenuData({ leftSide: title, rightSide: "", needIcon: false });
  }, []);

  return (
    <Box>
      {!isMobile && (
        <Typography
          variant="p1"
          fontWeight={theme.direction === "rtl" ? 600 : "bold"}
          textAlign={{ xs: "center", sm: theme.direction === "rtl" ? "right" : "left" }}
          component="h1"
        >
          {t(title)}
        </Typography>
      )}
      <Box sx={{ marginTop: "8px" }}>
        <Typography
          variant="p3"
          component="p"
          textAlign={{ xs: "center", sm: theme.direction === "rtl" ? "right" : "left" }}
        >
          {subtitle}{" "}
          {!isKYC && (
            <span style={{ unicodeBidi: "plaintext" }}>
              {(userInfo.phoneNumber && encryptMobilePhoneNumber(userInfo.phoneNumber as string)) || ""}
            </span>
          )}
        </Typography>
      </Box>
      <Grid
        container
        columnSpacing={5}
        paddingY={"40px"}
        width={"100%"}
        marginLeft={"0"}
      >
        <Grid item xs={12} paddingLeft={{ xs: "0!important", sm: "40px" }}>
          <Box
            marginBottom={{ xs: "16px", sm: "24px" }}
            justifyContent={{ xs: "center", sm: "flex-start" }}
            display={"flex"}
          >
            <PinInput
              onPinChanged={handleOtpInputChange}
              pin={otpInputValue}
              pinLength={pinLength}
              error={inputError}
              disabled={isInputDisabled}
            />
          </Box>
          {otpMessage === "pageContent.newCodeSent" && (
            <Typography
              variant="p3"
              justifyContent={{ sm: "center", md: theme.direction === "rtl" ? "flex-start" : "flex-end" }}
              color={COLORS.DARK_POSITIVE}
              fontWeight={theme.direction === "rtl" ? 600 : "bold"}
              marginBottom={{ sm: "8px", md: "16px" }}
            >
              {t(otpMessage)}{" "}
            </Typography>
          )}
          {otpMessage === "errors.WRONG_OTP" && (
            <Box textAlign={{ xs: "center", sm: theme.direction === "rtl" ? "right" : "left" }}>
              <Typography
                variant="p3"
                color={COLORS.DARK_NEGATIVE}
                fontWeight={theme.direction === "rtl" ? 600 : "bold"}
                marginBottom={{ xs: "8px", sm: "16px" }}
              >
                {t(otpMessage)}{" "}
              </Typography>
              <ResendButton onClick={handleResendClick}>
                <Typography variant="p3">{t("buttons.resend")}</Typography>
              </ResendButton>
            </Box>
          )}
          {blockedTimer?.hours || blockedTimer?.minutes || blockedTimer?.seconds ? (
            <BlockedAccountInfo
              otpMessage={t(otpMessage)}
              blockedTimer={blockedTimer}
              setBlockedTimer={setBlockedTimer}
              setIsInputDisabled={setIsInputDisabled}
              setInputError={setInputError}
              isMobile={isMobile}
            />
          ) : (
            otpMessage !== "errors.WRONG_OTP" && (
              <ResendCode
                resendTimer={resendTimer}
                setResendTimer={setResendTimer}
                handleResendClick={handleResendClick}
              />
            )
          )}
        </Grid>
      </Grid>
      {onGoBack && (
        <Grid container justifyContent={{ xs: "center", sm: theme.direction === "rtl" ? "flex-end" : "flex-start" }}>
          <Button
            color={!isMobile ? "secondary" : "inherit"}
            tertiary={isMobile}
            sx={{
              padding: "14px 22px",
              minWidth: { sm: "240px" },
            }}
            onClick={onGoBack}
          >
            {t("buttons.goBack") as string}
          </Button>
        </Grid>
      )}
    </Box>
  );
};
