import { getEncryptedValue, saveEncryptedValue } from "./hooks/useSessionStorageState";

export const setExpireTokenTimer = (newToken: string, initExpirationDate?: string): string => {
  const expirationDate = getEncryptedValue("tokenExpire")?.toString() || "";
  const oldToken = JSON.parse(getEncryptedValue("merchantData")?.toString() || "").checkoutToken;
  let expDate: string;
  if (newToken !== oldToken || expirationDate == "") {
    expDate = initExpirationDate || new Date().setMinutes(new Date().getMinutes() + 180).toString() || "";
  } else {
    expDate = expirationDate || "";
  }
  saveEncryptedValue("tokenExpire", expDate);

  return expDate;
};
