const NO_TRANSLATION = "no translation";

export const TRANSLATIONS_ARAB = {
  hello: "أهلا يا صديقي",
  additionalPart: "جزء إضافي",
  loading: "...Loading",
  tokenMessage: "هذا العرض سينتهي في خلال:",
  error404Header: "عفواً!",
  error404: "خطأ ٤٠٤. للاسف الصفحه التي تبحث عنها غير موجوده. ",
  acceptTerms: " أؤكد أنني قرأت وأوافق علي ",
  acceptTermsBtn: "الشروط والأحكام.",

  units: {
    month: "شهر",
    month_one: "شهر",
    month_other: "شهور",
    trial: "ﻤﺤﺎولة",
    trial_one: "ﻤﺤﺎولة",
    trial_other: "ﻣﺤﺎوﻟة",
  },
  currency: {
    SAR: "ريال",
  },
  buttons: {
    logIn: "تسجيل دخول",
    logInSingle: "تسجيل دخول",
    continue: "استمرار",
    goBack: "رجوع",
    ok: NO_TRANSLATION,
    createAccount: "إنشاء حساب",
    resend: "أعد الإرسال",
    exit: "خروج",
    tryAgain: "اﻋﺪ اﻟﻤﺤﺎوﻟﻪ",
    cancel: "رجوع", // Translation - back
    yesCancel: "نعم",
    no: "لا",
    yes: "نعم",
    proceed: "استكمال",
    backToStore: "اﻟﺮﺟﻮع إلى اﻟﻤﺘﺠﺮ",
    goToStore: "رﺟﻮع اﻟﻰ اﻟﻤﺘﺠﺮ",
    downloadApp: "تحميل تطبيق كوارا للتمويل",
    continueHere: "استمرار هنا",
    continueWithApp: "اﺳﺘﻤﺮاﺭ ﺑﺎﻟﺘﻄﺒﻴﻖ",
    goToTheApp: "تابع الى التطبيق",
    getStarted: "لنبدأ",
    goToContract: "اذهب الى العقد",
    reject: "رفض",
    sign: "توقيع",
    updateDOB: "ﺗﺤﺪﻳﺚ ﺗﺎرﻳﺦ اﻟﻤﻴﻠﺎد",
    callBack: "اتصل مرة أخرى",
    selectSector: "اختر القطاع",
    back: "رجوع",
    accept: "قبول",
    cancelDeletion: "إلغاء الحذف",
    addMoreLine: "+ اضافة قريب",
    removeLine: "- حذف",
    yesDelete: "نعم، حذف",
    noLeaveIt: "لا ، اتركه",
  },
  labels: {
    passwordRequire: "الرقم السري يجب ان يحتوي علي:",
    password: "الرقم السري",
    passwordRepeat: "تأكيد الرقم السري ",
    email: "البريد الإلكتروني",
    mobile: "رقم الهاتف",
    nationalIdOrIQAMA: "الإقامة أو رقم الهوية",
    birthDate: "تاريخ الميلاد",
    recoveryCode: NO_TRANSLATION,
    retryIn: "أعد المحاولة ﺑﻌﺪ",
    passwordStrength: {
      Low: "ضعيف",
      Medium: "ﻢﺗﻮﺴﻃ",
      Great: "قوي",
    },
    comingSoon: "قريبا",
    accommodationType: "نوع الإقامة",
    chooseAccommodation: "اختر نوع الاقامه",
    dependentsNumber: "عدد المعالين",
    monthlyExpenses: "المصاريف الشهرية",
    ownHouse: "مالك",
    rental: "مستأجر",
    setBirthday: "ﺣﺪد ﺗﺎرﻳﺦ اﻟﻤﻴﻠﺎد",
    remaining: "ﻣﺘﺒﻘﻲ",
    selectSector: "سأكون مسؤولاً عن سداد المدفوعات",
    monthlyIncome: "اجمالي المصاريف الشهرية",
    monthlyIncomeDescription: "يجب أن يكون الحد الأدنى للدخل الشهري 4000 ريال سعودي",
    monthlyRent: "الإيجار الشهري",
    householdWage: "العمالة المنزلية",
    utilityExpense: "النفقات العامة",
    insurancePremium: "التأمين",
    transportExpenses: "مصاريف النقل",
    healthcareExpenses: "المصاريف الصحية",
    communicationExpenses: "مصاريف الاتصال",
    foodAndLivingExpenses: "مصاريف الغذاء",
    expatDependentGovFees: 'الرسوم الحكومية "لغير السعودين"',
    educationSuppliesExpense: "مصاريف التعليم",
    monthlyRemittance: "الحوالات الشهرية",
    monthlyRemittanceDescription: "(من أجل إعالة العائلة)",
    otherMonthlyExpenses: "مصاريف أخرى",
    otherMonthlyExpensesDescription: "(بما في ذلك المتوقع في المستقبل)",
    MonthlyObligations: "التزامات اخري",
    numberOfDependants: "عدد المعالين",
    publicSchoolDependants: "عدد المعالين ف المدارس العامة",
    privateSchoolDependants: "عدد المعالين في المدارس الخاصة",
    numberOfHouseholdHelp: "عدد المساعدة المنزلية",
    numberOfAllDependants: "عدد جميع المعالين (العدد يجب أن يجمع جميع المعالين أعلاه وأيضاً ما لم يذكروا)",
    numberOfAllDependantsDescription: "(يجب أن يشمل الرقم جميع المعالين أعلاه والذين لم يذكروا هناك.)",
    resonsibleTitle: "سأكون مسؤولاً عن سداد المدفوعات",
    nameOfResponsiblePerson: "اسم الشخص المسؤول عن المدفوعات",
    breadwinnerTitle: "هل أنت المعيل الوحيد للأسرة؟",
    rdef: "هل تستخدم صندوق التنمية العقارية",
    enterNameOfResponsiblePerson: "أدخل اسم الشخص المسؤول",
    homeOwnerTitle: "هل انت صاحب البيت؟",
    politicalPersonTitle: "هل أنت شخص سياسي؟",
    phoneNumberAbsher: "يرجى كتابة نفس الرقم المسجل في أبشر",
    quaraFinanceEmployee: "هل أنت موظف بكوارا للتمويل؟",
    quaraFinanceRelative: "هل لديك أقرباء بكوارا للتمويل؟",
    relative: "القريب",
    relativeNumbers: {
      1: "الاول",
      2: "الثاني",
      3: "الثالث",
      4: "الرابع",
      5: "الخامس",
    },
    relativeID: "هوية القريب",
    relativeName: "اسم القريب",
    relationshipPosition: "وظيفة القريب",
    Relationship: "صلة القرابة",
    placeholderRelativeID: "أدخل هوية القريب",
    placeholderRelativeName: "أدخل اسم القريب",
    placeholderRelationshipPosition: "أدخل وظيفة القريب",
    placeholderRelationship: "أدخل صلة القرابة",
  },
  placeholders: {
    email: "البريد الإلكتروني",
    mobileNumber: "رقم الهاتف",
    nationalIdOrIQAMA: "الإقامة أو رقم الهوية",
    birthDate: "ﺗﺎرﻳﺦ اﻟﻤﻴﻠﺎد",
    password: "اﻟﺮﻗﻢ اﻟﺴﺮي",
  },
  additionalInfo: {
    didntGetCode: NO_TRANSLATION,
    forgotPassword: "ﻫﻞ ﻧﺴﻴﺖ اﻟﺮﻗﻢ اﻟﺴﺮي؟",
  },
  errors: {
    NATIONAL_ID_NOT_VALID_ERROR: "اﻟﺮﻗﻢ ﺧﺎﻃﺊ. ﻣﻦ ﻓﻀﻠﻚ اﻋﺪ ادﺧﺎﻟﻪ ﺑﻄﺮﻳﻘﻪ ﺻﺤﻴﺤﻪ",
    NATIONAL_ID_IS_MANDATORY_ERROR: "ﻳﺠﺐ ﻋﻠﻴﻚ ادﺧﺎل ﻫﺬا اﻟﺮﻗﻢ",
    WRONG_PASSWORD_ERROR: "اﻟﺮﻗﻢ ﺧﺎﻃﺊ. ﻣﻦ ﻓﻀﻠﻚ اﻋﺪ ادﺧﺎﻟﻪ ﺑﻄﺮﻳﻘﻪ ﺻﺤﻴﺤﻪ",
    EMAIL_NOT_VALID_ERROR: "هذا البريد غير صحيح",
    UPPERCASE_one: "ﻋﻠﻰ اﻟﺎﻗﻞ ﺣﺮف ﻛﺒﻴﺮ",
    UPPERCASE_two: "على الاقل حرفين كبيرين",
    LOWERCASE_one: "على الاقل حرف صغير",
    LOWERCASE_two: "على الاقل حرفين صغيرين",
    NUMBER: "ﻋﻠﻲ اﻟﺎﻗﻞ رﻗﻢ",
    SPECIAL_SYMBOL: `على الاقل رمز واحد \n )~! @ # $% ^ & * _- + = '| \\ () {} []: "" <>,.? /(`,
    SYMBOLS: " على الاقل {{ numberOfSigns }} حروف ",
    PHONE_NOT_VALID_ERROR: "ﻣﻦ ﻓﻀﻠﻚ أدﺧﻞ ﻧﻔﺲ رﻗﻢ اﻟﻤﻮﺑﻴﻞ اﻟﻤﺴﺠﻞ ﻓﻲ أﺑﺸﺮ",
    BIRTH_DATE_UNDERAGE_ERROR: "ﺗﺎرﻳﺦ ﻏﻴﺮ ﺻﺤﻴﺢ. ﻣﺴﺘﺨﺪم اﻟﺘﻄﺒﻴﻖ ﻳﺠﺐ أن ﻳﻜﻮن ﺳﻨﻪ ٢١ ﻋﺎﻣﺎً ﻋﻠﻲ الأﻗﻞ",
    CODE_IS_INVALID: NO_TRANSLATION,
    OTP_ATTEMPTS_EXCEEDED24: "لقد تجاوزت المحاولات المسموح بها. من فضلك انتظر قبل المحاوله التاليه ",
    OTP_ATTEMPTS_EXCEEDED: "ﻟﻘﺪ ﺗﺠﺎوزت اﻟﻤﺤﺎوﻟﺎت اﻟﻤﺴﻤﻮح ﺑﻬﺎ",
    ACCOUNT_LOCKED_ATTEMPTS_EXCEEDED: NO_TRANSLATION,
    WRONG_OTP: "اﻟﺮﻗﻢ ﺧﺎﻃﺊ.",
    LOGIN_ATTEMPTS_EXCEEDED: "ﻳﺠﺐ اﻟﺎﻧﺘﻈﺎر ٥ دﻗﺎﺋﻖ ﺑﺴﺒﺐ ﻛﺜﺮه اﻟﻤﺤﺎوﻟﺎت اﻟﺨﺎﻃﺌﻪ",
    USER_IS_BLOCKED: "لقد تم تجميد حسابك بسبب عدة محاولات خاطئة لكلمة المرور",
    PASSWORD_DONT_MATCH: "الرقم السري غير متطابق",
    PASSWORD_DONT_MATCH_LOWEST_CRITERIA: "اﻟﺮﻗﻢ اﻟﺴﺮي ﻟﺎ ﻳﻮاﻓﻖ اﻗﻞ اﻟﺸﺮﻮﻃ",
    DATE_OF_BIRTH_IS_INCORRECT: "ﻣﻦ ﻓﻀﻠﻚ أدﺧﻞ ﺗﺎرﻳﺦ اﻟﻤﻴﻠﺎد ﺑﺸﻜﻞ ﺻﺤﻴﺢ",
    Minmum_monthly_income: "يجب أن يكون الحد الأدنى للدخل الشهري 4000 ريال سعودي",
  },
  pageHeader: {
    createAccount: {
      create: "إنشاء",
      account: "حساب",
    },
    login: {
      login: "تسجيل دخول",
      account: "",
    },
    unblockAccount: {
      verify: "تحقق من حسابك",
    },
    employmentSector: `اختر قطاع التوظيف`,
    setupPassword: "انشاء الرقم السرى",
    forgotPassword: "نسيت الرقم السري",
    verifyNewPassword: "أدﺧﻞ رﻣﺰ اﻟﺘﺤﻘﻖ",
    codeVerification: "أدﺧﻞ رﻣﺰ اﻟﺘﺤﻘﻖ",
    employmentDetails: "ادخل البيانات الوظيفيه",
    offerDetails: "عرضنا لك من أجل تقسيط مبلغ",
    offerDetailsMobile: "ﻋﺮوﺿﻨﺎ ﻣﻦ اﺟﻠﻚ ﻟﺘﻘﺴﻴﻂ",
    otpVerification: "أدﺧﻞ رﻣﺰ اﻟﺘﺤﻘﻖ",
  },
  pageContent: {
    dontHaveAccount: "ليس لديك حساب؟",
    alreadyHaveAccount: "هل لديك حساب بالفعل؟",
    codeSentTo: "الرجاء إدخال الرمز الذي تم إرساله إليك من قبل أبشر إلى هاتفك المسجّل لدى الحكومة.",
    codeSentToNumber: "ﻣﻦ ﻓﻀﻠﻚ أدﺧﻞ الرمز السري المرسل  إلى",
    newPasswordCodeSentToNumber: "ﻣﻦ ﻓﻀﻠﻚ أدﺧﻞ الرمز السري المرسل  إلى",
    didntReceiveCode: "ﻟﻢ ﺗﺴﺘﻘﺒﻞ الرمز اﻟﺴري؟",
    resendCodeIn: "اعد الارسال خلال",
    privateSector: "القطاع الخاص",
    governmentSector: "القطاع الحكومي",
    verifyDataToResetPassword: "تحقق من بياناتك الشخصيه لانشاء رقم سري جديد",
    newCodeSent: "تم ارسال رمز جديد",
    otpVerification: "من فضلك أدخل الرقم السريّ المٌرسل إلى",
    offerDetails: {
      firstInstallment: "القسط الاول",
      nextInstallment: "الاقساط التاليه",
      lastInstallment: "القسط الاخير",
      financeAmount: "القيمه الماليه",
    },
    offerPayments: {
      chooseOffer: "اختر كيف تريد الدفع",
      offerLabel: "قسط مشترياتك بدفعات ثابته بقيمه {{cost}} ريال شهريا",
      notEligibleForOffer: "نأسف هذا البرنامج غير متاح لك حاليالاختياره",
      onlyMonthly: " ﻒﻘﻃ ﺶﻫﺮﻳ",
      select3_6monthsLabel: "دﻓﻌﺎت ﻣﺘﺴﺎوﻳﻪ ﻗﺴﻂ ﻣﺸﺘﺮﻳﺎﺗﻚ ﻋﻠﻰ ٣ اﻟﻰ ٦",
      select3_6monthsTemporaryLabel: "ﻏﻴﺮ ﻣﺘﺎح",
      comingSoon: "قريبا...",
      select3_6monthsHeader: "سدد على ٣-٦",
      payIn: "دﻓﻌﺎت",
      noEligiblePrograms: "للاسف لم تتوافق مع اي برنامج متاح لدي كوارا حاليا",
      contactToSupport: "للاسف لم تتوافق مع اي برنامج متاح لدي كوارا حاليا <0>{{email}}</0>",
    },
    relationship: {
      Grandparent: "الجد",
      Parent: "الاب",
      Sibling: "الاخ",
      Cousin: "ابن العم",
      Spouse: "الزوج",
      Offspring: "قريب",
    },
  },
  dialogTitle: {
    threeDSecureVerification: "سيتم التحقق من الكارت باستخدام طريقه الامان ثلاثيه الابعاد",
    accountLocked: "ﺗﻢ اﻏﻠﺎق اﻟﺤﺴﺎب ﻣﺆﻗﺘﺎ",
    userAlreadyRegistered: "هذا المستخدم مسجل مسبقاً",
    userAlreadyRegistered_deprecated: "هذا المستخدم مسجل بالفعل",
    unableToVerify: "ﻟﺴﻮء اﻟﺤﻆ ﻣﻦ ﻟﻢ ﻧﺴﺘﻄﻊ اﻟﺘﺤﻘﻖ ﻫﻮﻳﺘﻚ",
    somethingWentWrong: "ﺣﺪث ﺷﺊ ﺧﻄﺄ",
    privateSectorVerificationFailed: "فشل التحقق من القطاع الخاص ",
    governmentSectorVerificationFailed: "ﻓﺸﻞ اﻟﺘﺤﻘﻖ ﻣﻦ اﻟﻘﻄﺎع اﻟﺤﻜﻮﻣﻲ",
    incorrectData: "بيانات غير صحيحه",
    identityVerifying: NO_TRANSLATION,
    success: "نجاح!",
    unableVerifyCardDetails: NO_TRANSLATION,
    incorrectCardDetails: "بيانات الكارت غير صحيحه",
    balanceInsufficient: "رصيد الكارت غير كافي",
    confirmCancelQuestion: "هل أنت متأكد من الإلغاء؟",
    loanOfferExpired: "نأسف، انتهت صلاحية العرض",
    couldNotProvideOffer: `للاسف لم نستطع توفير قرض لك`,
    couldNotProvideOfferPrivate: "ﻓﺸﻞ اﻟﺘﺤﻘﻖ ﻣﻦ اﻟﻘﻄﺎع اﻟﺨﺎص",
    couldNotProvideOfferGovernment: "ﻓﺸﻞ اﻟﺘﺤﻘﻖ ﻣﻦ اﻟﻘﻄﺎع الحكومي",
    didntMeetFinanceCreditCriteria: "اﻟﻤﺘﻄﻠﺒﺎت اﻟﻤﺎﻟﻴﻪ ﻟﻜﻮاﺭﺍ ﻟﻠﺎﺳﻒ ﺑﻴﺎﻧﺎﺗﻚ ﻟﺎ ﺗﺘﻮاﻓﻖ ﻣﻊ",
    pleaseDownloadApp: "من فضلك قم بتحميل التطبيق",
    rejectContract: "هل انت متأكد انك تريد رفض العقد؟",
    srsCheckHaveNotPassed: "نأسف! بياناتك المالية لا تتوافق مع متطلبات كوارا للتمويل",
    wrongDOB: "من فضلك أدخل تاريخ الميلاد الصحيح طبقاً للمعلومات التالية",
    successfulCallbackRequest: "تم إرسال الطلب بنجاح!",
    underDeletation: "هذا الحساب تحت طلب الإلغاء",
    removeRelative: "هل تريد حذف هذا القريب؟",
    finalLoanSammary: "ملخص القرض النهائي",
    accountDeactivated: "تم تعطيل الحساب",
    accountActivated: "تم تفعيل الحساب",
  },
  dialogContent: {
    dataDoesntExist: "ﻫﺬه اﻟﺒﻴﺎﻧﺎت ﻏﻴﺮ ﻣﻮﺟﻮده. ﻣﻦ ﻓﻀﻠﻚ اﺿﻐﻂ رﺟﻮع وﺣﺎول ﻣﺮه اﺧﺮي",
    accountLocked: " ﻟﺘﻔﻌﻴﻞاﻟﺤﺴﺎب، ﻣﻦ ﻓﻀﻠﻚﺗﻮاﺻﻞﻣﻌﻨﺎ ﻋﻠﻲ <0>{{email}}</0>",
    checkProvidedData:
      "ﻣﻦ ﻓﻀﻠﻚ ﺗﺄﻛﺪ ﻣﻦ اﻟﺒﻴﺎﻧﺎت اﻟﻤﺪﺧﻠﻪ اﻧﻬﺎ ﻣﺘﻮاﻓﻘﻪ ﻣﻊ اﻟﺒﻴﺎﻧﺎت اﻟﺤﻜﻮﻣﻴﻪ\n" +
      "<0>{{ email }}</0> و ﻳﻤﻜﻨﻚ اﻟﺘﻮاﺻﻞ ﻣﻌﻨﺎ ﻋلى ",
    requestWasntProcessed: "ﻣﻦ ﻓﻀﻠﻚ اﻋﺪ اﻟﻤﺤﺎوﻟﻪ ﺑﺴﺒﺐ ﺣﺪث ﻏﻴﺮ ﻣﺘﻮﻗﻊ ﻃﻠﺒﻚ ﻟﻢ ﻳﺘﻢ ﺗﻨﻔﻴﺬه.",
    contactForMoreOptions: `اذا كنت تريد معرفه خيارات اكثر, من فضلك تواصل معنا علي \n<0>{{ email }}</0>`,
    cardAddSuccess: "تم اضافه بيانات الكارت بنجاح",
    unableProcessRequest: "ﻣﻦ ﻓﻀﻠﻚ اﻋﺪ اﻟﻤﺤﺎوﻟﻪ ﺑﺴﺒﺐ ﺣﺪث ﻏﻴﺮ ﻣﺘﻮﻗﻊ ﻃﻠﺒﻚ ﻟﻢ ﻳﺘﻢ ﺗﻨﻔﻴﺬه.",
    cardDetailsDoesntMatch: "بيانات الكارت لا تتوافق. من فضلك تأكد من سلامه البيانات واعد المحاوله.",
    cancelCardDetails: "اذا تريد الغاء العرض من فضلك اضغط نعم.",
    unableDueInsufficientBalance:
      "لم تستطع اجراء المعامله نظرا لعدم كفايه رصيد الكارت.من فضلك تأكد من وجود رصيد كافي فى الكارت واعد المحاوله.",
    cancelOffer: 'اضغط على "نعم." للتأكيد.',
    offerExpired: "من فضلك قم بالعودة إلى المتجر وسجّل مرة أخرى ليصلك عرض جديد",
    exitOrContact: `ُيمكنك تسجل خروج أو التواصل معنا على \n<0>{{ email }}</0>`,
    youWillBeRedirectedToTheStore: `ﺳﻮف ﻳﺘﻢ ﺗﺤﻮﻳﻠﻚ اﻟﻰ اﻟﻤﺘﺠﺮ ﺧﻠﺎل \n <0>{{timer}}</0> اﻭ اﺿﻐﻂ ﺧﺮﻮﺟ ﻟﺘﺮﺟﻊ ﺣﺎﻟﺎ`,
    proceedWithExistingLogin: `من فضلك قم بتسجيل الدخول باستخدامه`,
    selectPlatform: "اختر تطبيق جوالك لإتمام طلبك",
    downloadFromStore: "اﺿﻐﻂ ﻋﻠﻰ ﺗﺤﻤﻴﻞ اﻟﺘﻄﺒﻴﻖ ﻟﺘﺬﻫﺐ اﻟﻰ اﻟﻤﺘﺠﺮ وﺗﻘﻮم ﺑﺘﻨﺰﻳﻠﻪ و ﺗﺤﺼﻞ ﻋﻠﻰ ﻃﻠﺒﻚ",
    confirmRejection: "اذا تريد رفض العقد من فضلك اضغط نعم.",
    srsCheckHaveNotPassed: "ﺳﻮف ﻳﺘﻢ ﺗﺤﻮﻳﻠﻚ إلى اﻟﻤﺘﺠﺮ ﺧﻠﺎل <0>{{timer}}</0> أو اﺿﻐﻂ ﺧﺮوج للعودة",
    srsCheckFailed: "عملية التحقق فشلت لأسباب تقنية. من فضلك أعد المحاولة.",
    srsCheckFinallyFailed: "عملية التحقق فشلت لأسباب تقنية. من فضلك أعد المحاولة.",
    exceededRegAttempts: "ﻟﻘﺪ ﺗﺨﻄﻴﺖ أﻗصى ﻋﺪد ﻣﺤﺎوﻟﺎت اﻟﺘﺴﺠﻴﻞ ﺑﻬﺬا اﻟﺮﻗﻢ اﻟﻘﻮﻣﻲ/ الإقامة",
    enterPhoneFromAbsher: "ﻣﻦ ﻓﻀﻠك أدﺧﻞ ﻧﻔﺲ ﺑﻴﺎﻧﺎﺗﻚ اﻟﻤﺴﺠﻠة ﻓﻲ ﻣﻨﺼة أﺑﺸﺮ ﻣﻦ أﺟﻞ اﻛﺘﻤﺎل اﻟﺘﺤﻘﻖ",
    successfulCallbackRequest: "سوف تستقبل مكالمة قريباً جداً",
    whereToGo: "من فضلك اختر اين تريد الذهاب.",
    underDeletation: "ينبغي إلغاء حذف الحساب  لإكمال طلب التمويل. سيتم إيقاف طلب التمويل في حالة عدم إلغاء الحذف.",
    removeRelative: "لقد أدخلت بعض  بياناته بالفعل.",
    accountDeactivated:
      "بسبب 3 محاولات خاطئة لإدخال كلمة المرور ، تم إلغاء تنشيط حسابك حتى تتمكن من تأكيد هويتك. يرجى المتابعة لاستيفاء المتطلبات اللازمة لتفعيل حسابك.",
    accountDeactivatedOTP:
      "بسبب 3 محاولات خاطئة لإدخال الرمز السري ، تم إلغاء تنشيط حسابك حتى تتمكن من تأكيد هويتك. يرجى المتابعة لاستيفاء المتطلبات اللازمة لتفعيل حسابك.",
    accountActivated: "شكرا لتأكيد هويتك. يرجى متابعة إعداد كلمة المرور لتسجيل الدخول إلى حسابك.",
  },
  orderContent: {
    header: "المشتريات",
    subheader: {
      keyWithCount_one: "انت تؤكد <0> شراء منتج واحد </0> بقيمه كليه <1>{{sum}} </1>",
      keyWithCount_two: "انت تؤكد <0> شراء منتجين </0> بقيمه كليه <1>{{sum}} </1>",
      keyWithCount_few: `اﻧﺖ ﺗﺆﻛﺪ ﺷﺮاء <0/>{{itemsAmount}}<0> منتجات ﺑﻘﻴﻤﻪ كلية  <1>{{sum}}</1>`,
    },
    productsInCart: "المنتجات في العربه",
  },
  confirmationContent: {
    header: "ﻳﺠﺐ اﻟﺎﻧﺘﻈﺎر ٢٤ ﺳﺎﻋﻪ اﻟﺰاﻣﻴﻪ ﻟﻠﺎﺳﺘﻤﺮار",
    whatWillHappenNext: "ﻣﺎذا ﺳﻴﺘﻢ ﺑﻌﺪ ذﻟﻚ؟",
    phoneCall: "ﺳﺘﺴﺘﻠﻢ مكالمة هاتفية ﻣﻦ كوارا للتمويل ﻟﻠﺘﺤﻘﻖ ﻣﻦ ﺗﺴﺠﻴﻠﻚ.",
    message: "ﺳﺘﺴﺘﻠﻢ رﺳﺎﻟﻪ ﻫﺎﺗﻔﻴﻪ ﺑﺎﻟﻤﻮاﻓﻘﻪ.",
    contract: "ستوقع العقد من خلال موقع كوارا للتمويل بخطوات بسيطه.",
    nafithConfirm: "ستوافق على السند الاذني من نافذ.",
    transaction: "ﻃﻠﺒﻚ ﺳﻴﻜﻮن مكتملاً ﺑﻤﺠﺮد اﻧﺘﻬﺎء 24 ساعة.",
    installMobileApp: "قم بتوقيع عقدك وإدارة طلباتك وأقساطك لاحقًا من تطبيق Quara Finance للهاتف المحمول.",
    warnings: {
      contractIsWaitingForACall:
        "جاري العمل على العقد! من فضلك انتظر مكالمتنا للتأكيد، ويُمكنك التقدُّم بطلب قرض آخر بعد الانتهاء من إجراءات التمويل الحالي.",
      contractIsWaitingForASigning:
        "جاري العمل على العقد! ﻣﻦ ﻓﻀﻠﻚ وﻗﻊ اﻟﻌﻘﺪ اﻟﻠﺬي ﻳﻨﺘﻈﺮك ﻓﻲ ﺗﻄﺒﻴﻖ كوارا للتمويل. ويُمكنك التقدُّم بطلب قرض آخر بعد الانتهاء من إجراءات التمويل الحالي.",
      contractIsSigned:
        "الشركة السعودية للمعلومات الائتمانية (سمة): أقر أنا الموقع أدناه (وكيل /أو شخص/ مفوض، بالنيابة عن الشركة بموافقتي على تمكين وتزويد شركة كوارا للتمويل بأي معلومات تيطلبها لفتح و/أو تدقيق و/أو إدارة حساباتي وتسهيلاتي. كما أفوضهم بجمع كافة البيانات اللازمة أو التي تخصني، أو تخص حسابي، أو التسهيلات اللاحقة، من الشركة السعودية للمعلومات الائتمانية (سمة) كما أتعهد بالإفصاح لشركة (سمة (بموجب اتفاقية مشاركة المعلومات) عن كافة البيانات والمعلومات أو لأي جهة يقرها البنك المركزي السعودي. (ساما).agency approved by Saudi Central Bank (SAMA)",
    },
  },
  employmentDetails: {
    consent: "أوافق على طلب بياناتي من مكتب سمة للمعلومات الائتمانية.",
    fullConsent: "نص الموافقة",
    consentDialogTitle: "نص الموافقة من شركة سمة",
    consentDialogDescription:
      "أقر أنا الموقع أدناه، ((وكيل / شخص مفوض (بالنيابة عن الشركة))، بموافقتي على تمكين شركة كوارا للتمويل وتزويدها بأي معلومات تطلبها لفتح و/أو تدقيق و/أو إدارة حساباتي وتسهيلاتي. كما أفوض شركة كوارا للتمويل بجمع كافة البيانات اللازمة التي تخصني، أو تخص حسابي، أو التسهيلات اللاحقة، من الشركة السعودية للمعلومات الائتمانية (سمة)، مع الإفصاح عن هذه المعلومات ومشاركتها (بموجب اتفاقية مشاركة المعلومات) لشركة (سمة). ",
  },
  newOfferCalculator: {
    requestAmount: "المبلغ المطلوب",
    description: "اختر عرض التمويل الأنسب",
    sarPerMonth: "ريال/شهر",
  },
  offerDetails: {
    totalAmount: "المبلغ الإجمالي",
    description: "تفاصيل عرض التمويل",
    requested_amount: "المبلغ المطلوب",
    profit: "الربح",
    tenure: "الفترة",
    monthly_payment: "الدفع الشهري",
    interest_rate: "نسبة الفائدة",
    apr: "فائدة",
  },

  terms_title: 'شروط وأحكام "شركة كوارا للتمويل"',
  terms: {
    keyPrinciples: {
      title: "ﻣﺒﺎدئ اﺳﺎسية",
      1: 'شروط وأحكام "شركة كوارا للتمويل"تم تحرير هذه الشروط والأحكام لتوفير إرشادات واضحة وشروط ملزمة قانونيا فيما يتعلق بالخدمات المقدمة لك من قبل شركة كوارا للتمويل بموجب قوانين المملكة العربية السعودية المتاجرة باستخدام الاسم التجاري "كورا للتمويل") (يشار إليها فيما بعد بضمير "نحن" أو أو "الشركة" أو "كوارا للتمويل"أو شركة كوارا للتمويل). تحكم هذه الشروط استخدامك لتطبيق كوارا للتمويل للهاتف المحمول ("تطبيق كوارا للتمويل") لخدمات التمويل الصغير، وموقع كوارا للتمويل الالكتروني ("موقع كوارا للتمويل")، وعلاقتك مع الشركة بالإضافة إلى أي خدمات أخرى المتاحة لك من خلال استخدام تطبيق كوارا للتمويل من وقت لآخر ("الخدمات"). هذا المستند متاح باللغتين العربية والإنجليزية.',
      2: 'يتم تقديم هذه الشروط إليك لمراجعتها وقبولها قبل منح موافقتك على إكمال عملية التسجيل والتسجيل (المشار إليها فيما يلي باسم "التسجيل") من قبل الشركة. التسجيل شرط أساسي لاستخدام تطبيق كوارا للتمويل وموقع كوارا للتمويل والخدمات. يتطلب التسجيل من الشركة إجراء فحوصات "اعرف عميلك" (المشار إليها فيما يلي باسم "اعرف عميلك") من أجل التحقق من أنك مستخدم معتمد للخدمات ولن يتم إجراؤها إلا بعد إبداء موافقتك على الشروط.',
      3: "يرجى قراءة هذه الشروط بعناية، قبل الانتهاء من التسجيل، حيث تؤثر الشروط على حقوقك والتزاماتك بموجب قوانين المملكة العربية السعودية. إذا كنت لا توافق على هذه الشروط، فيرجى الامتناع عن استخدام موقع كوارا للتمويل أو تطبيق كوارا للتمويل. وفي حال قيامك بإجراءات التسجيل في خدمات تطبيقات كوارا للتمويل، فإنك تقر بأنك قد قرأت وفهمت ووافقت على الالتزام بهذه الشروط.",
    },
    definitions: {
      title: "تعريفات",
      0: "يقصد بالكلمات والعبارات التالية المعاني الآتية:",
      1: '"الحساب" يعني حساب الأموال الإلكترونية الذي يفتحه العميل باستخدام تطبيق كوارا للتمويل لاستخدام الخدمة.',
      2: 'يعني "APP" تطبيق كوارا للتمويل.',
      3: '"التطبيق" يعني استمارة التسجيل التي يجب على العميل إكمالها لإنشاء خدمة حساب كوارا للتمويل.',
      4: '"ساما": البنك المركزي السعودي.',
      5: "سمة: الشركة السعودية للمعلومات الائتمانية.",
      6: '"الرسوم" تعني رسوم الخدمة التي يفرضها كوارا للتمويل على الخدمات المختلفة المقدمة على تطبيق كوارا للتمويل.',
      7: '"الائتمان" هو النقد الإلكتروني المتاح في حساب دائن العميل.',
      8: '"حساب الائتمان" يعني الحساب المفتوح في البنك الشريك لتنظيم جميع المدفوعات والاحتفاظ بها نيابة عن العملاء.',
      9: 'يشير مصطلح "العميل" إلى أي شخص، يبلغ من العمر 18 عامًا أو أكبر، قام بالتسجيل في خدمات تطبيق كوارا للتمويل ووافق على الشروط والأحكام الخاصة بها على النحو المبين في هذا المستند.',
      10: ' "خدمة تطبيقات كوارا للتمويل" هي عبارة عن منصة إلكترونية تقدمها شركة كوارا للتمويل، والتي من خلالها يمكن للعميل المسجل الحصول على قروض وسداد القروض من خلال نماذج تحويل الأموال المختلفة من حسابه إلى حساب عميل آخر داخل حدود المملكة العربية السعودية وفقًا لترخيص صادر بواسطة "سما".',
      11: '"مركز خدمة العملاء لتطبيق كوارا للتمويل" يعني رقم خدمة العملاء المركزي الذي يمكن للعملاء الوصول إليه خلال ساعات العمل للإبلاغ عن أي مشاكل فنية واجهتها من خلال استخدام خدمة تطبيق كوارا للتمويل، والتي تسمح لشركة كوارا للتمويل بالاستجابة لهذه المشاكل أو العنوان أو أي استفسارات أخرى للعملاء.',
      12: '"الموزع / الوكيل" هو الفرد المعين من قبل شركة كوارا للتمويل لتقديم الخدمات المتعلقة بتطبيق كوارا للتمويل.',
      13: '"خدمات الموزع" هي الخدمات والوظائف التي يضطلع بها الموزعون / الوكلاء المرتبطون بتطبيق كوارا للتمويل بما في ذلك إيصالات الاشتراك، وتفعيل وتسهيل اكتساب العميل، وإعداد العميل، وقسائم الشحن، والخدمات الأخرى.',
      14: '"الحساب الإلكتروني" هو حساب تطبيق كوارا للتمويل الخاص بالعميل ويشير إلى الحساب الرقمي الذي تم إنشاؤه والاحتفاظ به بواسطة كوارا للتمويل لصالح العميل حصريًا عند التسجيل وقبول البنود والشروط التي توفرها شركة كوارا للتمويل من وقت لآخر.',
      15: '"ريال سعودي" يعني الريال السعودي.',
      16: 'تشير كلمة "اعرف عميلك" إلى عملية "اعرف عميلك" وإلى تفاصيل الهوية الشخصية للعميل والتي تمت مصادقتها وفقًا لبنك المركزي السعودي ومتطلبات الكيانات الحكومية الأخرى.',
      17: 'يشير مصطلح "الإقراض" إلى خدمة الإقراض الصغير المقدمة لعملاء تطبيق كوارا للتمويل على أساس كل حالة على حدة. سيكون تحديد كوارا للتمويل لأهلية العملاء لتلقي القروض الصغيرة وفقًا لتقدير كوارا للتمويل وحدها ووفقًا لمعايير وإجراءات التأهيل المحددة من قبل كوارا للتمويل.',
      18: 'يشير مصطلح "غسل الأموال" إلى جميع الأنشطة المحظورة وفقًا لقوانين وأحكام مكافحة غسل الأموال في المملكة العربية السعودية.',
      19: ' "بطاقة SIM" هي البطاقة التي تحتوي على المعلومات ذات الصلة بالعميل والتي يتم إصدارها من قبل مشغل اتصالات الهاتف المحمول في المملكة العربية السعودية وتمكن العميل من الاستفادة والوصول إلى خدمة تطبيق كوارا للتمويل والخدمات الأخرى التي تقدمها شركة كوارا للتمويل.',
      20: ' "رمز التنشيط" يعني رمز التعريف الشخصي المكون من 4 أرقام والذي يتم إصداره للعميل عند التسجيل في خدمة تطبيق كوارا للتمويل. يتم استخدام رمز التعريف الشخصي المكون من 4 أرقام لتنشيط حساب العميل.',
    },
    appRegistration: {
      title: "التسجيل في تطبيق كوارا للتمويل والمتطلبات",
      1: "خدمات تطبيق كوارا للتمويل متاحة للأفراد المقيمين في المملكة العربية السعودية والذين يمتلكون بطاقة SIM نشطة وخدمة اشتراك مع أي شركة اتصالات سعودية معتمدة. ستكون كل بطاقة SIM مؤهلة فقط لامتلاك حساب واحد مرتبط بها.  يحق لشركة كوارا للتمويل تغيير الشروط لتشمل الأفراد المقيمين في المملكة العربية السعودية في أي وقت وفقا لتقديرها الخاص. ",
      2: "يمكن إكمال التسجيل في خدمات تطبيقات كوارا للتمويل من خلال تطبيق كوارا للتمويل.",
      3: 'يخضع التسجيل في خدمات تطبيقات كوارا للتمويل لجمع معلومات "اعرف عميلك" الكاملة والتحقق من هوية العميل وفقًا للوائح البنك المركزي السعودي.',
      4: 'يحق لشركة كوارا في أي وقت، في اتخاذ جميع الخطوات والإجراءات ذات الصلة اللازمة لضمان الامتثال لجميع متطلبات البنك المركزي السعودي “اعرف عميلك" ذات الصلة والقابلة للتطبيق.',
      5: "يقر العميل بأن جميع المعلومات المقدمة صحيحة وكاملة ودقيقة.",
      6: "يحق لشركة كوارا في أي وقت في رفض الطلب المقدم من خلال التطبيق في حالة وجود أي تناقضات أو عدم دقة في المعلومات أو الوثائق المقدمة من قبل العميل.",
      7: "عند التسجيل في تطبيق كوارا للتمويل، سيتلقى العميل رسالة تأكيد تؤكد نجاح إنشاء الحساب.",
      8: "عند التسجيل في تطبيق كوارا للتمويل، سيتلقى العميل رمز التفعيل الذي يجب إدراجه في التطبيق لتفعيل الحساب. يمكن للعميل تغيير رمز التفعيل في أي وقت ويجب عليه القيام بذلك على الفور لأغراض أمنية.",
      9: "يتحمل العميل وحده مسؤولية دقة المعلومات المقدمة في تطبيق كوارا للتمويل. إذا تم تحديد المعلومات المقدمة من قبل العميل على أنها غير صحيحة أو خاطئة أو مضللة، فإن تطبيق كوارا للتمويل لا يتحمل أي مسؤولية ولا يتحمل مسؤولية أي عواقب قد تنجم عن تقديم مثل هذه المعلومات غير الصحيحة أو الخاطئة أو المضللة. إذا كان لدى العميل معرفة فعلية أو بناءة فيما يتعلق بعدم دقة المعلومات المقدمة حول حسابه، يجب على العميل إخطار الشركة عن طريق التواصل مع support@quarafinace.com في أقرب وقت ممكن. إذا اكتشفت الشركة أن المعلومات المقدمة من العميل خاطئة أو مضللة، تحتفظ الشركة بالحق في إنهاء حساب العميل في أي وقت، مع أو بدون إشعار. سيكون الإنهاء من قبل الشركة ساري المفعول على الفور أو كما قد يتم تحديده في إشعار من الشركة. سيشمل إنهاء حساب العميل تعطيل وصول العميل إلى التطبيق.",
    },
    appConditions: {
      title: "شروط وأحكام تطبيق شركة كوارا للتمويل:",
      1: "لا يمكن تخزين أي أموال في أي وقت في حساب تطبيق كوارا للتمويل للعملاء وفقًا للوائح البنك المركزي السعودي الحالية.",
      2: "سيظل حساب تطبيق كوارا للتمويل الخاص بالعميل نشطًا لمدة 180 يومًا من آخر معاملة أو تاريخ تنشيط الحساب.      ",
      3: "في حالة فقد أو تلف بطاقة SIM التي تم تخصيص رقم العميل عليها، يجب على العميل إبلاغ الشركة على الفور من خلال دعم خدمة العملاء أو عبر البريد الإلكتروني لحظر الخدمة لمنع الاستخدام غير المصرح به لخدمات تطبيق كوارا للتمويل حتى بطاقة SIM يتم استرداد البطاقة. يمكن للعميل أيضًا نقل حسابه من بطاقة SIM إلى أخرى بعد أن تتحقق الشركة من عدم وجود آثار قانونية قد تنشأ بسبب تعليق بطاقة SIM نتيجة للأنشطة غير المصرح بها. يشمل هذا النشاط غير المصرح به على سبيل المثال لا الحصر الاحتيال وغسل الأموال وتمويل الإرهاب والقائمة السوداء. يتحمل العميل وحده عبء الحصول على بطاقة SIM البديلة.      ",
      4: "من خلال التقديم على تطبيق كوارا للتمويل، يمنح العملاء موافقتهم الكاملة وتفويضهم لـ كوارا للتمويل لطلب بيانات العملاء من يقين أو تحكم أو أبشر أو علم أو أي وكالة أو مؤسسة مالية أو أي وكالة إبلاغ للمستهلكين للوصول إلى السجلات المالية أو المالية الأخرى المعلومات، من أجل التحقيق أو تحديد أهلية العملاء.      ",
      5: 'يمنح العملاء موافقتهم الكاملة وتفويضهم لـ كوارا للتمويل لطلب معلومات شخصية "اعرف عميلك" من أبشر أو يقين أو تحكم أو علم أو أي مؤسسة حكومية أخرى لتحديد وتقييم هوية الفرد وأهليته القانونية لإلزامه بالالتزامات المنصوص عليها في هذه الأحكام والشروط.      ',
      6: "بموجب هذا انا الموقع ادناه أوافق على تزويد شركة كوارا للتمويل بأي معلومات أو بيانات تطلبها مني لتأسيس حسابي لدى الشركة /او لمراجعته/أو لأدارته وأفوض الشركة بأن تحصل على ما يلزم أو تحتاج اليه من معلومات تخصني أو تخص حسابي المذكور أو أي حساب اخر يكون لدى الشركة من الشركة السعودية للمعلومات الائتمانية (سمة)      ",
      7: "كما أوافق على أن تفصح الشركة عن المعلومات الخاصة بي وبحسابي المذكور أو أي حساب اخر يكون لدى المفوض للشركة السعودية للمعلومات الائتمانية (سمة) من خلال اتفاقية مشاركة المعلومات و/أو لأي جهة أخرى.      ",
      8: "لا يحق للشركة الإفصاح عن أي معلومات أو مستندات أو تفاصيل شخصية عن أي أنشطة في حساب العميل إلا عند طلب جهة حكومية متخصصة في مكافحة الاحتيال وغسل الأموال وتمويل الإرهاب.   يلتزم العميل بأي تعليمات متعلقة بتطبيق كوارا للتمويل صادرة عن الشركة من وقت لآخر.",
    },
    suspension: {
      title: "تعليق وإغلاق الحساب والخدمات:",
      1: " يحق لشركة كوارا للتمويل تعليق خدمات تطبيق كوارا للتمويل أو إيقافها، إما كليًا أو جزئيًا، أو حظر أو تقييد أو إنهاء الحساب أو تعليق استخدام أي رمز تنشيط وفقًا لتقديرها، في أي وقت ودون أي مسؤولية تجاه العميل في الحالات التالية:      ",
      2: "1. إذا كان لدى كوارا للتمويل سبب للاعتقاد بأن الهاتف المحمول أو الخط أو الرمز الخاص بالعميل المستخدم للحصول على خدمات تطبيق كوارا للتمويل يتم استخدامه على عكس ما تم الكشف عنه أو استخدامه في أنشطة غير قانونية.      ",
      3: "2. في حالة قيام أي طرف قانوني أو تنظيمي أو حكومي متخصص أو أي سلطة أخرى مماثلة ببدء تحقيق أو اتخاذ إجراءات تنظيمية أو إدارية أو تنفيذية ضد العميل أو أي إجراء آخر تتضرر فيه شركة كوارا للتمويل      ",
      4: "3. في حالة عدم التزام العميل بالشروط أو اللوائح أو التعليمات ذات الصلة لاستخدام خدمات تطبيق كوارا للتمويل بما في ذلك شروط الاستخدام.      ",
      5: "4. في حالة قيام العميل بإبلاغ كوارا للتمويل أن بطاقة SIM التي تم ربط تطبيق كوارا للتمويل بها قد فقدت أو تمت سرقتها أو أنه تم الكشف عن رمز التفعيل بشكل غير صحيح لشخص آخر.      ",
      6: "5. في حال طلب العميل بأغلاق الحساب التابع له.      ",
      7: "6. في حالة حدوث عطل فني و / أو تعديل / أو ترقية / أو تغيير / أو نقل / أو إصلاح / أو صيانة بسبب أي حاله طارئه أو لأي أسباب فنية.      ",
      8: "7. في حال أي انتهاك مشتبه به للقواعد واللوائح والأوامر والتوجيهات والإخطارات الصادرة عن البنك المركزي السعودي من وقت لآخر أو عن أي انتهاك للشروط والأحكام المذكورة هنا.      ",
      9: "8. في حال كان لدى شركة كوار للتمويل سبب التعليق ضروري وفقًا لتقديرها.      ",
      10: "شركة كوارا للتمويل ليست مسؤولة تجاه العميل عن أي خسارة أو عيب أو ضرر مباشر أو غير مباشر ناتج عن أي سلوك ، أو أن العميل غافل عن حالة حسابه في حالة الإغلاق أو التعليق.      ",
      11: "حساب ائتمان العميل هو امتياز له طوال فترة حياته. في حالة وفاة العميل، سيكون الرصيد المتبقي امتيازًا لورثته القانونيين. سيصبح هذا ساري المفعول قانونيًا بعد أن يقدم ورثته القانونيون شهادة وفاة صالحة إلى مركز رعاية العملاء.      ",
      12: "في حالة وجود أي خلل في تشغيل الخدمة يقع ضمن مسؤولية كوارا للتمويل لأي سبب من الأسباب، سوف تلتزم كوارا للتمويل بالتخفيف من تأثير الضرر الذي يلحق بالتطبيق إن وجد.      ",
    },
    serviceCharges: {
      title: "رسوم الخدمات:",
      1: "يجب على العميل دفع رسوم الخدمة المنصوص عليها في تطبيق كوارا للتمويل بالشكل والطريقة المحددة، كما يجوز لشركة كوارا للتمويل، وفقًا لتقديرها، تغيير أو تعديل أو زيادة أو تخفيض رسوم الخدمة من وقت لآخر مع إخطار العميل المناسب. ستلتزم شركة كوارا للتمويل بالإعلان عن التغييرات في الخدمات المتاحة بعدة طرق مثل على سبيل المثال لا الحصر من خلال التطبيقات والمواقع الإلكترونية والصحف والرسائل النصية والكتيبات.      ",
      2: "سيمكن حساب تطبيق كوارا للتمويل العميل من المشاركة في الأنشطة التالية:      ",
      3: "•	التمويل الصغير",
      4: "•	حساب تطبيق كوارا للتمويل لتحويل الحساب المصرفي.",
      5: "بعد إكمال نشاط تطبيق كوارا للتمويل، سيتلقى المرسل رسالة نصية قصيرة SMS تتضمن وصفًا للمعاملة المالية لآخر رصيد دائن في الحساب. يخضع جميع المسجلين في نظام تطبيق كوارا للتمويل لقرينة الدقة الغير قابلة للدحض.      ",
    },
    securityAndUnauthorizedUsage: {
      title: "الأمان والاستخدام غير المصرح به:",
      1: "لكل حساب بيانات اعتماد واحدة يمكن للعميل من خلالها الوصول إلى الحساب.      ",
      2: "يتحمل العميل وحده المسؤولية عن سرية وسلامة وأمن الكود الخاص به وبيانات الاعتماد الأخرى.      ",
      3: "يتحمل العميل وحده المسؤولية عن العواقب الناشئة عن الكشف عن الكود / أو الاستخدام غير المصرح به لخدمة تطبيق كوارا للتمويل. في حالة فقد الرمز أو الهاتف المحمول أو بطاقة SIM المرتبطة بخدمات تطبيقات كوارا للتمويل أو وضعها في غير مكانها أو تم اختراقها بأي طريقة أخرى ، يجب على العميل إبلاغ تطبيق كوارا للتمويل على الفور عن طريق إرسال بريد إلكتروني إلى support@quarafinance.com حيث بعد تطبيق كوارا للتمويل سيتم حظر الحساب وسيتم إصدار بيانات اعتماد جديدة للعميل بعد التحقق من الصحة المطلوبة.      ",
      4: "يتحمل العميل مسؤولية حماية هاتفه المحمول واستخدامه الصحيح كما أنه مسؤول أيضًا عن الحفاظ على جميع معاملات تطبيق كوارا للتمويل التي تم إكمالها من خلال رمز التحقق.      ",
      5: "يحق للعميل تغيير رمز حسابه من خلال قائمة الخدمة في التطبيق أو من خلال وسائل أخرى، وفي أي حال يجب على العميل تعريف نفسه عند أي معاملة من خلال تطبيق كوارا للتمويل باستخدام هاتفه المحمول والرمز المرسل له كما يحظر على العميل الكشف عن معلومات الدخول لحسابه لأي شخص، بما في ذلك موظف خدمة العملاء أو مركز رعاية العملاء.      ",
    },
    customerObligations: {
      title: "التزامات العميل :",
      1: "العميل مسؤول عن جميع رسوم معاملات تطبيق كوارا للتمويل التي يتم الوصول إليها باستخدام الرمز بما في ذلك المكالمات والأنشطة الجارية الأخرى عبر الهاتف المحمول، سواء كانت الأنشطة التي ينفذها العميل أو يقوم بها شخص آخر بتكليف من العميل.      ",
      2: "العميل مسؤول أيضًا عن جميع الرسوم المرتبطة بزيادة الحساب والتحويلات والإقراض الصغير والأنشطة الأخرى.      ",
      3: "لا يجوز للعميل استخدام خدمات تطبيقات كوارا للتمويل لأي غرض قد يتم تفسيره على أنه مخالف أو غير مقبول لأي قوانين معمول بها أو لوائح البنك المركزي السعودي أو إرشادات أو بيانات قضائية أو سياسة شركة كوارا للتمويل أو السياسة العامة أو لأي غرض قد يضر بشكل سلبي بسمعة شركة كوارا للتمويل.      ",
      4: "يقر العميل ويضمن أن جميع المعلومات التي تم إدخالها في تطبيق كوارا للتمويل أو المقدمة بطريقة أخرى إلى تطبيق كوارا للتمويل صحيحة وكاملة، بما في ذلك المعلومات الشخصية المتعلقة بالحساب. عند معالجة معاملة، سيعتمد تطبيق كوارا للتمويل على معلومات التسجيل التي قدمها العميل.      ",
      5: "يقر العميل بأن أي أخطاء في المعلومات، بما في ذلك التحديد الخاطئ للمستفيد (المستفيدين)، أو أسماء وأرقام الحساب غير الصحيحة أو غير المتسقة، أو الأخطاء الإملائية، هي مسؤوليته / مسؤوليتها، وأن تطبيق كوارا للتمويل لن يتحمل أي مسؤولية عن تنفيذ معاملة قائمة على بناءً على المعلومات غير الدقيقة أو غير الكاملة المقدمة أو التي أدخلها العميل.      ",
      6: "الغرض من خدمات تطبيق كوارا للتمويل هو استخدامها في المملكة العربية السعودية ، على وجه التحديد ودون حصر ويتفهم العميل ويوافق على أنه لا يجوز له استخدام الخدمة في هذه الحالات  (أ) تكون ناتجًا على عائدات الجريمة أو الاحتيال أو أي نشاط آخر غير لائق ؛ (ب) لغرض المقامرة ، بما في ذلك المراهنة أو دفع المكاسب ؛ (د) في حال تحديد شركة كوارا للتمويل عمليات مشبوهة أو قد تنتهك القانون أو اللوائح المعمول بها ، بما في ذلك القوانين التي تهدف إلى اكتشاف ومنع غسل الأموال أو تمويل الإرهاب أو الاتجار بالبشر أو الاحتيال أو فرض ضوابط على العملة أو أسعار الصرف.      ",
      7: "يوافق العميل على أنه سيطلب فقط معاملة تتوافق مع هذه الشروط والأحكام.",
    },
    dataProtection: {
      title: "حماية البيانات والسرية :",
      1: "تخضع خصوصية الاتصالات   شروط البنك المركزي السعودي وقراراتها وتعاميمها ويوافق العميل على ذلك من أجل تسهيل توفير خدمات تطبيقات كوارا للتمويل. وقد يُطلب من شركة كوارا للتمويل بموجب القانون أو بخلاف ذلك الكشف عن أي معلومات أو تفاصيل حول العميل إلى أي جهة حكومية.      ",
      2: 'رسومات مستخدم "كوارا للتمويل" وأسماء العلامات التجارية والشعارات والتصميم والتجميع والمعلومات ورمز التحقق والمنتجات والبرامج والخدمات وجميع العناصر الأخرى لخدمات تطبيقات كوارا للتمويل التي تقدمها الشركة "المواد “محمية بموجب قوانين حقوق النشر والتصاميم وبراءات الاختراع والعلامات التجارية والاتفاقيات الدولية وغيرها من حقوق الملكية الفكرية المعمول بها.      ',
      3: "تُستخدم أي معلومات تتعلق بالعميل بشكل عام لتوفير خدمات تطبيقات كوارا للتمويل وتحسينها.      ",
      4: "لا يمكن الوصول إلى المعلومات الشخصية والمالية الخاصة بالعميل واستخدامها إلا من قبل الموظفين المصرح لهم.      ",
      5: "يحتفظ الدائن بجميع معلومات الدفع والأموال الأخرى الواردة في حساب الائتمان المسجل في حساب العميل (مبالغ الائتمان) كمعلومات سرية.      ",
      6: "سيكون العميل هو المستفيد الوحيد من مبالغ الائتمان التي تساوي قيمة الائتمان المدين في حساب العميل. يوافق العميل بموجب هذا على الاحتفاظ بمسجلي نظام تطبيق كوارا للتمويل في تطبيق كوارا للتمويل كتحقق ملموس من مبلغ النقود الإلكترونية المتاح في أي وقت في حساب العميل.      ",
      7: "لا يلزم الرصيد بإجراء تحقيق مستقل في شرعية المبالغ المستحقة للعميل.      ",
      8: "لا يحق للعميل المطالبة بأي مزايا قد تأتي من مبلغ الائتمان ويحق لـ كوارا للتمويل الاحتفاظ بالمزايا لاستخدامها لأي أغراض قانونية.    يجب على الشركة استخدام جميع التدابير المعقولة لضمان أن تظل معاملات العملاء ومعلوماتهم آمنة وسرية. لا تقدم شركة كوارا للتمويل أي ضمانات من أي نوع حول النظام والشبكة أو وظيفتها أو أدائها، أو عن أي خسارة أو ضرر، متى وأيا كان الذي تكبده أو يتكبده العميل أو أي شخص آخر ناتج عن أو فيما يتعلق باستخدام خدمات تطبيق كوارا للتمويل.",
    },
    disclaimerIndemnity: {
      title: "إخلاء المسؤولية والتعويض",
      1: 'يتم توفير التطبيق والمواد الموجودة على التطبيق وأي منتج أو خدمة يتم الحصول عليها من خلال التطبيق "كما هي" وبدون ضمانات من أي نوع، سواء كانت صريحة أو ضمنية إلى أقصى حد مسموح به بموجب القانون المعمول به.      ',
      2: "تخلي شركة كوارا للتمويل والشركات التابعة لها والمرخصون والموردون والمعلنون والجهات الراعية والوكلاء المسؤولية من جميع الضمانات، الصريحة أو الضمنية، بما في ذلك، على سبيل المثال لا الحصر، الضمانات الضمنية للملكية وعدم الانتهاك والدقة والقابلية للتسويق والملاءمة لغرض معين، وأي ضمانات قد تنشأ عن سير التعامل أو سير الأداء أو استخدام التجارة.      ",
      3: "لا تضمن شركة كوارا للتمويل والشركات التابعة لها والمرخصون والموردون والمعلنون والجهات الراعية والوكلاء أن استخدامك للتطبيق سيكون دون انقطاع أو خالي من الأخطاء أو آمنًا أو أنه سيتم تصحيح العيوب أو خلو التطبيق من الفيروسات أو غيرها من المكونات الضارة.      ",
      4: "يقر العميل بأنه مسؤول عن صيانة جميع الأجهزة المحمولة والأجهزة والمعدات الأخرى اللازمة للوصول إلى التطبيق واستخدامه وجميع الرسوم المتعلقة بذلك. يتحمل العميل مسؤولية تحديث برمجياته / أجهزته المحمولة إذا تم الإعلان عن ذلك من قبل السلطة المحلية لمنع الهاتف المحمول من اختراق بيانات العميل وقد يؤدي إلى استخدام الحساب من قبل المتسلل.      ",
      5: "يتحمل العميل كل المسؤولية والمخاطر المتعلقة باستخدامه للتطبيق والاعتماد عليه. لا يُنشئ أي رأي أو نصيحة أو بيان من كوارا للتمويل أو الشركات التابعة لها أو المرخصين أو الموردين أو المعلنين أو الرعاة أو الوكلاء أو الأعضاء أو الزوار، سواء تم إجراؤها على التطبيق أم لا كما يعتبر استخدام العميل للتطبيق والخدمات وجميع المواد المقدمة من خلال التطبيق مسؤوليته الخاصة بالكامل.      ",
      6: "لا تقدم كوارا للتمويل أي ضمان أو ضمان أو تعهد صريح أو ضمني على الإطلاق فيما يتعلق بخدمات تطبيقات كوارا للتمويل، والتي لم يتم ذكرها صراحةً هنا.      لن تكون شركة كوارا للتمويل مسؤولة عن أي أفعال أو إغفالات من قبل أي طرف ثالث بما في ذلك الخدمات غير المصرح بها صراحةً من قبل تطبيق كوارا للتمويل.",
      7: "لن تكون شركة كوارا للتمويل مسؤولة أمام أي شخص عن أي تأخير أو خسارة في العمل أو الربح أو الإيرادات أو الشهرة والمدخرات المتوقعة والأضرار والرسوم والتكاليف والمصروفات أياً كانت الناشئة، على خدمات تطبيقات كوارا للتمويل غير المتاحة / استخدام كوارا للتمويل خدمات التطبيقات أو غير ذلك.      ",
      8: "يوافق العميل على تعويض شركة كوارا للتمويل و / أو الأطراف ذات الصلة والدفاع عنها وحمايتها من جميع المطالبات والخسائر والأضرار والمسؤوليات والتكاليف والنفقات، بما في ذلك على سبيل المثال لا الحصر الرسوم والنفقات القانونية الناشئة عن أو المتعلقة باستخدام أو إساءة استخدام خدمات تطبيقات كوارا للتمويل، عن أي انتهاك لهذه الشروط والأحكام، أو أي خرق للضمانات والعهود.      ",
    },
    ChangesOfTermsandConditions: {
      title: "التغييرات في الشروط والأحكام",
      1: "يحق لشركة كوارا للتمويل إجراء أي تغييرات على هذه الشروط في أي وقت. يجب على كوارا للتمويل تزويد العملاء بنسخة محدثة من الشروط ، وسيتم نشر إعلان عن هذه التغييرات في إحدى الصحف أو الرسائل القصيرة أو موقع كوارا للتمويل أو تطبيق كوارا للتمويل أو أي وسيلة إعلامية مناسبة أخرى والتي ستكون كافية للعميل أن تكون على علم رسميًا.      ",
      2: "يعتبر استخدام العميل المستمر لخدمات تطبيقات كوارا للتمويل بعد الإعلان عن التغييرات على الشروط بمثابة قبول للتغييرات.      ",
      3: "تخضع الشروط الواردة هنا للإخطارات / الإرشادات الصادرة عن البنك المركزي السعودي من وقت لآخر.      ",
    },
    GeneralTermsandConditions: {
      title: "الاحكام والشروط العامة",
      1: "يجب على العميل دفع جميع رسوم استعادة المبالغ المدينة بما في ذلك أي رسوم خدمة مطبقة.      ",
      2: "يجب على العميل إبلاغ كوارا للتمويل على الفور بأي تغييرات تطرأ على معلوماته الشخصية في نموذج طلب التسجيل.      ",
      3: "يجب الإبلاغ عن أي نزاع أو شكوى ضد أي خدمات مالية مقدمة من خلال تطبيق كوارا للتمويل APP مباشرة من قبل العميل عن طريق إرسال بريد إلكتروني إلى support@quarafinance.com ويجوز لشركة كوارا للتمويل التحقيق في الادعاء وتحديده بحسن نية، وفقًا لتقديرها الخاص لحلها. أي خلافات عادلة وودية.      ",
      4: "في حالة وجود أي نزاع، تكون سجلات تطبيق كوارا للتمويل ملزمة كدليل قاطع على المعاملات التي تتم باستخدام خدمات تطبيقات كوارا للتمويل.      ",
      5: "تمتلك شركة كوارا للتمويل جميع حقوق النشر والعلامات التجارية وجميع حقوق النشر الفكرية الأخرى في المواد ويوافق العميل بموجب هذا على أنه لا يحق له استخدام هذه الحقوق أو المطالبة بها.      ",
      6: "يوافق العميل على تلقي رسائل SMS من كوارا للتمويل عبر الهاتف المحمول المحدد في نموذج طلب التسجيل.      ",
      7: "تعتبر هذه الشروط (أو أي نسخة معدلة) بمثابة اتفاقية تلزم العميل أو ممثليه بالشروط والأحكام المنصوص عليها هنا.      ",
      8: "لا يجوز نقل هذه الشروط أو إحالتها إلى أي شخص آخر باستثناء العميل الموجه في نموذج التسجيل.      ",
      9: "جميع الحقوق والتعويضات الواردة في هذه الاتفاقية تراكمية ولا تستبعد أي حقوق أو تعويضات ينص عليها القانون.      ",
    },
    ApplicableLaw: {
      title: "القانون الواجب التطبيق",
      1: 'يخضع هذا العقد ويتم تفسيره وفقًا لقوانين وأنظمة ("المملكة العربية السعودية") ، وستتم إحالة جميع النزاعات الناشئة بموجب هذا العقد إلى محاكم المملكة العربية السعودية ، والاختصاص القضائي في الرياض.      ',
    },
    CustomerConsent: {
      title: "موافقة واقرار العميل:",
      1: " أقر انا العميل بأني قد قرأت وفهمت شروط وأحكام استخدام التطبيق طبقا لنظام وقوانين المملكة العربية السعودية وبموجب هذا الاقرار أقر وأوافق على ما يلي: أوافق على تفويض  شركة كوارا للتمويل للوصول إلى معلوماتي الشخصية عبر منصة أبشر أو خدمات يقين أو تحكم Tahakom أو أي مؤسسة حكومية أخرى.      ",
      2: "أوافق على تفويض  شركة كوارا للتمويل للوصول إلى سجلاتي المالية لأغراض تسجيل الائتمان عبر سمة.      ",
      3: "أوافق على تفويض شركة كوارا للتمويل للوصول إلى سجلات الراتب / الدخل الخاصة بي لأغراض الدخل المالي عبر المؤسسة العامة للتأمينات الاجتماعية.      ",
      4: "أقر بأن من مسؤوليتي مراجعة كشوف حسابي والإشعارات ذات الصلة فور استلام الإخطار ذي الصلة.      ",
      5: "أوافق على دفع أي أرصدة أو رسوم أو ضرائب مستحقة على حسابي من شركة كوارا للتمويل.      ",
      6: "أوافق على أن أكون مسؤولاً عن أي أضرار أو تكاليف أو إنهاء للحساب ناتج عن فشلي في الإقرار بكشوف حسابي والإشعارات ذات الصلة.      ",
      7: "أوافق على إخطار كوارا للتمويل كتابةً أو عبر الهاتف بأي تغييرات على معلومات حسابي أو غيرها من المعلومات التي قدمتها فيما يتعلق بهذه الشروط.      ",
      8: "أوافق على أنه يمكن لكوارا للتمويل سحب أي مبلغ من المال تم استخدامه في معاملة تطبيق كوارا إلى حسابي عن طريق أي خطأ فني أو أي خطأ بشري.      ",
      9: "لقد قرأت جميع الشروط والأحكام المذكورة أعلاه بدقة ووافقت عليها.      ",
      10: "",
    },
  },
};
