export enum EMPLOYMENT_SECTORS {
  NONE = "",
  PRIVATE = "PRIVATE",
  GOVERNMENT = "GOVERNMENT",
}

export enum EMPLOYMENT_SECTORS_ACTIONS {
  SECTOR = "SECTOR",
  MONTHLY_INCOME = "MONTHLY_INCOME",
  MONTHLY_RENT = "MONTHLY_RENT",
  HOUSEHOLD_WAGE = "HOUSEHOLD_WAGE",
  UTILITY_EXPENSE = "UTILITY_EXPENSE",
  INSURANCE_PREMIUM = "INSURANCE_PREMIUM",
  TRANSPORT_EXPENSE = "TRANSPORT_EXPENSE",
  HEALTHCARE_EXPENSE = "HEALTHCARE_EXPENSE",
  COMMUNICATION_EXPENSE = "COMMUNICATION_EXPENSE",
  FOOD_LIVING_EXPENSE = "FOOD_LIVING_EXPENSE",
  EXPAT_DEPENDENT_GOVT_FEES = "EXPAT_DEPENDENT_GOVT_FEES",
  EDUCATION_SUPPLIES_EXPENSE = "EDUCATION_SUPPLIES_EXPENSE",
  MONTHLY_REMITTANCE = "MONTHLY_REMITTANCE",
  OTHER_MONTHLY_EXPENSE = "OTHER_MONTHLY_EXPENSE",
  OTHER_OBLIGATION = "OTHER_OBLIGATION",
  NUMBER_OF_DEPENDANTS_PUBLIC_SCHOOL = "NUMBER_OF_DEPENDANTS_PUBLIC_SCHOOL",
  NUMBER_OF_DEPENDANTS_PRIVATE_SCHOOL = "NUMBER_OF_DEPENDANTS_PRIVATE_SCHOOL",
  NUMBER_OF_HOUSEHOLD_HELP = "NUMBER_OF_HOUSEHOLD_HELP",
  NUMBER_OF_ALL_DEPENDANTS = "NUMBER_OF_ALL_DEPENDANTS",
  RESPONSIBLE_FOR_PAYMENT = "RESPONSIBLE_FOR_PAYMENT",
  PERSON_RESPONSIBLE = "PERSON_RESPONSIBLE",
  SOLE_BREADWINNER = "SOLE_BREADWINNER",
  RDEF = "RDEF",
  ACCOMODATION_TYPE = "ACCOMODATION_TYPE",
  HOME_OWNER = "HOME_OWNER",
  POLITICAL_PERSON = "POLITICAL_PERSON",
  QUARA_FINANCE_EMPLOYEE = "QUARA_FINANCE_EMPLOYEE",
  QUARA_FINANCE_RELATIVE = "QUARA_FINANCE_RELATIVE",
  QUARA_FINANCE_RELATIVE_DETAILS = "QUARA_FINANCE_RELATIVE_DETAILS",
}
