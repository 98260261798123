import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { BaseInput, Button, CustomDatePicker, PhoneNumberInput, Typography } from "@ui-kit";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateObject } from "react-multi-date-picker";
import { useHistory, useLocation } from "react-router-dom";

import { ReactComponent as InfoIcon } from "@assets/icons/info_icon.svg";
import { BasicDialog, Dialog } from "@components/Dialogs/BasicDialog";
import { StartPageHeader } from "@components/StartPageHeader/StartPageHeader";
import { TextWithEmail } from "@components/TextWithEmail";
import { TextWithLink } from "@components/TextWithLink/TextWithLink";
import { HeaderText } from "@components/TopMenu/components/MobileMenu/MobileMenu.components";
import { SUPPORT_EMAIL } from "@constants/common";
import { dialogContents } from "@constants/popups";
import { DEFAULT_MERCHANT_DATA_KEY } from "@constants/sessionStorageDefaults";
import { MERCHANT_DATA_KEY } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";
import { registrationSiteMap } from "@modules/Registration/pageMap";
import { Header } from "@pages/Confirmation/Confirmation.components";
import { UserLoginDialogs } from "@pages/Login/components/UserLoginDialogs";
import { useDialog } from "@utils/hooks/useDialog";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { logoutWithRedirect } from "@utils/services/Authentication/logoutWithRedirect";
import { convertArabicToDigit } from "@utils/services/Text/Text.service";
import { validateFormFields } from "@utils/services/Validation";
import { hasEmptyFields } from "@utils/services/Validation/validators";

import { FormErrors } from "./UnblockUserData.types";
import {
  DEFAULT_FORM_ERRORS,
  DEFAULT_UNBLOCK_USER_FORM_DATA,
  UnblockUserFormFields,
  UnblockUserInfoValidators,
  UNBLOCK_USER_FORM_FIELDS,
} from "./formData";
import { useUnblockUser } from "./hooks/useUnblockUser";

const StyledContinueButton = styled(Button)(({ theme }) => ({
  fontWeight: theme.direction === "ltr" ? "bold" : 600,
  width: "100%",
  maxWidth: "376px",
  [theme.breakpoints.up("sm")]: {
    width: "270px",
  },
}));

const HeaderTextPart = styled(Typography)(({ theme }) => ({
  fontWeight: theme.direction === "ltr" ? "bold" : 600,
  fontSize: `${theme.direction === "ltr" ? "2.4rem" : "2.8rem"}!important`,
  marginInlineEnd: 8,
}));

export const UnblockUser: FC = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const { unblockUser } = useUnblockUser();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isHijri, setHijriFlag] = useState(false);
  const currentDate = useMemo(() => new Date(), []);
  const [formFields, setFormData] = useState<UnblockUserFormFields>(DEFAULT_UNBLOCK_USER_FORM_DATA);
  const [errors, setFormErrors] = useState<FormErrors>(DEFAULT_FORM_ERRORS);
  const [nationalId, setNationalId] = useState("");
  const [isDialogOpen, closeDialog, openDialog] = useDialog();

  useEffect(() => {
    const idState = location.state as { nationalId: string };
    if (idState?.nationalId) {
      setNationalId(idState?.nationalId);
      setHijriFlag(nationalId[0] === "1");
    } else {
      history.push(siteMap.LogInPage.path);
    }
  });

  const handleDateChange = (chosenDate: DateObject): void =>
    setFormData((prevState) => ({ ...prevState, [UNBLOCK_USER_FORM_FIELDS.BIRTH_DATE]: chosenDate }));

  const handleContinueClick = () => {
    const { isFormValid, formErrors } = validateFormFields(formFields, UnblockUserInfoValidators, currentDate);
    if (isFormValid) {
      const convertedDate = convertArabicToDigit((formFields.BIRTH_DATE as DateObject).format("YYYY-MM-DD"));
      const convertedMobileNumber = "+966" + formFields.MOBILE_PHONE;

      const unblockUserRequest = async () => {
        const { isSuccess } = await unblockUser({
          uid: (location.state as { nationalId: string }).nationalId,
          phoneNumber: convertedMobileNumber,
          dateOfBirth: convertedDate,
        });
        openDialog();
        if (isSuccess) {
          history.push(registrationSiteMap.SetupKYCPage.path, { purpose: "ACCOUNT_REACTIVATION" });
        } else {
          openDialog();
        }
      };
      unblockUserRequest();
    } else {
      setFormErrors(formErrors as FormErrors);
    }
  };

  const handlePhoneNumberChange = (event: React.ChangeEvent<{ value: string }>) => {
    const maxLength = event.target.value.startsWith("05") ? 10 : 9;

    if (event.target.value.length <= maxLength) {
      setFormData((prevState) => ({ ...prevState, [UNBLOCK_USER_FORM_FIELDS.MOBILE_PHONE]: event.target.value }));
    }
  };

  const isNextDisabled = hasEmptyFields(formFields);

  const [merchantData] = useSessionStorageState(MERCHANT_DATA_KEY, DEFAULT_MERCHANT_DATA_KEY);

  const handleCloseDialog = () => {
    logoutWithRedirect(merchantData.merchantFailureUrl);
  };

  return (
    <>
      <Box>
        {!isMobile && (
          <StartPageHeader
            buttonText={""}
            leftSideHeaderText={t("pageHeader.unblockAccount.verify")}
            rightSideHeaderText={""}
            linkPathname={siteMap.CreateAccountPage.path}
          />
        )}
        <Grid container flexDirection="column" rowSpacing={{ md: 3, lg: 3 }}>
          <Grid item container columnSpacing={5}>
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
            >
              <PhoneNumberInput
                value={formFields[UNBLOCK_USER_FORM_FIELDS.MOBILE_PHONE]}
                errorText={
                  errors[UNBLOCK_USER_FORM_FIELDS.MOBILE_PHONE].length
                    ? t(`errors.${errors[UNBLOCK_USER_FORM_FIELDS.MOBILE_PHONE][0]}`)
                    : ""
                }
                onChange={handlePhoneNumberChange}
                label={t("labels.mobile")}
                type="text"
                labelId="phone-label-error"
                inputProps={{ maxLength: 10 }}
                placeholder={t("placeholders.mobileNumber")}
                hintText={
                  <Grid display="flex" alignItems="center" paddingBottom={2}>
                    <InfoIcon />
                    <Grid marginX={1}>{t("labels.phoneNumberAbsher")}</Grid>
                  </Grid>
                }
              />
            </Grid>
          </Grid>
          <Grid item container columnSpacing={5}>
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              mb={{
                xs: errors[UNBLOCK_USER_FORM_FIELDS.BIRTH_DATE].length ? "16px" : "0px",
                sm: errors[UNBLOCK_USER_FORM_FIELDS.BIRTH_DATE].length ? "16px" : "0px",
              }}
            >
              <CustomDatePicker
                lang={i18n.language === "ar" ? "ar" : "en"}
                dateValue={formFields[UNBLOCK_USER_FORM_FIELDS.BIRTH_DATE] as DateObject}
                onChange={handleDateChange}
                dateFormat={isHijri ? "YYYY/M/D" : "D/MM/YYYY"}
                isHijri={isHijri}
                placeholder={t("placeholders.birthDate")}
                calendarTitle={t("labels.setBirthday")}
                inputProps={{
                  label: t("labels.birthDate"),
                  labelId: "date-picker-input",
                  errorText: errors[UNBLOCK_USER_FORM_FIELDS.BIRTH_DATE].length
                    ? t(`errors.${errors[UNBLOCK_USER_FORM_FIELDS.BIRTH_DATE][0]}`)
                    : "",
                  showAdditionalMessage: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            <StyledContinueButton onClick={handleContinueClick} disabled={isNextDisabled} variant="contained">
              {t("buttons.continue") as string}
            </StyledContinueButton>
            {isMobile ? (
              <TextWithLink
                text={t("pageContent.alreadyHaveAccount")}
                linkPath={siteMap.LogInPage.path}
                linkText={t("buttons.logInSingle")}
              />
            ) : null}
          </Grid>
        </Grid>
      </Box>
      {isDialogOpen && (
        <BasicDialog
          open={isDialogOpen}
          onAgree={closeDialog}
          onCancel={handleCloseDialog}
          title={t(dialogContents.unableToVerify.title)}
          description={
            <TextWithEmail textKey={dialogContents.unableToVerify.description as string} email={SUPPORT_EMAIL} />
          }
          icon={dialogContents.unableToVerify.icon}
          agreeButtonText={t(dialogContents.unableToVerify.agreeText)}
          cancelButtonText={t(dialogContents.unableToVerify.cancelText)}
        />
      )}
    </>
  );
};
