export const TRANSLATIONS_EN = {
  hello: "Hello my friend",
  additionalPart: "Additional part",
  loading: "Loading...",
  error404Header: "Oops!",
  tokenMessage: "This offer will expire in next:",
  error404: "Error 404. Seems like page you're looking for doesn't exist.",
  acceptTerms: "I confirm that I read and accepts ",
  acceptTermsBtn: "terms & conditions.",
  units: {
    month: "month",
    month_one: "month",
    month_other: "months",
    trial: "Trial",
    trial_one: "Trial",
    trial_other: "Trials",
  },

  currency: {
    SAR: "SAR",
  },
  buttons: {
    logIn: "Log in",
    logInSingle: "Login",
    continue: "Continue",
    goBack: "Go back",
    resend: "Resend",
    ok: "Ok",
    exit: "Exit",
    tryAgain: "Try again",
    createAccount: "Create  Account",
    cancel: "Cancel",
    yesCancel: "Yes, cancel",
    no: "No",
    yes: "Yes",
    proceed: "Proceed",
    backToStore: "Back to store",
    goToStore: "Go to store",
    downloadApp: "Download Quara Finance app",
    continueHere: "Continue here",
    continueWithApp: "Continue with app",
    goToTheApp: "Go to the app",
    getStarted: "Get started",
    goToContract: "Go to the contract",
    reject: "Reject",
    accept: "Accept",
    back: "Back",
    sign: "Sign",
    updateDOB: "Update date of birth",
    callBack: "Request another call",
    selectSector: "Select sector",
    cancelDeletion: "Cancel deletion",
    addMoreLine: "+ Add another relative",
    removeLine: "- Remove",
    yesDelete: "Yes, Delete",
    noLeaveIt: "No, Leave it",
  },
  labels: {
    quantity: "Quantity",
    email: "E-mail address",
    mobile: "Mobile number",
    nationalIdOrIQAMA: "National ID or IQAMA",
    birthDate: "Date of Birth",
    passwordRequire: "Password should contain:",
    password: "Password",
    passwordRepeat: "Repeat password",
    recoveryCode: "Recovery code",
    retryIn: "Please retry in",
    passwordStrength: {
      Low: "Weak",
      Medium: "Medium",
      Great: "Great",
    },
    accommodationType: "Type of accommodation",
    chooseAccommodation: "Select Accommodation type",
    dependentsNumber: "Number of dependents",
    monthlyExpenses: "Monthly Expenses",
    comingSoon: "Coming soon",
    ownHouse: "Own house",
    rental: "Rental",
    setBirthday: "Set birthday",
    remaining: "Remaining",
    selectSector: "Select a sector",
    monthlyIncome: "Monthly Income",
    monthlyIncomeDescription: "Minimum monthly income must be SAR 4000",
    monthlyRent: "Monthly Rent",
    householdWage: "Household wage",
    utilityExpense: "Utility Expense",
    insurancePremium: "Insurance Premium",
    transportExpenses: "Transport Expenses",
    healthcareExpenses: "Healthcare Expenses",
    communicationExpenses: "Communication Expenses",
    foodAndLivingExpenses: "Food and Living Expenses",
    expatDependentGovFees: "Expat Dependent Gov. Fees",
    educationSuppliesExpense: "Education supplies expense",
    monthlyRemittance: "Monthly Remittance",
    monthlyRemittanceDescription: "(home country for family maintainance)",
    otherMonthlyExpenses: "Other monthly expenses",
    otherMonthlyExpensesDescription: "(including expected in the future)",
    MonthlyObligations: "Monthly Obligations",
    numberOfDependants: "Number of dependants",
    publicSchoolDependants: "Number of dependants in public school",
    privateSchoolDependants: "Number of dependants in private school",
    numberOfHouseholdHelp: "Number of household help",
    numberOfAllDependants: "Number of all dependants",
    numberOfAllDependantsDescription:
      "(The number should include all the above dependants & those not mentioned there.)",
    resonsibleTitle: "I will be responsible for making payments",
    nameOfResponsiblePerson: "Name of person responsible for payments",
    enterNameOfResponsiblePerson: "Enter responsible person name",
    breadwinnerTitle: "Are you the sole breadwinner of the family?",
    rdef: "Are you using Real estate Development Fund?",
    homeOwnerTitle: "Are you a home owner?",
    politicalPersonTitle: "Are you a political person?",
    quaraFinanceEmployee: "Are  you a Quara Finance employee?",
    quaraFinanceRelative: "Do you have a relative in Quara Finance?",
    phoneNumberAbsher: "Please write the same number registered in Absher",
    relative: "Relative",
    relativeNumbers: {
      1: "First",
      2: "Second",
      3: "Third",
      4: "Fourth",
      5: "Fifth",
    },
    relativeID: "Relative ID",
    relativeName: "Relative name",
    relationshipPosition: "Relationship position",
    Relationship: "Relationship",
    placeholderRelativeID: "Enter relative ID",
    placeholderRelativeName: "Enter relative name",
    placeholderRelationshipPosition: "Enter relationship position",
    placeholderRelationship: "Select relationship",
  },
  placeholders: {
    email: "Enter email",
    mobileNumber: "Enter mobile",
    nationalIdOrIQAMA: "Enter ID/Iqama",
    birthDate: "00/00/0000",
    password: "Password",
  },
  additionalInfo: {
    didntGetCode: "Didn't receive the Code?",
    forgotPassword: "Forgot password?",
  },
  errors: {
    EMAIL_NOT_VALID_ERROR: "Invalid e-mail address.",
    PHONE_NOT_VALID_ERROR: "Please write the same number registered in Absher.",
    NATIONAL_ID_NOT_VALID_ERROR: "Wrong Number. Please enter it correctly.",
    NATIONAL_ID_IS_MANDATORY_ERROR: "This field is mandatory",
    WRONG_PASSWORD_ERROR: "Password is incorrect. Please try again.",
    BIRTH_DATE_UNDERAGE_ERROR: "Invalid date of birth. The User of the App should be at least 21 years old.",
    UPPERCASE_one: "At least {{ numberOfSigns }} UPPERCASE letter",
    UPPERCASE_other: "At least {{ numberOfSigns }} uppercase letters",
    LOWERCASE_one: "At least {{ numberOfSigns }} lowercase letter",
    LOWERCASE_other: "At least {{ numberOfSigns }} lowercase letters",
    NUMBER: "At least {{ numberOfSigns }} number",
    SPECIAL_SYMBOL: 'At least {{ numberOfSigns }} special symbol: (~! @ # $% ^ & * _- + = \'| \\ () {} []: "" <>,.? /)',
    SYMBOLS: "At least {{ numberOfSigns }} signs",
    CODE_IS_INVALID: "Code is invalid, try again",
    OTP_ATTEMPTS_EXCEEDED24: "You have exceeded the maximum OTP attempts, Please wait 24 hours before next try.",
    OTP_ATTEMPTS_EXCEEDED: "You have exceeded the maximum security code attempts.",
    ACCOUNT_LOCKED_ATTEMPTS_EXCEEDED:
      "Your account is locked due to exceeding the maximum OTP attempts, Please contact Quara Finance support to unlock your account.",
    WRONG_OTP: "Wrong code.",
    LOGIN_ATTEMPTS_EXCEEDED: "You need to wait 5 minutes due to many failed attempts",
    USER_IS_BLOCKED: "Your account has been deactivated due to multiple wrong password attempts",
    PASSWORD_DONT_MATCH: "The password doesn't match. please try again.",
    PASSWORD_DONT_MATCH_LOWEST_CRITERIA: "Password does not meet the minimum criteria.",
    DATE_OF_BIRTH_IS_INCORRECT: "Please enter your date of birth correctly",
    Minmum_monthly_income: "Minimum monthly income must be SAR 4000",
  },
  pageHeader: {
    createAccount: {
      create: "Create New Account",
      account: "",
    },
    login: {
      login: "Login",
      account: "",
    },
    unblockAccount: {
      verify: "Verify your account",
    },
    employmentSector: "Select Employment Sector",
    setupPassword: "Setup your password",
    codeVerification: "Enter Verification Code",
    forgotPassword: "Forgot password",
    verifyNewPassword: "Verfiy new password",
    employmentDetails: "Enter Financial details",
    commodityOurchase: "Commodity purchase offer",
    confirmCommodityOurchase: "Confirm purchase of commodities",
    offerDetails: "Our offer for your purchase",
    offerDetailsMobile: "Choose your program",
    otpVerification: "OTP Verification",
  },
  pageContent: {
    alreadyHaveAccount: "Already have an account?",
    dontHaveAccount: "Don’t have an account?",
    codeSentTo: "Please enter the code sent by Absher to your government-registered mobile phone",
    codeSentToNumber: "Please enter the security code that has been sent to",
    newPasswordCodeSentToNumber: "Please verify your new password via the code sent to your mobile number",
    didntReceiveCode: "Didn't receive the code?",
    resendCodeIn: "Resend code in",
    privateSector: "Private",
    governmentSector: "Government",
    newCodeSent: "New code has been sent.",
    verifyDataToResetPassword: "Verify your personal data to reset your password",
    otpVerification: "Please fill the security code that has been sent to",
    commodityOurchase:
      "Below we present the offer of commodities that we will buy on your behalf. The list contains a inventory of commodities with the purchase price for which we will use the financing amount proposed earlier.",
    confirmCommodityOurchase:
      "I confirm purchase of commodities presented at the screen before and I give my consent to create account in my name to make that transaction. No new fees will be added to those already presented to me.",
    offerDetails: {
      firstInstallment: "First installment",
      nextInstallment: "Next installments",
      lastInstallment: "Last installment",
      financeAmount: "Finance amount",
    },
    offerPayments: {
      chooseOffer: "choose your payment option",
      offerLabel: "Pay a fixed amount of SAR {{cost}} over {{month}} months",
      notEligibleForOffer: "Sorry you are not eligible for this program",
      fixedLabel: "Fixed",
      select3_6monthsLabel: "Select from 3-6 months to pay without any interest.",
      select3_6monthsTemporaryLabel: "Not available",
      comingSoon: "Coming soon",
      select3_6monthsHeader: "Pay in 3-6",
      payIn: "Pay in",
      noEligiblePrograms: "Unfortunately you are not eligible for any of our programs",
      contactToSupport: "Please contact us at: <0>{{email}}</0> for any support",
    },
    relationship: {
      Grandparent: "Grandparent",
      Parent: "Parent",
      Sibling: "Sibling",
      Cousin: "Cousin",
      Spouse: "Spouse",
      Offspring: "Offspring",
    },
  },
  dialogTitle: {
    threeDSecureVerification: "Your card will be verified using 3D Secure method",
    accountLocked: "Your account is locked",
    userAlreadyRegistered: "This user is already registered",
    userAlreadyRegistered_deprecated: "The user is already registered",
    unableToVerify: "Unfortunately we were unable to verify your identity",
    somethingWentWrong: "Something went wrong",
    incorrectData: "Incorrect Data Entry",
    privateSectorVerificationFailed: "Private sector verification failed",
    governmentSectorVerificationFailed: "Government sector verification failed",
    identityVerifying: "Unfortunately you can’t continue the process due to Compliance issues",
    success: "Success!",
    unableVerifyCardDetails: "Unfortunately, we were unable to confirm your card details",
    incorrectCardDetails: "Incorrect Card Deatils",
    balanceInsufficient: "Card Balance is insufficient",
    confirmCancelQuestion: "Are you sure you want to reject the offer ?",
    loanOfferExpired: "Unfortunately, the loan offer has expired",
    couldNotProvideOffer: "Unfortunately we couldn’t provide you with a loan offer",
    couldNotProvideOfferPrivate: "Private sector verification failed",
    couldNotProvideOfferGovernment: "Government sector verification failed",
    didntMeetFinanceCreditCriteria: "Unfortunately you didn’t meet Quara Finance credit criteria",
    pleaseDownloadApp: "Please download the app",
    pleaseOpenOurApp: "Please open our app",
    rejectContract: "Are you sure you want to reject the contract ?",
    srsCheckHaveNotPassed: "Sorry, you didn`t meet the Quara Finance credit criteria",
    wrongDOB: "Please enter the correct date of birth according to your information",
    successfulCallbackRequest: "Request sent successfully",
    underDeletation: "This account is under cancellation process",
    removeRelative: "Remove relative",
    finalLoanSammary: "Final Loan Summary",
    successResetPassword: "Your have successfully reset your Password",
    accountDeactivated: "Account Deactivated",
    accountActivated: "Account Activated",
  },
  dialogContent: {
    accountLocked: "To unlock your account, please contact us on <0>{{email}}</0>",
    proceedWithExistingLogin: "Please proceed to login with your account",
    checkProvidedData:
      "Please check your data again, it does not match those held by the government. You can also contact us on <0>{{ email }}</0>",
    requestWasntProcessed: "Due to unforeseen circumstances your request wasn’t processed. Please try again",
    dataDoesntExist: "Please Go back and try again",
    contactForMoreOptions: "If you would like to know more options, please contact us on  <0>{{ email }}</0>",
    unableProcessRequest: "We were unable to process your request. Please try again later.",
    tryAgain: "Please try again later",
    cardAddSuccess: "Your credit card has been added successfully!",
    unableDueInsufficientBalance:
      "We were unable to process your card payment due to an insufficient card balance. Please make sure you have sufficient credit on your card and try again.",
    cancelCardDetails: "If you want to cancel the card details press yes.",
    cancelOffer: "If you want to reject the finance contract press yes.",
    offerExpired: "Please go back to the store and apply again to receive a new offer.",
    cardDetailsDoesntMatch:
      "The card detailes doesn’t match. Please make sure you have sufficient credit on your card and try again.",
    exitOrContact: "Please exit or contact us on <0>{{email}}</0>",
    youWillBeRedirectedToTheStore:
      "You will be redirected to the store in <0>{{timer}}</0>\n or click exit to go back now.",
    selectPlatform: "Select your platform type and go to the store to install our app and get your order",
    downloadFromStore: "Click on downloading to go to the store to install our app and get your order",
    confirmRejection: "If you want to reject the loan contract press yes.",
    srsCheckHaveNotPassed: "You will be redirected to the store in <0>{{timer}}</0> or click exit to go back now",
    srsCheckFailed: "Verification process is failed due to technical issues. Please try again",
    srsCheckFinallyFailed: "Verification process is failed due to technical issues. Please try again later",
    exceededRegAttempts: "You have exceeded the maximum trials for registering with this National ID/IQAMA",
    enterPhoneFromAbsher: "Please enter the same data as registered in Absher for a successful verification.",
    successfulCallbackRequest: "You’ll receive our call very soon",
    whereToGo: "Please decide where you want to go.",
    underDeletation:
      "You need to cancel the account deletion to continue applying for the finance. If you don’t cancel, loan application will stop.",
    removeRelative: "Are you sure you want to delete this relative? We can see that some fields have been filled.",
    successResetPassword: "You can now Login using your new password.",
    accountDeactivated:
      "Due to 3 wrong attempts on entering password your account was deactivated until you can confirm you identity. Please proceed to fulfill requirements needed to activate you account.",
    accountDeactivatedOTP:
      "Due to 3 wrong attempts on entering the one time password code, your account was deactivated until you can confirm you identity. Please proceed to fulfill requirements needed to activate you account.",
    accountActivated:
      "Thank you for confirming your identity. Please proceed to password setup to login to your account",
  },
  orderContent: {
    header: "Your purchase",
    subheader: {
      keyWithCount_one: "<0>{{ itemsAmount }} item</0> at a total value of <1> {{ sum }}</1>",
      keyWithCount_other: "<0>{{ itemsAmount }} items</0> at a total value of <1> {{ sum }}</1>",
    },
    productsInCart: "Products in cart:",
  },
  confirmationContent: {
    header: "Obligatory 24h waiting period.",
    whatWillHappenNext: "What happens next:",
    phoneCall: "You will receive a call from us to verify your application.",
    message: "A confirmation of approval will be sent via SMS.",
    contract: "With just a few clicks, sign the contract on the Quara Finance website.",
    nafithConfirm: "Confirm the Nafith e-promissory note.",
    transaction: "Congratulations! you will get the financing as soon as the 24h waiting period is over.",
    installMobileApp:
      "Sign your contract and manage your orders and installments later from the Quara Finance mobile application.",
    warnings: {
      contractIsWaitingForACall:
        "You already have a contract with Quara Finance. We will call you shortly to discuss having another contract. Please keep in mind, you can apply for another loan once the current one is fulfilled.",
      contractIsWaitingForASigning:
        "You already have an open contract. Please sign the contract thats is waiting for you in Quara Finance mobile application. You can apply for another loan after completing the formalities with the current one.",
      contractIsSigned:
        "You already have an open contract. Please wait for releasing current loan. You can check its current status in Quara Finance mobile application. You can apply for another loan after completing the formalities with the current one.",
    },
  },
  employmentDetails: {
    consent: "I give my consent to request my data from SIMAH Credit Bureau.",
    fullConsent: "Full consent",
    consentDialogTitle: "Consent for Information from SIMAH",
    consentDialogDescription:
      "I, the undersigned, (Agent/Authorized person (on behalf of the Business/Company)), hereby agree to provide Quara Finance Co. with any information required for the establishing and/or auditing and/or administering of my accounts and facilities. Additionally, I authorize Quara Finance Co. to obtain and collect any information it deems necessary regarding me, my accounts, and facilities therewith, from the Saudi Credit Bureau (SIMAH) and to disclose and share (inclusive of Data Pooling) that information to the said company.",
  },
  newOfferCalculator: {
    requestAmount: "The requested amount",
    description: "Select the most suitable financing offer",
    sarPerMonth: "SAR/MO",
  },
  offerDetails: {
    totalAmount: "Total amount",
    description: "Financing offer details",
    requested_amount: "Requested Amount",
    profit: "Profit",
    tenure: "Tenure",
    apr: "APR",
    monthly_payment: "Monthly Payment",
    interest_rate: "Interest Rate",
  },

  terms_title: "Quara Terms & Conditions",
  terms: {
    keyPrinciples: {
      title: "Key Principles",
      1: "These Terms and Conditions (“Terms”) have been designed to provide clear guidance and legally binding terms in respect of services provided to you by Quara Finance Company under the laws of the Kingdom of Saudi Arabia trading using the brand name “Quara Finance”) (hereinafter referred to as “we”, “us”, “the Company” or “Quara Finance”). These Terms govern your use of Quara Finance’s mobile application (“Quara Finance App”) for micro-financing services, the Quara Finance Website (“the Quara Finance Site”) and your relationship with the Company as well as any other services that are made available to you through the use of the Quara Finance App from time to time (the “Services”). This document is available both in Arabic and English.",
      3: "These Terms are being presented to you for your review and acceptance before you grant your consent for the completion of the on-boarding and registration process (hereinafter referred to as “Registration”) by the Company. Registration is a prerequisite for the use of the Quara Finance App, Quara Finance Site and Services. Registration requires the Company to undertake “Know Your Customer” checks (hereinafter referred to as “KYC”) in order to verify you as an authorized user of the Services and will only be undertaken once you have indicated your acceptance of the Terms.",
      2: "Please read these Terms carefully, before the completion of Registration, as the Terms affect your rights and liabilities under the laws of the Kingdom of Saudi Arabia. If you do not agree to these Terms, please refrain from using the Quara Finance Site or the Quara Finance App. By registering for Quara Finance App Services, you acknowledge that you have read, understood and agree to be bound by these Terms.",
    },
    definitions: {
      title: "Definitions",
      0: "The following words and phrases shall provide the following meanings as follows:",
      1: "“Account” means the electronic money account the Customer opens with the Quara Finance App for the use of the Service.",
      2: "“APP” means the Quara Finance App.",
      3: "“Application” means the registration form which the Customer must complete to establish a Quara Finance Account Service.",
      4: '"SAMA" means the Saudi Central Bank.',
      5: "“SIMAH” means the Saudi Credit Bureau.",
      6: "“Charges” means the service charges imposed by QUARA FINANCE on different services offered on the Quara Finance App.",
      7: "“Credit” is the E-Cash available in the Customer creditor account.",
      8: "“Credit Account” means the account opened in the Partner Bank to regulate and keep all payments on behalf of the Customers.",
      9: "“Customer” refers to any person, 18-years or older, who has registered for Quara Finance App services and has accepted its terms and conditions as reflected in this document.",
      10: "“Quara Finance App Service” is an electronic platform offered by Quara Finance Company, through which the registered Customer can obtain finances and repay finances through various money transfer models from their Account to another Customer’s Account within the boundaries of the KSA according to a license issued by SAMA.",
      11: "“Quara Finance App Customer Care Center” means the centralized customer care number which Customers may access during working hours to report any technical problems experienced through the use of the Quara Finance App Service, and which allows Quara Finance Company to respond to such problems or address any other Customers inquiries.",
      12: "“Distributor/Agent” is the individual assigned by Quara Finance Company to provide services related to Quara Finance App.",
      13: "“Distributor’s Services” are the services and functions undertaken by the distributors/agents related to Quara Finance App including subscription receipts, activation and facilitation of the Customer acquisition, Customer onboarding, top-up vouchers and other services.",
      14: "“E-Account” is the Customer’s Quara Finance App Account as it refers to the digitized account made and kept by QUARA FINANCE for the benefit of the Customer exclusively upon registering and accepting the terms and conditions provided by Quara Finance Company from time to time.",
      15: '"SAR" means Saudi Arabian Riyals.',
      16: "“KYC” refers to the “Know-Your-Customer” process and to the personal identification details of a Customer which is authenticated as per SAMA and other government entity requirements.",
      17: "“Lending” refers to the micro-lending service offered to Quara Finance App Customers on a case by case basis. Quara Finance’s determination of the Customers eligibility to receive micro-lending will be at Quara Finance’s sole discretion and in accordance with Quara Finance’s defined qualification criteria and procedures.",
      18: "“Money Laundering” refers to all activities prohibited in accordance with the KSA’s Anti-Money Laundering laws and rulings.",
      19: "“SIM Card” is the card that contains the Customer’s relevant information which is issued by a mobile telecommunication operator in the KSA and enables the Customer to benefit from and access the Quara Finance App Service and other services provided by Quara Finance Company.",
      20: " “Activation Code” means the 4-digit pin code that is issued to the Customer upon registration to the Quara Finance App Service. The 4-digit pin code is used to activate the Customer’s account.",
    },
    appRegistration: {
      title: "Quara Finance App Registration and Requirements",
      1: "Quara Finance App Services are available to individuals residing in the KSA who own an active SIM Card and a subscription service with any authorized Saudi Telecommunications Company. Each SIM Card shall only be eligible to have one Account associated with it. Quara Finance Company reserves the right, at its sole discretion and at any time, to change the Terms to include individuals residing in the KSA.",
      2: "Registration to the Quara Finance App Services can be completed through the Quara Finance App.",
      3: "Registration to the Quara Finance App Services is subject to the collection of complete KYC information and validation of Customer's identity in accordance with SAMA regulations.",
      4: "The Company reserves the right, at any time, to undertake all relevant steps and procedures necessary to ensure compliance with all relevant and applicable SAMA KYC requirements.",
      5: "The Customer certifies that all information provided is correct, complete and precise.",
      6: "The Company reserves the right at any time to reject an Application request in the event of any discrepancies or inaccuracies in the information or documentation provided by the Customer.",
      7: "Upon registration to Quara Finance App, the Customer will receive a confirmation message that verifies the successful creation of the account.",
      8: "Upon registration to Quara Finance App, the Customer will receive the Activation Code that must be inserted into the App in order to activate the Account. The Customer can change the Activation Code at any time and should do so immediately for security purposes.",
      9: "The Customer is solely responsible for the accuracy of the information provided in the Application for Quara Finance App. If the information provided by Customer is determined to be incorrect, erroneous or misleading, Quara Finance App retains no responsibility and is not liable for any consequences that may result from the provision of such incorrect, erroneous or misleading information. If the Customer has actual or constructive knowledge regarding the inaccuracy of the information provided about his Account, the Customer must notify the Company by contacting support@quarafinace.com as soon as possible. If the Company discovers that the information provided by Customer is false, erroneous or misleading, the Company reserves the right to terminate the Customer’s Account at any time, with or without notice. A termination by the Company will be effective immediately or as may be specified in a notice from the Company. Termination of the Customer’s Account will include disabling the Customer’s access to the APP.",
    },
    appConditions: {
      title: "Quara Finance App Conditions",
      1: "No money can be stored at any point of time in a Quara Finance App Account for Customers in accordance with current SAMA regulations.",
      2: "The Customer’s Quara Finance App Account will remain active for 180 days from the last transaction or the date of Account activation.",
      3: "In the event of loss or damage to the SIM Card on which the Customer’s number is assigned, the Customer should inform the Company immediately through the Customer Care Support or via email to block the service to prevent unauthorized use of Quara Finance App services until the SIM Card is recovered. The Customer can also transfer his Account from one SIM Card to another after the Company verifies that there are no legal implications that may arise due to SIM Card suspension as a result of unauthorized activities. Such unauthorized activity includes but is not limited to fraud, money laundering, terrorism financing and blacklisting. The onus of acquiring the replacement SIM Card will be solely on the Customer.",
      4: "By applying to the Quara Finance App, the Customers give their full consent and authorization for QUARA FINANCE to request Customers data from Yakeen,Tahakom,Absher,ELM, or any financial agency or institution or any consumer reporting agency to access financial records or other financial information, in order to investigate or to determine Customers eligibility. ",
      5: "Customers give their full consent and authorization for QUARA FINANCE to request KYC personal information from ABSHER,Yakeen,Tahakom,ELM, or any other governmental institution to determine and evaluate the individual’s identity and legal capacity to commit him/herself to the obligations stated in these Terms and Conditions.",
      6: "Hereby, I the undersigned (Agent/Authorized person(on behalf of the Business/Company)), agree to provide Quara  Finance App with any information that it requires for the establishing and/or auditing and/or administering my accounts and facilities therewith and I authorize it to obtain and collect any information as it deems necessary or in need for regarding me, my accounts and facilities therewith, from the Saudi Credit Bureau (SIMAH) and to disclose and share (inclusive of Data Pooling) that information.",
      7: "The Company has no right to disclose any personal information, documents or details of any activities in the Customer’s Account except when requested by a governmental entity specialized in combating fraud, money laundering and terrorism financing.",
      8: "The Customer shall abide by any related Quara Finance App instructions issued by the Company from time to time.",
    },
    suspension: {
      title: "Suspension and Closure of Account and Services:",
      1: "The Company reserves the right to suspend or discontinue Quara Finance App services either, wholly or partially, or to block, restrict or terminate the Account or suspend the usage of any Activation Code at its discretion, at any time and without any responsibility toward the Customer in the following cases:",
      2: "1.	If QUARA FINANCE has a reason to believe that the Customer’s mobile, line or code used to obtain Quara Finance App Services are used contrary to what has been disclosed or used for illegal activities.",
      3: "2.	In case any specialized legal, regulatory or governmental party or any other comparable authority starts an investigation or undertakes regulatory, administrative or executive procedures against the Customer or any other act that QUARA FINANCE believes may harm Quara Finance’s corporate image.",
      4: "3.	In case the Customer does not abide by the conditions, regulations or relevant instructions of using Quara Finance App services including the usage terms.",
      5: "4.	In case the Customer has informed QUARA FINANCE that the SIM to which the Quara Finance App is linked has been lost or stolen or that the Activation Code has been improperly disclosed to another person.",
      6: "5.	Upon receiving a request from the Customer for Account closure.",
      7: "6.	For technical failure, modification, upgrade, variation, relocation, repair, and/or maintenance due to any emergency or for any technical reasons.",
      8: "7.	For any suspected violation of the rules, regulations, orders, directions, notifications issued by SAMA from time to time or for any violation of the Terms and Conditions mentioned in herein.",
      9: "8.	If QUARA FINANCE has reason to believe that termination/ suspension is necessary at its discretion.",
      10: "QUARA FINANCE is not accountable towards the Customer against any loss, defect, direct or indirect damage caused by any conduct, or Customer’s oblivious as to the status of their Account in the event of closure or suspension.",
      11: "The Customer’s Credit Account is his privilege during his life span. In the event of a Customer’s death, the remaining balance will be the privilege of his legal inheritors. This will take legal effect after his legal inheritors submit a valid death certificate to the customer care center.",
      12: "In case there is any defect in operating the service that falls within Quara Finance’s responsibility for any reason, QUARA FINANCE will be committed to mitigating the impact of the damage to the App if any.",
    },
    serviceCharges: {
      title: "Service Charges",
      1: "The Customer shall pay the Service Charges prescribed by Quara Finance App in the form and manner prescribed for such payment. QUARA FINANCE may at its discretion, change, amend, increase, modify or reduce the Service Charges from time to time with due notification to the Customer. QUARA FINANCE will be committed to announcing the changes of the available services in many ways such as but not limited to through the Apps, websites, newspapers, text messages and brochures.",
      2: "The Quara Finance App Account will enable the Customer to engage in the following activities:",
      3: "•	Micro-lending",
      4: "•	Quara Finance App Account to bank account transfer.",
      5: "After completing Quara Finance App activity, the sender will receive an SMS text message that includes a description of the financial transaction of the most recent credit balance on the Account. All registrars in Quara Finance App system are subject to a rebuttable presumption of accuracy.",
    },
    securityAndUnauthorizedUsage: {
      title: "Security and Unauthorized Usage",
      1: "Each Account has one credential by which the Customer can access the account.",
      2: "The Customer shall be solely responsible for the confidentiality, safety and security of their code and other credentials.",
      3: "The Customer shall be solely responsible for the consequences arising out of disclosure of the code and/or the unauthorized use of Quara Finance App Service. In case the Code, mobile phone, or SIM Card   associated with Quara Finance App Services is lost, misplaced or otherwise compromised, the Customer shall promptly inform Quara Finance App by sending an email to support@quarafinance.com where after the Quara Finance App Account will be blocked and new Credentials will be issued to the Customer after the requisite validation.",
      4: "The Customer is responsible for protecting their mobile phone and its correct use and is also responsible for maintaining the secrecy of the Code and all Quara Finance App transactions completed through the code perse. ",
      5: "The Customer has the right to change the Code of his Account through the service menu on the App or through other means. In any case, the Customer must identify themselves upon any Quara Finance App transaction by using his mobile phone and Code. The Customer is prohibited from disclosing the Code to anybody, including the Customer Care employee or Customers Care Center.",
    },
    customerObligations: {
      title: "Customer’s Obligations",
      1: "The Customer is responsible for all Quara Finance App transaction charges accessed by using the Code including the calls and the other on-going activities through the mobile, whether activities implemented by the Customer or by another person commissioned by the Customer.",
      2: "The Customer is also responsible for all charges associated with account top-up, transfers, micro-lending and other activities.",
      3: "The Customer shall not use Quara Finance App Services for any purpose that may be construed as contrary or inacceptable to any applicable laws, SAMA regulations, guidelines, judicial statements, QUARA FINANCE policy or public policy or for any purpose that might negatively prejudice the goodwill of QUARA FINANCE.",
      4: "The Customer represents and warrants that all information entered into the Quara Finance App or otherwise provided to Quara Finance App are true and complete, including personal information regarding the Account. When processing a transaction, Quara Finance App will rely on the registration information that was provided by the Customer.",
      5: "The Customer acknowledges that any errors in the information, including misidentification of beneficiary(s), incorrect or inconsistent Account names and numbers, or misspellings, are his/her/its responsibility and that Quara Finance App shall have no liability for executing a transaction based upon the inaccurate or incomplete information provided or entered by the Customer.",
      6: "The Quara Finance App services are intended to be used in the Kingdom of Saudi Arabia. Specifically and without limitation, the Customer understands and agrees that he may not use the Service where it is (a) induced by or involving the proceeds of crime, fraud or other improper activity; (b) sent for the purpose of gambling, including placing bets or paying winnings; (d) which QUARA FINANCE determines is suspicious or may violate applicable law or regulation, including laws intended to detect and prevent Money Laundering, terrorist financing, human trafficking, fraud, or to enforce currency or exchange rate controls.",
      7: "The Customer agrees that he will only request a transaction that complies with these terms and conditions.",
    },
    dataProtection: {
      title: "Data Protection and Confidentiality",
      1: "Privacy of communication is subject to the terms granted by SAMA and its resolutions and circulars. The Customer agrees that in order to facilitate the provision of Quara Finance App Services. QUARA FINANCE may be required by law or otherwise to disclose any information or details about the Customer to any governmental authority or when disclosure is made with the written consent of the Customer.",
      2: 'The “Quara Finance” user graphics, brand names, logos, design, compilation, information, computer code (including source code and object code), products, software, services, and all other elements of Quara Finance App Services provided by the Company (the "Materials") are protected by copyright, designs, patent, and trademark laws, international conventions and other applicable intellectual property rights.',
      3: "Personal and financial information of Customer can only be accessed and used by authorized employees.",
      4: "The creditor keeps all payment information and other funds received in the credit Account that is registered in the Customer’s Account (Credit Amounts) as confidential information.",
      5: "The Customer will be the only beneficiary of the Credit Amounts which are equal in value to the debited credit in the Customer’s Account. The Customer hereby approves of keeping the Quara Finance App system registrars in the Quara Finance App as a tangible verification of the e-money amount available anytime in the Customer’s Account.",
      6: "The credited is not committed to making an independent investigation of the legitimacy of the Customer’s Credited Amounts.",
      7: "The Customer has no right to demand any benefits that might accrue from the Credit Amount and the Quara Finance has the right to keep the benefits for its use for any legal purposes.",
      8: "The Company shall utilize all reasonable measures to ensure that Customer Transactions and information remain secure and confidential. QUARA FINANCE makes no warranty of any kind about the system and the network or their function or performance, or for any loss or damage, whenever and whatsoever suffered or incurred by the Customer or by any other person resulting from or in connection with the use of Quara Finance App Services.",
    },
    disclaimerIndemnity: {
      title: "Disclaimer and Indemnity",
      1: 'The App, the materials on the App, any product or service obtained through the App are provided "as is" and without warranties of any kind, either express or implied to the fullest extent permissible pursuant to applicable law.',
      2: "QUARA FINANCE and its affiliates, licensors, suppliers, advertisers, sponsors and agents disclaim all warranties, express or implied, including, without limitation, implied warranties of title, non-infringement, accuracy, merchantability, and fitness for a particular purpose, and any warranties that may arise from course of dealing, course of performance or usage of trade.",
      3: "QUARA FINANCE and its affiliates, licensors, suppliers, advertisers, sponsors and agents do not warrant that your use of the App will be uninterrupted, error-free or secure, that defects will be corrected, or that the App is free of viruses or other harmful components.",
      4: "The Customer acknowledges that they are responsible for obtaining and maintaining all mobile, hardware and other equipment needed to access and use the App, and all charges related thereto. The Customer is responsible to update his/her mobile software/hardware if it has been announced by the local authority to prevent the mobile from hacking customer data and might lead to use the account by hacker.",
      5: "The Customer assumes all responsibility and risk for his/her use of the App and reliance thereon. No opinion, advice or statement of QUARA FINANCE, its affiliates, licensors, suppliers, advertisers, sponsors, agents, members or visitors, whether or not made on the App shall create any warranty. The Customer’s use of the App and Services and all Materials provided through the App are entirely at his/her own risk.",
      6: "QUARA FINANCE makes no express or implied warranty, guarantee, or undertaking whatsoever regarding the Quara Finance App Services, which are not expressly mentioned herein.",
      7: "QUARA FINANCE shall not be liable to any person for any delays, loss of business, profit, revenue or goodwill, anticipated savings, damages, fees, costs and expenses whatsoever arising, on Quara Finance App Services being unavailable/usage of the Quara Finance App Services or otherwise.",
      8: "The Customer agree to indemnify, defend and hold QUARA FINANCE and/or related parties harmless from all claims, losses, damages, liabilities, costs and expenses, including and without limitation legal fees and expenses, arising out of or related to the use or misuse of the Quara Finance App Services, for any violation of these Terms and Conditions, or any breach of the warranties, and covenants.",
    },
    ChangesOfTermsandConditions: {
      title: "Changes of Terms and Conditions",
      1: "QUARA FINANCE has the right to make any changes to these Terms at any time. QUARA FINANCE shall provide the Customers with an updated copy of the Terms, and an announcement of such changes will be published in a newspaper, SMS, Quara Finance Website, Quara Finance App or any other suitable media outlet which will be sufficient for the Customer to be officially informed.",
      2: "The Customer’s continuous use of the Quara Finance App Services following the announcement of the changes to the Terms are considered as an acceptance of the changes.",
      3: "The Terms herein shall be subject to the notifications/ guidelines issued by SAMA, from time to time.",
    },
    GeneralTermsandConditions: {
      title: "General Terms and Conditions",
      1: "The Customer should pay all charges of restoring debited amounts including any applicable service fees.",
      2: "The Customer should inform QUARA FINANCE immediately of any changes to his/her personal information in the registration request form.",
      3: "Any dispute with or complaint against any financial services provided through the QUARA FINANCE APP must be directly reported by the Customer by sending an email to support@quarafinance.com QUARA FINANCE may investigate the allegation and determine in good faith, in its sole discretion to resolve any disagreements fairly and amicably.",
      4: "In the event of any dispute, Quara Finance App records shall be binding as the conclusive evidence of the transactions carried out using Quara Finance App Services.",
      5: "QUARA FINANCE owns all copyrights, trademark and all other intellectual copyrights in the Materials and the Customer hereby agrees that he/she has no right to use or claim such rights.",
      6: "The Customer agrees to receive SMS messages from QUARA FINANCE through the mobile defined in the registration request form.",
      7: "These Terms (or any amended copy) are considered an agreement committing the Customer or his representatives to the terms and conditions stipulated herein.",
      8: "These Terms may not be transferred or referred to any other person except the Customer addressed in the registration form.",
      9: "All rights and reparations mentioned in this agreement are accumulative and do not exclude any rights or reparations stipulated by the law.",
    },
    ApplicableLaw: {
      title: "Applicable Law",
      1: "This contract is subject to and interpreted according to the laws and regulations of the Kingdom of Saudi Arabia (“KSA”), and all conflicts emerging herewith will be referred to the KSA courts. Jurisdiction will be In Riyadh. ",
    },
    CustomerConsent: {
      title: "Customer Consent",
      1: "I hereby declare that I have read the above and clearly understand the content. By agreeing to the above I acknowledge and agree that:",
      2: "I authorize QUARA FINANCE to access my personal information via ABSHER platform or Yakeen services or Tahakom or any other governmental institution.",
      3: "I authorize QUARA FINANCE to access my financial records for purposes of credit scoring via SIMAH.",
      4: "I authorize QUARA FINANCE to access my salary/income records for purposes of financial income via GOSI.",
      5: "I acknowledge that it is my responsibility to review my Account statements and related notices promptly upon receipt of related notification.",
      6: "I agree to pay QUARA FINANCE any outstanding credits, fees or taxes that have been incurred on my Account.",
      7: "I agree to be responsible for any damages, costs or termination of the Account incurred by my failure to acknowledge my Account statements and related notices.",
      8: "I agree to notify QUARA FINANCE in writing or by telephone of any changes to my account information or other information that I have provided in connection with these Terms.",
      9: "The costumer agrees that Quara Finance can withdraw any amount of money that was used for the Quara Application transaction to the customer account by any technical error or any Human Mistake.  ",
      10: "I have read all the terms and conditions noted in the above thoroughly and accepted it.",
    },
  },
};
