import { ReactComponent as ErrorIcon } from "@assets/icons/popup_error_icon.svg";
import { ReactComponent as InfoIcon } from "@assets/icons/popup_info_icon.svg";
import { ReactComponent as SuccessIcon } from "@assets/icons/success.svg";

export const dialogContents = {
  accountLockedLogin: {
    title: "dialogTitle.accountDeactivated",
    description: "dialogContent.accountDeactivated",
    agreeText: "buttons.proceed",
    icon: <InfoIcon />,
  },
  accountLocked: {
    title: "dialogTitle.accountDeactivated",
    description: "dialogContent.accountDeactivatedOTP",
    agreeText: "buttons.proceed",
    icon: <InfoIcon />,
  },
  unableToVerify: {
    title: "dialogTitle.unableToVerify",
    description: "dialogContent.checkProvidedData",
    icon: <ErrorIcon />,
    agreeText: "buttons.tryAgain",
    cancelText: "buttons.backToStore",
  },
  somethingWentWrong: {
    title: "dialogTitle.somethingWentWrong",
    description: "dialogContent.requestWasntProcessed",
    icon: <ErrorIcon />,
    agreeText: "buttons.tryAgain",
    cancelText: "",
  },
  underDeletation: {
    title: "dialogTitle.underDeletation",
    description: "dialogContent.underDeletation",
    icon: <ErrorIcon />,
    agreeText: "buttons.cancelDeletion",
    cancelText: "buttons.goToStore",
  },
  somethingWentWrongExitBtn: {
    title: "dialogTitle.somethingWentWrong",
    description: "dialogContent.requestWasntProcessed",
    icon: <ErrorIcon />,
    agreeText: "buttons.exit",
    cancelText: "",
  },
  somethingWentWrongUnableProcessReq: {
    title: "dialogTitle.somethingWentWrong",
    description: "dialogContent.unableProcessRequest",
    icon: <ErrorIcon />,
    agreeText: "buttons.tryAgain",
    cancelText: "",
  },
  incorrectData: {
    title: "dialogTitle.incorrectData",
    description: "dialogContent.dataDoesntExist",
    icon: <ErrorIcon />,
    agreeText: "buttons.goBack",
    cancelText: "",
  },
  srsCheckHaveNotPassed: {
    title: "dialogTitle.srsCheckHaveNotPassed",
    description: "dialogContent.srsCheckHaveNotPassed",
    agreeText: "buttons.exit",
    icon: <ErrorIcon />,
  },
  srsCheckFailed: {
    title: "dialogTitle.somethingWentWrong",
    description: "dialogContent.srsCheckFailed",
    agreeText: "buttons.tryAgain",
    icon: <ErrorIcon />,
  },
  srsCheckFinallyFailed: {
    title: "dialogTitle.somethingWentWrong",
    description: "dialogContent.srsCheckFinallyFailed",
    agreeText: "buttons.exit",
    icon: <ErrorIcon />,
  },
  userLockedByRegAttempts: {
    title: "dialogTitle.accountLocked",
    description: "dialogContent.exceededRegAttempts",
    agreeText: "buttons.exit",
    icon: <InfoIcon />,
  },
  successResetPassword: {
    title: "dialogTitle.successResetPassword",
    description: "dialogContent.successResetPassword",
    icon: <SuccessIcon />,
    agreeText: "buttons.logInSingle",
    isSuccess: true,
  },
  successActivateAccount: {
    title: "dialogTitle.accountActivated",
    description: "dialogContent.accountActivated",
    icon: <SuccessIcon />,
    agreeText: "buttons.proceed",
    isSuccess: true,
  },
  defaultDialogContent: {
    title: "",
    description: "",
    cancelText: "",
    agreeText: "",
    icon: <></>,
  },
};
